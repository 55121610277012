import React from "react";
import Loading from "./Loading";
import "./Loading.css";
const ModalLoader = () => {
  return (
    <div className="modal__loader__container">
      <Loading />
    </div>
  );
};

export default ModalLoader;