import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";

import { useTranslation } from "react-i18next";
import Loading from "../../../components/LoadingError/Loading";
import { useFetchVIPTopSpecies } from "../../../queries/useFetchVIPDashboard";

const VIPHorizontalBarChart = ({ dates, toggleRefetch }) => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [maxLabelLength, setMaxLabelLength] = useState(17);

  useEffect(() => {
    const handleResize = () => {
      const newMaxLabelLength = window.innerWidth < 768 ? 12 : 17; // Adjust screen width breakpoint as needed
      setMaxLabelLength(newMaxLabelLength);
    };

    handleResize(); // Set initial max label length based on screen width
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const { topSpecies, topSpeciesLoading } = useFetchVIPTopSpecies({
    dates,
  });

  useEffect(() => {
    if (chartRef.current && topSpecies && !chartInstance.current) {
      const ctx = chartRef.current.getContext("2d");

      // Set device pixel ratio to enhance rendering quality
      const devicePixelRatio = window.devicePixelRatio || 1;
      chartRef.current.width = chartRef.current.clientWidth * devicePixelRatio;
      chartRef.current.height = 400 * devicePixelRatio;

      chartInstance.current = new Chart(ctx, {
        type: "bar",
        data: {
          labels: topSpecies.map((species) => {
            const label = Object.keys(species)[0];
            return label.length > maxLabelLength
              ? `${label.slice(0, maxLabelLength)}...`
              : label;
          }),
          datasets: [
            {
              label: `${t("dashboard.topSpecies")}`,
              data: topSpecies.map((species) => Object.values(species)[0]),
              backgroundColor: "#3A8AC9",
              barThickness: 30, // Adjust the bar thickness to create space between bars
            },
          ],
        },
        options: {
          indexAxis: "y",
          responsive: true,
          // maintainAspectRatio: false,
          scales: {
            x: {
              display: true,
              beginAtZero: true,
            },
            y: {
              display: true,
            },
          },
        },
      });
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
        chartInstance.current = null; // Reset chartInstance ref
      }
    };
  }, [dates, topSpecies, currentLanguage, maxLabelLength, t]);

  useEffect(() => {
    setCurrentLanguage(i18n.language); // Update current language when language changes
  }, [i18n.language]);

  if (topSpeciesLoading) {
    return (
      <div className="horizontalBarLoadingContainer">
        <Loading />
      </div>
    );
  }

  return <canvas ref={chartRef} className="horizontalBarChartCanvas" />;
};

export default VIPHorizontalBarChart;
