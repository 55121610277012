import React, { useEffect, useState } from "react";
import "./styles.css";
const NoData = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 5000);
  }, []);

  return (
    <>
      {show && (
        <div className="map__nodata">
          <h3>No observations found</h3>
        </div>
      )}
    </>
  );
};

export default NoData;
