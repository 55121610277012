import React from "react";
import Image from "react-bootstrap/Image";
import "./styles.css";
import { useTranslation } from "react-i18next";

export default function LandingFooter() {
  const { t } = useTranslation();
  const handleAmmcoEmailClick = () => {
    window.location.href = "mailto:info@ammco.org";
  };

  return (
    <section className="LandingFooter">
      <h2 className="titleBackgroundFooter">
        {t("landingPage.about.aboutTitle")}
      </h2>
      <p>{t("landingPage.about.p1")}</p>
      <p>{t("landingPage.about.p2")}</p>
      <p>{t("landingPage.about.p3")}</p>
      <div className="landingFooter-footer">
        <div className="footerImageColumn">
          <Image
            className="d-none d-lg-block"
            src="./assets/sirenLogoWithNameLG.png"
            alt="sirenLogoName"
          />
          <Image
            className="d-none d-md-block d-lg-none"
            src="./assets/sirenLogoWithNameMD.png"
            alt="sirenLogoName"
          />
          <Image
            className="d-block d-md-none"
            src="./assets/sirenLogoWithNameSM.png"
            alt="sirenLogoName"
          />
        </div>

        <div className="landingFooterAmmco">
          <h4>powered by AMMCO</h4>
          <div className="landingFooterAmmcoWrapper">
            <div className="landingFooterAmmcoInfo">
              <span className="landingFooterContactIcons">
                <Image
                  src="assets/elements/images/icon/telephone.svg"
                  alt="telephone"
                />
              </span>
              +(237) 680587715 | 651743595
            </div>
            <div
              className="landingFooterAmmcoInfo landingFooterAmmcoEmail"
              onClick={handleAmmcoEmailClick}
            >
              <span className="landingFooterContactIcons">
                <Image
                  src="assets/elements/images/icon/envelope.svg"
                  alt="telephone"
                />
              </span>
              info@ammco.org
            </div>
            <div className="landingFooterAmmcoInfo">
              <span className="landingFooterContactIcons">
                <Image
                  src="assets/elements/images/icon/location.svg"
                  alt="telephone"
                />
              </span>
              Dizangue/Edea, Littoral Cameroon
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
