import React from "react";

const AnswersOptions = ({
  tableManager,
  value,
  onChange,
  isEdit,
  data,
  column,
  rowIndex,
  searchText,
  isFirstEditableCell,
}) => {
  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <>
        {data.answers.map((item) => (
          <div> {item} , </div>
        ))}
      </>
    </div>
  );
};

export default AnswersOptions;
