import "./styles.css";
import ContentBox from "../../ContentBox";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TitleComponent from "../../TitleComponent";
import SignUpBtn from "../SignUpBtn";
import SecondaryBtn from "../SecondaryBtn";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { API_URL as API } from "../../../services/API";
import Image from "react-bootstrap/Image";
import TertiaryBtn from "../TertiaryBtn";

function AtGlance() {
  const [data, setData] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetch(API + "api/landingPge");
        if (data.ok) {
          const body = await data.json();
          setData(body);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [data]);

  if (!data) return null;

  return (
    <section className="glanceContainer">
      <TitleComponent>{t("landingPage.content.atGlance.title")}</TitleComponent>
      <Container fluid className="glanceRowContainer d-flex">
        <div className="text-center glaceRow">
          <div className="glaceCol-right">
            <ContentBox
              number={data.countries}
              text={`${t("landingPage.content.atGlance.countries")}`}
            />
          </div>
          <div className="glanceCol-left">
            <ContentBox
              number={numberWithCommas(data.observations)}
              text="Observations"
            />
          </div>
          <div className="glaceCol-right">
            <ContentBox
              number={data.projects}
              text={`${t("landingPage.content.atGlance.projects")}`}
            />
          </div>
          <div className="glanceCol-left">
            <ContentBox
              number={data.species}
              text={`${t("landingPage.content.atGlance.species")}`}
            />
          </div>
        </div>
      </Container>
      <Container fluid className="glaceFooter">
        {/* <h3 className="glaceFooter-title">
          {t("landingPage.content.atGlance.through2023")}
        </h3>
        <div className="glaceFooter-text">
          {t("landingPage.content.atGlance.sirenCountries")}
        </div> */}
        <div className="glanceFooter-btnWrapper">
          <TertiaryBtn
            text={`${t("landingPage.content.learnMore")}`}
            link={"#howItWorks"}
          />
          <SignUpBtn />
        </div>
      </Container>
    </section>
  );
}

function numberWithCommas(x) {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return;
  }
}

export default AtGlance;
