import React from "react";
import close from "../../../assets/images/close.svg";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
const CustomHeader = ({ tableManager }) => {
  const { searchApi } = tableManager;
  const { searchText, setSearchText } = searchApi;
  useEffect(() => {
    document.querySelectorAll(".sort-close").forEach(function (element) {
      element.addEventListener("click", function () {
        this.parentElement.parentElement.classList.add("d-none");
        setSearchText("");
      });
    });
  }, []);
  const { t } = useTranslation();
  return (
    //
    <div
      className="content-search d-none ps-3 pe-3 pb-5  list-v"
      style={{ zIndex: "9999", opacity: "2", right: "10px", width: "255px" }}
    >
      <div
        className="d-flex py-2"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="title-men   ">
          {t("listview.customComponents.search")}
        </div>
        <span className=" sort-close pointer ">
          <img src={close} alt="" style={{ height: "15px" }} />
        </span>
      </div>
      <div className=" search__container">
        <input
          name="my-search"
          className="search__input"
          type="search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: 250 }}
        />
        <span
          className="clear__search"
          onClick={() => {
            setSearchText("");
          }}
        >
          {t("listview.customComponents.clear")}
        </span>
      </div>
    </div>
  );
};

export default CustomHeader;
