import React, { useEffect, useState } from "react";

import { ReactComponent as PlaceholderImage } from "../../../assets/images/no-image.svg";
import { useSelector } from "react-redux";
const styles = {
  root: {
    position: "relative",
    padding: "0 20px",
    display: "flex",
    width: "100%",
    // height: "90%",
    alignItems: "center",
  },
  img: { width: "45px", height: " 45px" },

  text: {
    marginLeft: 5,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};
const PhotoCell = ({ data }) => {
  const [imagesList, setImagesList] = useState([]);
  const updatedLocalProject = useSelector(
    (state) => state.updateProjectLocally
  );
  const { updtedObservationData } = updatedLocalProject;
  useEffect(() => {
    const values = Object.values(data.images).filter(
      (value) => value !== "NULL" && value !== ""
    );
    setImagesList(values);

    if (updtedObservationData !== null) {
      const values = Object.values(data.images).filter(
        (value) => value !== "NULL" && value !== ""
      );
      setImagesList(values);
    }
  }, [data, updtedObservationData]);

  return (
    <div style={styles.root}>
      <React.Fragment>
        {imagesList?.length !== 0 ? (
          <img style={styles.img} src={imagesList[0]} alt="avatar" />
        ) : (
          <div>
            <PlaceholderImage
              className="no-image"
              style={{
                width: "45px",
                height: "45px",
              }}
            />
          </div>
        )}
        {imagesList.length > 1 && (
          <span style={styles.text}>{`+ ${imagesList.length - 1}`}</span>
        )}
      </React.Fragment>
    </div>
  );
};

export default PhotoCell;
