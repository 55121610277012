const GetQuestionsColumn = ({ questionsData }) => {
  // Ensure questionsData is an array and has content before mapping
  if (!Array.isArray(questionsData) || !questionsData.length) {
    return []; // Return an empty array if no data
  }

  // Map over the questionsData to create column definitions
  return questionsData.map((item, index) => ({
    id: index.toString(), // Convert index to string for the 'id'
    field: item.answers,
    label: item.question_title,
    width: "135px",
  }));
};

export default GetQuestionsColumn;
