import { create } from "zustand";

export const usePatrolVariables = create((set) => ({
  currentPage: null,
  totalPageNumber: null,
  setCurrentPage: (page) => {
    set({ currentPage: page });
  },
  setTotalPageNumber: (totalPageNumber) => {
    set({ totalPageNumber: totalPageNumber });
  },
}));
