import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const UnVal = ({ data }) => {
  return (
    <div>
      <div className="">
        <Link
          to={`/patrols/observations/unvalid/${data.id}`}
          style={{
            // marginLeft: "15px",
            textAlign: "center",
            display: "flex",
            width: "50px",
            fontWeight: "bold",
            cursor: "pointer",
            textDecoration: "underline",
            color: "#E70909",
            justifyContent: "center",
          }}
        >
          {data.unValidObs}
        </Link>
      </div>
    </div>
  );
};

export default UnVal;
