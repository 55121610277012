import React from "react";

const GetQuestionAnswer = ({ data, questionTitle }) => {
  const questionAnswer = data?.questionsAnwsers?.find(
    (qa) => qa.question.title === questionTitle
  );
  return <div>{questionAnswer?.content || ""}</div>;
};

export default GetQuestionAnswer;
