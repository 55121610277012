import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { MapContainer, TileLayer } from "react-leaflet";
import "../style.css";

import calender from "../../../assets/images/calender.png";
import watch from "../../../assets/images/watch.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import StartPatrolMarker from "../../map/leafletMap/StartPatrolMarker";
import EndPatrolMarker from "../../map/leafletMap/EndPatrolMarker";
import { useTranslation } from "react-i18next";

const ViewPatrolModal = ({ patrolData, closeDetailModal }) => {
  const { t } = useTranslation();
  return (
    <div className="mvp__modals">
      <Modal
        show={true}
        onHide={closeDetailModal}
        backdrop="static"
        centered
        className="mx-auto mvp__modals view_modal"
        keyboard={false}
        size="lg"
        style={{
          borderTopLeftRadius: "20px",
        }}
        dialogClassName="modal-size"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header
          className="bgPrimary text-left modal__header"
          style={{
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Modal.Title className="modal__header__title">
            {t("patrols.modal.view")}
          </Modal.Title>
          <div className="">
            <span
              className=" pointer"
              onClick={() => {
                closeDetailModal();
              }}
            >
              <RxCross1 className="close-modal-icon" />
            </span>
          </div>
        </Modal.Header>

        <>
          <div className="modal-top">
            <div
              className="modal-map"
              style={{ width: "100%", height: "100%", background: "grey" }}
            >
              {patrolData && (
                <MapContainer
                  center={[
                    patrolData && patrolData.gpsStartCoordX,
                    patrolData && patrolData.gpsStartCoordY,
                  ]}
                  zoom={12}
                  scrollWheelZoom={true}
                  zoomControl={false}
                  layer-type="base"
                  style={{ width: "100%", height: "100%", zIndex: "1" }}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <StartPatrolMarker
                    gpsStartCoordX={patrolData.gpsStartCoordX}
                    gpsStartCoordY={patrolData.gpsStartCoordY}
                  />
                  <EndPatrolMarker
                    gpsEndCoordX={patrolData.gpsEndCoordX}
                    gpsEndCoordY={patrolData.gpsEndCoordY}
                  />
                </MapContainer>
              )}
            </div>
          </div>
          <div className="modalBody  ">
            <div style={{ width: "100%" }}>
              <div className=" ">
                <div className="row px-4 pt-3 pb-2 ">
                  <div className="col-12 ">
                    <span
                      className="f-bold  me-4"
                      style={{ fontSize: ".9rem" }}
                    >
                      {t("patrols.modal.patrol")} {patrolData && patrolData.id}
                    </span>
                  </div>
                  <div className="col-5 text-end"></div>
                </div>
              </div>
            </div>
            <div
              className="modal__obs__details  container-fluid "
              style={{ width: "100%" }}
            >
              <div className="row px-3  modal__obs__details__row projectName">
                <div className="col-5 modal__detail__title">
                  {" "}
                  {t("patrols.modal.projectName")}
                </div>
                <div className="col-7 modal__title__value details">
                  {patrolData && patrolData.projectName}
                </div>
              </div>
              <div className="row px-3 modal__obs__details__row ">
                <div className="col-5 modal__detail__title">
                  {" "}
                  {t("patrols.author")}
                </div>
                <div className="col-7 modal__title__value details author__eclipse__text">
                  {patrolData && patrolData.author}
                </div>
              </div>
              <div className="row px-3 modal__obs__details__row ">
                <div className="col-5 modal__detail__title">
                  {" "}
                  {t("patrols.startTime")}
                </div>
                <div className="col-7 modal__title__value details d-flex ">
                  <div className="col-6  me-1 date__eclipe__text">
                    <img
                      src={calender}
                      alt=""
                      width={14}
                      className="me-2 mb-1"
                    />
                    {patrolData && patrolData.startTime.date.slice(0, 10)}
                  </div>
                  <div className="col-6 w-100">
                    <img src={watch} alt="" width={14} className="me-2 mb-1" />
                    {patrolData && patrolData.startTime.date.slice(11, 16)}
                  </div>
                </div>
              </div>
              <div className="row px-3 modal__obs__details__row ">
                <div className="col-5 modal__detail__title">
                  {" "}
                  {t("patrols.endTime")}
                </div>
                <div className="col-7 modal__title__value details d-flex ">
                  <div className="col-6  me-1 date__eclipe__text">
                    <img
                      src={calender}
                      alt=""
                      width={14}
                      className="me-2 mb-1"
                    />
                    {patrolData && patrolData.endTime.date.slice(0, 10)}
                  </div>
                  <div className="col-6 w-100">
                    <img src={watch} alt="" width={14} className="me-2 mb-1" />
                    {patrolData && patrolData.endTime.date.slice(11, 16)}
                  </div>
                </div>
              </div>
              <div className="row px-3 modal__obs__details__row ">
                <div className="col-5 modal__detail__title">
                  {" "}
                  {t("patrols.gpsStart")}
                </div>
                <div className="col-7 modal__title__value details author__eclipse__text">
                  <span>{patrolData && patrolData.gpsStartCoordX} , </span>
                  <span>{patrolData && patrolData.gpsStartCoordY}</span>
                </div>
              </div>
              <div className="row px-3 modal__obs__details__row ">
                <div className="col-5 modal__detail__title">
                  {" "}
                  {t("patrols.gpsEnd")}
                </div>
                <div className="col-7 modal__title__value details author__eclipse__text">
                  <span>{patrolData && patrolData.gpsEndCoordX} , </span>
                  <span>{patrolData && patrolData.gpsEndCoordY}</span>
                </div>
              </div>
              <div className="row px-3 modal__obs__details__row ">
                <div className="col-5 modal__detail__title">
                  {t("patrols.totalPatTime")}
                </div>
                <div className="col-7 modal__title__value details author__eclipse__text">
                  {patrolData && patrolData.totalPatrolTime}
                </div>
              </div>
              <div className="row px-3 modal__obs__details__row ">
                <div className="col-5 modal__detail__title">
                  {t("patrols.modal.totalDistanceWalke")}
                </div>
                <div className="col-7 modal__title__value details author__eclipse__text">
                  {patrolData && patrolData.totalDistance}
                </div>
              </div>
              <div className="row px-3 modal__obs__details__row ">
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="modal__detail__title">
                    {t("patrols.modal.totalObs")}
                    <span
                      style={{
                        paddingLeft: "20px",
                      }}
                    >
                      {" "}
                      {patrolData && patrolData.observations}
                    </span>
                  </div>

                  <div className="modal__detail__title">
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {t("patrols.modal.validated")}
                    </span>
                    <span
                      style={{
                        paddingLeft: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      <Link
                        style={{
                          fontWeight: "bold",
                          color: " #138101",
                        }}
                        to={`/patrols/observations/valid/${patrolData.id}`}
                      >
                        {patrolData && patrolData.valObs}
                      </Link>
                    </span>
                  </div>
                  <div className="modal__detail__title">
                    <span
                      style={{
                        paddingLeft: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      {t("patrols.modal.unvalidated")}
                    </span>
                    <span
                      style={{
                        paddingLeft: "10px",
                      }}
                    >
                      <Link
                        style={{
                          // paddingLeft: "10px",
                          fontWeight: "bold",
                          color: " #E70909",
                        }}
                        to={`/patrols/observations/unvalid/${patrolData.id}`}
                      >
                        {" "}
                        {patrolData && patrolData.unValidObs}
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="row px-3 modal__obs__details__row "
                style={{
                  borderRadius: "8px",
                  background: "#f0f0f0",
                }}
              ></div>
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default ViewPatrolModal;
