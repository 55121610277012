import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFetchSiteSgmntListByProject } from "../../queries/useFetchOptions";
import { editSiteSegment } from "../../actions/projectActions";
import EditSiteSegment from "./EditSIteSegment";
import ReadSiteSegments from "./ReadSiteSegments";
import Loading2 from "../LoadingError/Loading2";
import { t } from "i18next";
import { API_URL } from "../../services/API";
import axios from "axios";
import { setSuccessModifieProject } from "../../services/rxjs";
import SucccessMsg from "../adminSuccessMsg/successMsg";

export default function SiteSegmentView() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const dispatch = useDispatch();
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const userRole = useSelector((state) => state.userRoleOnParticularProject);
  const { role } = userRole;

  const [projectSitesSegments, setProjectSitesSegments] = useState([]);
  const [validate, setValidate] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteSiteNotif, setDeleteSiteNotif] = useState(false);
  const [deleteSegNotif, setDeleteSegNotif] = useState(false);
  const [siteSegUpdated, setSiteSegUpdated] = useState(false);
  const [user, setUser] = useState(null);
  const [selectedProject, setSelectedProject] = useState({});
  const handleGetLoading = (value) => {
    setDeleteLoading(value);
  };
  const {
    siteSegmentList,
    siteSegmentError,
    refetchSiteSegment,
    siteSegmentLoading,
    siteSegmentIsRefetching,
  } = useFetchSiteSgmntListByProject(localProjectId);

  const localProject = JSON.parse(localStorage.getItem("selectedProject"));

  useEffect(() => {
    setSelectedProject(localProject);
    setUser(userInfo);
    if (localProjectId) {
      refetchSiteSegment();
    }
  }, [localProjectId, refetchSiteSegment]);

  useEffect(() => {
    if (siteSegmentList && siteSegmentList.data.length !== 0) {
      setProjectSitesSegments([
        ...siteSegmentList.data,
        projectSitesSegments.length - 1 + 1,
      ]);
    } else {
      setProjectSitesSegments([
        {
          siteId: -1,
          site: "",
          segment: [],
        },
      ]);
    }
  }, [siteSegmentList]);

  const handleUpdate = (ind) => {
    setProjectSitesSegments((prev) => [
      ...prev,
      projectSitesSegments.length - 1 + 1,
    ]);
  };
  const { editSiteSegmentObject } = useSelector(
    (state) => state.editSiteSegentFunction
  );

  const handleGetValidateState = (boolean) => {
    setValidate(boolean);
  };
  useEffect(() => {
    if (editSiteSegmentObject && editSiteSegmentObject.site !== "") {
      setProjectSitesSegments((prevProjectSitesSegments) => {
        return prevProjectSitesSegments.map((val, index) => {
          if (
            editSiteSegmentObject.siteId !== -1 &&
            editSiteSegmentObject.siteId === val.siteId
          ) {
            // setValidate(true);
            return editSiteSegmentObject;
          } else if (index === editSiteSegmentObject?.index) {
            // setValidate(true);
            return editSiteSegmentObject;
          } else {
            // setValidate(true);
            return val;
          }
        });
      });

      dispatch(editSiteSegment({ editSiteSegmentObject: null }));
    }
  }, [editSiteSegmentObject]);

  const [siteIndexFromChild, setSiteIndexFromChild] = useState(null);
  const handleGetSiteIndexFromChild = (siteIndex) => {
    setSiteIndexFromChild(siteIndex);
  };
  useEffect(() => {
    if (siteIndexFromChild !== null) {
      const seletedElement = (projectSitesSegments[siteIndexFromChild] =
        siteIndexFromChild);
      projectSitesSegments[siteIndexFromChild] = seletedElement;

      setProjectSitesSegments([
        ...projectSitesSegments,
        projectSitesSegments.length - 1 + 1,
      ]);
      setSiteIndexFromChild(null);
    }
  }, [siteIndexFromChild]);

  const handleShowSiteDeleteNotif = (componentIndexx) => {
    setValidate(false);
    setDeleteSiteNotif(true);
    if (componentIndexx !== 0) {
      refetchSiteSegment();
    }
    setTimeout(() => {
      setDeleteSiteNotif(false);
    }, 4000);
  };
  const handleShowSegDeleteNotif = () => {
    setValidate(false);
    setDeleteSegNotif(true);
    refetchSiteSegment();
    setTimeout(() => {
      setDeleteSegNotif(false);
    }, 4000);
  };
  const handleShowSiteUpdateNotif = () => {
    setValidate(false);
    setSiteSegUpdated(true);
    refetchSiteSegment();
    setTimeout(() => {
      setSiteSegUpdated(false);
    }, 4000);
  };
  const onSubmit = () => {
    let link = "";

    link = "api/siteSegment/createUpdate";

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    const siteSegments = projectSitesSegments.filter((item) => {
      if (typeof item !== "object" || item === null) {
        return false;
      }
      if (!item.site || item === null) {
        return false;
      }

      if (item.siteId === -1) {
        return true;
      }

      const duplicateIndex = projectSitesSegments.findIndex(
        (i) => i.siteId === item.siteId && i !== item
      );
      if (duplicateIndex !== -1) {
        return false;
      }

      delete item.index;

      return true;
    });

    let info = {
      id: Number(localProjectId),
      siteSegments: siteSegments,
      public: selectedProject.public,
    };

    axios
      .post(API_URL + link, info, config)
      .then(function (response) {
        setValidate(false);
        setSuccessModifieProject();
        setSiteSegUpdated(true);

        refetchSiteSegment();
        handleShowSiteUpdateNotif();
      })
      .catch(function (error) {
        console.log(error, "update project error");
      });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "85%",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {siteSegUpdated && (
        <SucccessMsg message={`${t("admin.projectDetails.saveChanges")}`} />
      )}
      {deleteSiteNotif && (
        <SucccessMsg message={`${t("admin.projectDetails.siteDeleted")}`} />
      )}
      {deleteSegNotif && (
        <SucccessMsg message={`${t("admin.projectDetails.segDeleted")}`} />
      )}
      {siteSegmentLoading || deleteLoading || siteSegmentIsRefetching ? (
        <Loading2 />
      ) : (
        ""
      )}

      {!role && (
        <div
          style={{
            marginBottom: "40px",
            marginTop: "50px",
          }}
        >
          {projectSitesSegments &&
            projectSitesSegments.map((siteSeg, index) => {
              if (
                typeof siteSeg.site === "string" &&
                siteSeg.site.trim() !== ""
              ) {
                return <ReadSiteSegments siteSegments={siteSeg} />;
              }
              return null;
            })}
        </div>
      )}
      {role && (
        <div
          style={{
            marginBottom: "40px",
            marginTop: "50px",
          }}
        >
          {user &&
            projectSitesSegments &&
            projectSitesSegments.map((siteSeg, index) => (
              <EditSiteSegment
                key={index}
                user={user.data}
                siteIndex={index}
                componentIndex={index}
                siteSeg={siteSeg}
                arrayList={projectSitesSegments}
                handleUpdate={handleUpdate}
                handleGetLoading={handleGetLoading}
                sendSiteIndexToParent={handleGetSiteIndexFromChild}
                handleShowSiteDeleteNotif={handleShowSiteDeleteNotif}
                handleShowSegDeleteNotif={handleShowSegDeleteNotif}
                handleGetValidateState={handleGetValidateState}
                refetchSiteSegment={refetchSiteSegment}
              />
            ))}
        </div>
      )}

      {role && (
        <div className="row mt-4 addProject__btn2 container d-flex flex-column flex-sm-row">
          <button
            disabled={!validate}
            onClick={() => {
              onSubmit();
            }}
            className=" btn-block mt-4 login-btn admin-btn-dark"
          >
            {t("saveBtn")}
          </button>
        </div>
      )}
    </div>
  );
}
