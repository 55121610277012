import React, { useEffect, useMemo, useState } from "react";
import { BiChevronDown, BiErrorCircle } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { MdAdd } from "react-icons/md";
import { useDispatch } from "react-redux";
import ConfirmationModal from "../modals/ConfirmationModal";
import { createSiteSegment } from "../../actions/projectActions";
import { useTranslation } from "react-i18next";

const SiteSegment = ({
  siteIndex,
  handleUpdate,
  arrayList,
  sendSiteIndexToParent,
}) => {
  const { t } = useTranslation();
  // segment functions and states
  const [singleSiteSegmentData, setSingleSiteSegmentData] = useState({
    site: "",
    segments: [],
    index: siteIndex,
  });

  const [showAddSegmentFLield, setShowAddSegmentFLield] = useState(false);
  const [singleSegment, setSingleSegment] = useState("");
  const [checkEditSegment, setCheckEditSegment] = useState(false);
  const [singleSegmentId, setSingleSegmentId] = useState(null);
  const [singleSite, setSingleSite] = useState("");
  const [segmentIndex, setSegmentIndex] = useState(null);
  const [segmentList, setSegmentList] = useState([]);

  const [toggleSegmentDelete, setToggleSegmentDelete] = useState(false);
  const [toggleSiteDelete, setToggleSiteDelete] = useState(false);

  const [segmentDeleteMessage, setSegmentDeleteMessage] = useState([]);

  const [segmentDeleteMessageDetails, setSegmentDeleteMessageDetails] =
    useState("");

  // handle delete segment
  const dispatch = useDispatch();
  const handleAddSingleSegmentToList = (e) => {
    e.preventDefault();
    if (singleSegment && singleSegment.trim() !== "") {
      if (checkEditSegment) {
        setSegmentList((prevList) =>
          prevList.map((item, index) => {
            if (index === singleSegmentId) {
              return singleSegment;
            } else {
              return item;
            }
          })
        );
        setSingleSegment("");
        setCheckEditSegment(false);
      } else {
        setSegmentList((prev) => [...prev, singleSegment]);
        setSingleSegment("");
      }
    }
    setSingleSegmentId(null);
  };
  useEffect(() => {
    setSingleSiteSegmentData((prevState) => ({
      site: singleSite,
      segments: segmentList,
      index: siteIndex,
    }));
  }, [singleSite, siteIndex, segmentList]);
  useMemo(() => {
    if (singleSiteSegmentData.site !== "") {
      dispatch(createSiteSegment({ siteSegmentObject: singleSiteSegmentData }));
    }
  }, [singleSiteSegmentData, dispatch]);

  // Delete singleSegment
  const handleDeleteSingleSegment = () => {
    const newSegments = segmentList.filter(
      (item, index) => index !== segmentIndex
    );
    setSegmentList(newSegments);
    setToggleSegmentDelete(false);
  };
  const closeSegmentDeleteModal = () => {
    setToggleSegmentDelete(false);
    setSegmentIndex(null);
  };
  const closeSiteDeleteModal = () => {
    setToggleSiteDelete(false);
  };

  const handleShowAddSegmentBox = () => {
    if (singleSite && singleSite.trim() !== "") {
      setShowAddSegmentFLield((prev) => !prev);
    }
  };
  const handleDeleteSiteAndSegment = () => {
    sendSiteIndexToParent(siteIndex);
    setSingleSiteSegmentData({
      site: "",
      segments: [],
    });
    setSegmentList([]);
    setSingleSite("");
    setSingleSegment("");
    setShowAddSegmentFLield(false);
    setToggleSiteDelete(false);
  };

  return (
    <>
      <div className="row pb-3">
        <div className="col-md-4 offset-md-1 newpromt mt-3 pt-1">
          <div className="mt-1 mb-2  position-relative">
            <label className="site_lable">Site</label>
            <br />
            <div className="site__field_wrapper">
              <input
                type="text"
                name="siteName"
                id="siteName"
                value={singleSite}
                onChange={(e) => {
                  setSingleSite(e.target.value);
                }}
                className={` site__input`}
                placeholder={`${t("siteSeg.sitePlaceholder")}`}
                onClick={() => {
                  // handleFocus(siteIndex);
                  if (arrayList.length - 1 === siteIndex) {
                    handleUpdate(siteIndex);
                  }
                  // setShowAddSegmentFLield(false);
                }}
              />
              <div>
                <CgClose
                  className="site_feild_icon"
                  onClick={() => {
                    if (singleSite && singleSite.trim() !== "") {
                      setToggleSiteDelete(true);
                      dispatch(createSiteSegment({ siteSegmentObject: null }));
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5  newpromt mt-0 pt-2">
          <div className="mt-3 mb-2  position-relative">
            <label className="site_lable">Segments</label>
            <br />

            <div
              className={`w-100 segment_feild`}
              style={{
                borderBottom: `${
                  showAddSegmentFLield
                    ? "0.5px solid #073b60"
                    : "0.5px solid #cccccc"
                }`,
              }}
            >
              {segmentList.length === 0 ? (
                <div
                  className="ps-2 w-100 "
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleShowAddSegmentBox();
                  }}
                >
                  + {t("siteSeg.segBtnplaceholder")}
                </div>
              ) : (
                <div className="add_segments__container">
                  {segmentList.map((seg, i) => (
                    <div
                      key={i}
                      className={` ${
                        singleSegmentId === i
                          ? "selectedSegment"
                          : "added__segment"
                      }`}
                    >
                      <div
                        onClick={() => {
                          setSingleSegment(segmentList[i]);
                          setCheckEditSegment(true);
                          setSingleSegmentId(i);
                          setShowAddSegmentFLield(true);
                        }}
                      >
                        {seg}
                      </div>{" "}
                      <CgClose
                        className="segment_close_icon ms-2 "
                        style={{
                          paddingTop: "2px",
                        }}
                        onClick={() => {
                          setSegmentIndex(i);
                          setSegmentDeleteMessage(
                            `${t("siteSeg.removeSegTitle")}  ${segmentList[i]}`
                          );
                          setSegmentDeleteMessageDetails(
                            ` Segment ${segmentList[i]} ${t(
                              "siteSeg.removeSegDetails"
                            )}`
                          );
                          setToggleSegmentDelete(true);
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}

              <div>
                <BiChevronDown
                  className={`segment_chevron ${
                    showAddSegmentFLield
                      ? "segment_feild_icon_rotate"
                      : "segment_feild_icon"
                  }`}
                  onClick={() => {
                    handleShowAddSegmentBox();
                  }}
                />
              </div>
            </div>
          </div>
          {showAddSegmentFLield && (
            <div className="segment__addBox">
              <div
                className="pb-1 "
                style={{
                  marginLeft: "5px",
                  color: "#595959",
                }}
              >
                {t("siteSeg.segTitle")}
              </div>

              <div className="segment__field_wrapper">
                <input
                  type="text"
                  name="segmentName"
                  id="segmentName"
                  value={singleSegment}
                  onChange={(e) => {
                    setSingleSegment(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleAddSingleSegmentToList(e);
                    }
                  }}
                  className={`${
                    checkEditSegment ? "segment__input_edit" : "segment__input"
                  }`}
                  placeholder={`${t("siteSeg.segPlaceholder")}`}
                />
                <div
                  className="addSegment__btn"
                  onClick={(e) => {
                    handleAddSingleSegmentToList(e);
                  }}
                >
                  <MdAdd className="segment_feild_icon" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {toggleSegmentDelete && (
        <ConfirmationModal
          cofirmSourceIcon={
            <BiErrorCircle
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
              color="red"
            />
          }
          message={`${segmentDeleteMessage}?`}
          details={segmentDeleteMessageDetails}
          btn1Text={t("siteSeg.cancelBtn")}
          btn2Text={t("siteSeg.removeBtn")}
          color={"red"}
          handleConfirmAction={handleDeleteSingleSegment}
          handleHideConfirmationModal={closeSegmentDeleteModal}
          closeConfirm={closeSegmentDeleteModal}
        />
      )}
      {toggleSiteDelete && (
        <ConfirmationModal
          cofirmSourceIcon={
            <BiErrorCircle
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
              color="red"
            />
          }
          message={`${t("siteSeg.removeSiteTitle")} ${singleSite}?`}
          details={`Site ${singleSite} ${t("siteSeg.removeSiteDetails")}`}
          btn1Text={t("siteSeg.cancelBtn")}
          btn2Text={t("siteSeg.removeBtn")}
          color={"red"}
          handleConfirmAction={handleDeleteSiteAndSegment}
          handleHideConfirmationModal={closeSiteDeleteModal}
          closeConfirm={closeSiteDeleteModal}
        />
      )}
    </>
  );
};

export default SiteSegment;
