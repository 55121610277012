import React from "react";
import { Redirect, Route } from "react-router-dom";
import { isAdmin, isAuthenticated } from "./index";
import { useSelector } from "react-redux";

//private route, only show for admin users

const AdminRoute = ({ component: Component, ...rest }) => {
  const userRole = useSelector((state) => state.userRoleOnParticularProject);
  const { role } = userRole;

  console.log(role, "role");
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() && role ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/listview-validate",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default AdminRoute;
