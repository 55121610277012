import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { API_URL as API } from "../services/API";

import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DETAILS_RESET,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_FAIL,
  USER_CONFIRM_ACCOUNT_REQUEST,
  USER_CONFIRM_ACCOUNT_SUCCESS,
  USER_CONFIRM_ACCOUNT_FAIL,
  REMOVE_USER_FROM_PROJECT,
} from "./../constants/userConstants";

const url = API + "api/user/project";

var options = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const getCoordonate = async () => {
  try {
    const {
      data: { data },
    } = await axios.get(url, options);
    return data;
  } catch (error) {
    console.log(`Fetch data Error: ${error}`);
  }
};

//login
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    //headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    //get login data
    const { data } = await axios.post(
      API + "api/user/login",
      { email, password },
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
    // window.location.reload(true);

    //set user info into local storage
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error && error.response.status === 403
          ? "validation required! Please check your mails to validate your account"
          : email.message === "Network Error"
          ? "Network Error"
          : "email or password incorrect",
      // error.response && error.response.data.message.message
      //   ? error.response.data.message
      //   : error.message,
    });
    console.log(error);
    console.log("This is error", error && error.message);
  }
};

// forgot password
export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({
      type: USER_FORGOT_PASSWORD_REQUEST,
    });

    //headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      API + "api/user/forgotPassword",
      { email },
      config
    );

    dispatch({
      type: USER_FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });

    // localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_FORGOT_PASSWORD_FAIL,
      payload: error && "an error occured",
      // error.response && error.response.data.message.message
      //   ? error.response.data.message
      //   : error.message,
    });
    console.log("This is error", error);
  }
};
// reset password
export const resetPassword = (code, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_RESET_PASSWORD_REQUEST,
    });

    //headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    //get login data
    const { data } = await axios.post(
      API + `api/user/resetPassword?password=${password}&code=${code}`,
      // { code: code, password: password },
      config
    );

    if (data.code === 200) {
      setInterval(() => {
        document.location.href = "/login";
      }, 5000);
    }

    dispatch({
      type: USER_RESET_PASSWORD_SUCCESS,
      payload: data,
    });

    // localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_RESET_PASSWORD_FAIL,
      payload: error && "Invalide code",
      // error.response && error.response.data.message.message
      //   ? error.response.data.message
      //   : error.message,
    });
    console.log("This is error", error);
  }
};

//logout
export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("projectId");
  localStorage.removeItem("projectName");
  localStorage.removeItem("selectedProject");
  localStorage.removeItem("projectList");
  localStorage.removeItem("userPrRole");
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_DETAILS_RESET });
  dispatch({ type: USER_LOGOUT });
  document.location.href = "/";
  // return <Redirect to="/" />;
  // router.push("/login");
};

//get all users
export const listUsers =
  (keyword = "", pageNumber = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_LIST_REQUEST,
      });

      //get user from state
      const {
        userLogin: { userInfo },
      } = getState();

      //headers
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      //get all users
      const { data } = await axios.get(
        API + `api/users?keyword=${keyword}&pageNumber=${pageNumber}`,
        config
      );
      dispatch({
        type: USER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

//register a user
export const registerUser =
  (
    fname,
    lname,
    organization,
    email,
    password,
    location,
    phoneWithoutCode,
    dialCode,
    code,
    fonction_id,
    country_id
  ) =>
  async (dispatch) => {
    // console.log({
    //   fname,
    //   lname,
    //   organization,
    //   email,
    //   password,
    //   location,
    //   phoneWithoutCode,
    //   dialCode,
    //   code,
    //   fonction_id,
    //   country_id,
    // });
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        API + "api/user/create",
        {
          first_name: fname,
          last_name: lname,
          organization: organization,
          email: email,
          password: password,
          city: location,
          phone: phoneWithoutCode,
          codeTel: dialCode,
          sigle: code,
          fonction_id: fonction_id,
          country_id: country_id,
        },
        config
      );

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data.message,
      });
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error && error.message === "Network Error"
            ? "Network Error"
            : "This email already exists",
        // error.response && error.response.data.message
        //   ? error.response.data.message
        //   : error.message,
      });
      console.log(error);
    }
  };

// confirm account creation
export const confirmregistration = (token) => async (dispatch) => {
  try {
    dispatch({
      type: USER_CONFIRM_ACCOUNT_REQUEST,
    });

    //headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      API + `api/user/validate?token=${token}`,
      // { code: code },
      config
    );

    dispatch({
      type: USER_CONFIRM_ACCOUNT_SUCCESS,
      payload: data,
    });

    if (data.code === 200) {
      setInterval(() => {
        document.location.href = "/login";
      }, 5000);
    }
  } catch (error) {
    dispatch({
      type: USER_CONFIRM_ACCOUNT_FAIL,
      payload: error && "Verification failed",
    });
    console.log(error, "Validation error");
  }
};

//get user details
export const listUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST });

    //get user from state
    const {
      userLogin: { userInfo },
    } = getState();

    //headers
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    //api call to get product
    const { data } = await axios.get(API + `api/users/${id}`, config);
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//update a user
export const updateUser = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    });

    //get user from state
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    //update user
    const { data } = await axios.put(
      API + `api/users/${user.id}`,
      user,
      config
    );
    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//delete user
export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });

    //get user from state
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    //api call to delete user
    await axios.delete(API + `api/users/${id}`, config);
    dispatch({
      type: USER_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//update profile
export const updateProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    });

    //get user from state
    const {
      userLogin: { userInfo },
    } = getState();
    //headers
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    //update user
    const { data } = await axios.put(
      API + `api/users/profile/${user.id}`,
      user,
      config
    );

    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
// get single observation
export const removeUserAction = (value) => (dispatch) => {
  dispatch({
    type: REMOVE_USER_FROM_PROJECT,
    payload: value,
  });
};
