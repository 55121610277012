import React, { useEffect, useState } from "react";
import "./members.css";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import UsersList from "../userList/userList";
import { API_URL as API } from "../../services/API";
import Select from "react-select";
import axios from "axios";
import {
  displaySuccessMsgCreateProject,
  memberadd$,
  project$,
  setSuccedMemberadd,
  successCreateProject$,
  successModifieAccount$,
  successModifieProject$,
  userDelete$,
} from "../../services/rxjs";
import SucccessMsg from "../adminSuccessMsg/successMsg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Members = ({ project, user, proId }) => {
  // Translation
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [assignBtn, setAssignBtn] = useState(true);
  const [listusers, setListusers] = useState([]);
  const [selectuser, setSelectuser] = useState();
  const [roleuser, setRoleuser] = useState();
  const [refresh, setRefresh] = useState(false);
  const [memberCount, setMemberCount] = useState(0);
  const [successMsg, setSuccessMsg] = useState(false);

  const [memberadd, setMemberadd] = useState(false);
  const role = [
    { value: "Regular user", label: "Regular user" },
    { value: "Admin", label: "Admin" },
  ];
  const [userdelete, setUserdelete] = useState(false);

  const handleHideModal = () => {
    setShowModal(false);
  };

  // track user rmove from project
  const { isUserRemoved } = useSelector((state) => state.removeUserTracker);

  const {
    handleSubmit,
    // formState: { errors, isDirty, isValid },
  } = useForm({
    // mode: "onChange",
    // reValidateMode: "onChange",
    // resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {};

  const DelayMemberAddMessage = () => {};

  const changeProject = (project) => {
    if (project.role === "admin") setAdmin(true);
    else setAdmin(false);
  };

  useEffect(() => {
    project$.subscribe((changedProject) => changeProject(changedProject));
    successModifieAccount$.subscribe((result) => setSuccessMsg(result));
    successCreateProject$.subscribe((active) => {
      setSuccessMsg(active);
      if (active) displaySuccessMsgCreateProject();
    });
    successModifieProject$.subscribe((result) => setSuccessMsg(result));
    successModifieProject$.subscribe((active) => {});
    memberadd$.subscribe((active) => {
      setMemberadd(active);
      if (active) DelayMemberAddMessage();
    });

    userDelete$.subscribe((del) => {
      setUserdelete(del);
    });

    getUserNotOnProject(project.id);
  }, []);

  const filloption = (data) => {
    let content = [];

    if (data.length > 0)
      for (let i = 0; i < data.length; i++) {
        let newops = { value: data[i].id, label: data[i].email };
        content.push(newops);
      }

    // setOps(content);
    setListusers(content);
  };

  const getUserNotOnProject = async (id_project) => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const response = await axios.get(
      API + `api/userNoproject/list?project_id=${id_project}`,
      config
    );

    const data = response.data.data;

    filloption(data);
  };

  const setUsersCount = (usersCount) => {
    setMemberCount(usersCount);
  };

  const saving = () => {
    setAssignBtn(false);
    let role = "Regular user";
    let userId = listusers[0].value;

    if (roleuser !== undefined) role = roleuser.value;

    if (selectuser !== undefined) userId = selectuser.value;

    assign(userId, role);
  };

  const assign = (userId, role) => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    axios
      .post(
        user.api + "api/userproject/assign",
        {
          project_id: project.id,
          user_id: userId,
          role: role,
        },
        config
      )
      .then(function (response) {
        getUserNotOnProject(project.id);
        setRefresh(!refresh);
        setShowModal(false);
        setAssignBtn(true);
        setSuccedMemberadd();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    if (isUserRemoved) {
      getUserNotOnProject(project.id);
    }
  }, [isUserRemoved]);

  return (
    <div className="admin-content flex-fill ">
      <div className="container p-0 position-relative">
        {successMsg && (
          <SucccessMsg message={`${t("admin.projectDetails.saveChanges")}`} />
        )}

        {memberadd && (
          <div className="row pt-3 pb-3 succedmsg mb-3 me-0">
            <div className="col-md-12 offset-md-1 position-relative ">
              <svg
                width="32"
                height="22"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 7.168 24.832 0 16 0ZM16 28.8C8.944 28.8 3.2 23.056 3.2 16C3.2 8.944 8.944 3.2 16 3.2C23.056 3.2 28.8 8.944 28.8 16C28.8 23.056 23.056 28.8 16 28.8ZM23.344 8.928L12.8 19.472L8.656 15.344L6.4 17.6L12.8 24L25.6 11.2L23.344 8.928Z"
                  fill="#138101"
                />
              </svg>

              <span className="ml-5"> {t("admin.members.memberAdded")}</span>
            </div>
          </div>
        )}

        {userdelete && (
          <div className="row pt-3 pb-3 succedmsg mb-3 me-0">
            <div className="col-md-12 offset-md-1 position-relative ">
              <svg
                width="32"
                height="22"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 7.168 24.832 0 16 0ZM16 28.8C8.944 28.8 3.2 23.056 3.2 16C3.2 8.944 8.944 3.2 16 3.2C23.056 3.2 28.8 8.944 28.8 16C28.8 23.056 23.056 28.8 16 28.8ZM23.344 8.928L12.8 19.472L8.656 15.344L6.4 17.6L12.8 24L25.6 11.2L23.344 8.928Z"
                  fill="#138101"
                />
              </svg>

              <span className="ml-5"> {t("admin.members.memberRemoved")}</span>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-4 offset-md-1 position-relative">
            <span className="title-text d-block mb-3">
              {t("admin.members.members")} ({memberCount})
            </span>
          </div>
          <div className="col-md-7 text-end">
            {admin ? (
              <div
                className="btn modal_obs__btn px-1 d-inline-block me-3"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                <span className="mr-5 fs-4 d-inline-block txtplus">+ </span>
                <span className="txtadd"> {t("admin.members.add")} </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <UsersList
          projectId={project.id}
          token={user.token}
          onRefresh={refresh}
          defineUserNumber={setUsersCount}
        />
      </div>
      <Modal
        show={showModal}
        onHide={handleHideModal}
        backdrop="static"
        centered
        className="mx-auto"
        keyboard={false}
        size="lg"
        dialogClassName="modal-size"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header
          className="bg-cl-bl text-left "
          style={{ color: "white" }}
        >
          <Modal.Title className="w-100 text-left">
            {" "}
            {t("admin.members.asignUser")}
          </Modal.Title>

          <div className="legend-close text-end">
            <span
              className="pe-sm-2 m-2 pointer d-block mt-3"
              onClick={handleHideModal}
            >
              <img
                src="assets/elements/images/cross.png"
                alt=""
                style={{ height: "15px" }}
              />
            </span>
          </div>
        </Modal.Header>

        {true ? (
          <>
            <Modal.Body>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="pe-5 ps-5 mt-4 mb-4"
              >
                <div className=" position-relative">
                  <span className="d-block ">
                    {" "}
                    {t("admin.members.username")}
                  </span>

                  <Select
                    options={listusers}
                    // {...country}
                    onChange={(choice) => setSelectuser(choice)}
                    placeholder={`${t("admin.members.userNamePlaceholder")}`}
                    // defaultValue={listusers[0]}
                  />
                </div>
                <div className=" position-relative my-3">
                  <span className="d-block ">
                    {t("admin.members.asignRole")}
                  </span>

                  <Select
                    // className={`w-100 rounded password form-control `}
                    options={role}
                    name="role"
                    onChange={(choice) => setRoleuser(choice)}
                    placeholder={`${t("admin.members.asignRolePlaceholder")}`}
                    defaultValue={role.filter(
                      (option) => option.label === "Regular user"
                    )}
                  />
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <div
                className="d-flex justify-content-end"
                style={{
                  width: "100%",
                }}
              >
                <div
                  className="btn btn-lg  px-3 btn-validate1  mx-1"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  {t("cancelBtn")}
                </div>
                {assignBtn ? (
                  <div
                    className="btn btn-lg  px-3 btn-validate2 mx-1"
                    onClick={() => {
                      saving();
                    }}
                  >
                    {t("saveBtn")}
                  </div>
                ) : (
                  <div className="btn btn-lg px-3 btn-validateDesable mx-1">
                    {t("saveBtn")}
                  </div>
                )}
              </div>
            </Modal.Footer>
          </>
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
};

export default Members;
