import "./draft.css";

// create custom icon

function Draft() {
  return (
    <div classNameName="map_content ">
      <div className="widgets_inner">
        <div className="range_item">
          <input
            type="range"
            className="js-range-slider"
            value=""
            data-skin="round"
            data-min="0"
            data-max="100"
            data-grid="false"
          />
          <div className="d-flex align-items-center">
            <div className="price_text">
              <p>Âge e:</p>
            </div>
            <div className="price_value d-flex justify-content-center">
              <input
                type="text"
                className="js-input-from"
                id="amount"
                value="0"
                readonly
              />
              <span>à</span>
              <input
                type="text"
                className="js-input-to"
                id="amount"
                value="100"
                readonly
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Draft;
