import { useQuery } from "@tanstack/react-query";
import { API_URL as API } from "../services/API";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

export const useFetchObservaionTypeList = () => {
  const { t, i18n } = useTranslation();
  const [finalData, setfinalData] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data, isError, isLoading } = useQuery(["obsTypeOptions"], () => {
    return axios
      .get(API + "api/typeObservation/list", config)
      .then((res) => res.data);
  });
  const lang = localStorage.getItem("localLangVal");
  const [language, setLanguage] = useState(i18n.language);

  // Listen for language change and update the state
  useEffect(() => {
    const handleLanguageChange = () => {
      setLanguage(i18n.language);
    };
    i18n.on("languageChanged", handleLanguageChange);
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);
  useEffect(() => {
    if (!lang || lang === "English") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("fr");
    }
  }, [lang, i18n]);

  const currentLangage = i18n.language;
  useEffect(() => {
    if (data) {
      const updatedData = data.data.map((item) => {
        if (item.name === "Flash Observation") {
          return {
            ...item,
            name: `${t("dbData.obsTypes.flashObservation")}`,
          };
        } else if (item.name === "Animal") {
          return {
            ...item,
            name: `${t("dbData.obsTypes.animal")}`,
          };
        } else if (item.name === "Threat") {
          return {
            ...item,
            name: `${t("dbData.obsTypes.threat")}`,
          };
        } else if (item.name === "Crawl") {
          return {
            ...item,
            name: `${t("dbData.obsTypes.crawl")}`,
          };
        } else if (item.name === "Female Encounter") {
          return {
            ...item,
            name: `${t("dbData.obsTypes.femaleEncounter")}`,
          };
        } else if (item.name === "Nest") {
          return {
            ...item,
            name: `${t("dbData.obsTypes.nest")}`,
          };
        } else if (item.name === "Signs of presence") {
          return {
            ...item,
            name: `${t("dbData.obsTypes.signPresence")}`,
          };
        } else if (item.name === "Other") {
          return {
            ...item,
            name: `${t("dbData.obsTypes.others")}`,
          };
        }
        return item;
      });
      setfinalData(updatedData);
    }
  }, [data, currentLangage, t]);

  return { observationList: finalData, isError, isLoading };
};

export const useFetchSeSiteList = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data, isError, isLoading, refetch, isRefetching } = useQuery(
    ["siteList"],
    () => {
      return axios.get(API + "api/site/list", config).then((res) => res.data);
    }
  );

  return {
    siteList: data,
    isError,
    siteLoading: isLoading,
    siteRefetch: refetch,
    siteIsRefetching: isRefetching,
  };
};
export const useFetchSiteSgmntListByProject = (projectId) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  // api/siteSegment/list
  const { data, isError, error, isLoading, refetch, isRefetching } = useQuery(
    ["sitesAndSegmentsList"],
    () => {
      return axios
        .get(API + `api/siteSegment/list/?projectId=${projectId}`, config)
        .then((res) => res.data);
    }
  );

  return {
    siteSegmentList: data,
    siteSegmentError: error,
    siteSegmentLoading: isLoading,
    refetchSiteSegment: refetch,
    siteSegmentIsRefetching: isRefetching,
  };
};
export const useSegmentAvailabilityCheck = (projectId) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data, error, refetch } = useQuery(
    ["segMentAVailabilityCheck"],
    () => {
      return axios
        .get(API + `api/siteSegment/check?projectId=${projectId}`, config)
        .then((res) => res.data);
    }
  );
  useEffect(() => {
    refetch();
  }, [projectId, refetch]);

  return {
    isSegAvailable: data?.data[0],
    isSegAvailableError: error,
  };
};
export const useNestIdAvailabilityCheck = (projectId) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data, error, refetch } = useQuery(["NestIdAvailabilityCheck"], () => {
    return axios
      .get(API + `api/project/if_nest?project_id=${projectId}`, config)
      .then((res) => res.data);
  });

  useEffect(() => {
    refetch();
  }, [projectId, refetch]);

  return {
    isNestIdAvailable: data?.data?.exist,
    isNestIdAvailableError: error,
  };
};
export const useFetchSeSiteListByProject = (projectId) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data, isError, isLoading, refetch, isRefetching } = useQuery(
    ["siteListByProject"],
    () => {
      return axios
        .get(API + `api/site/byproject?project_id=${projectId}`, config)
        .then((res) => res.data);
    }
  );

  return {
    siteListByProject: data,
    isError,
    siteLoadingByProject: isLoading,
    siteRefetchByProject: refetch,
    siteIsRefetchingByProject: isRefetching,
  };
};

export const useFetchSegmntList = (site_id) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  // api/siteSegment/list
  const { data, isError, isLoading, refetch, isRefetching } = useQuery(
    ["segmantList"],
    () => {
      return axios
        .get(API + `api/segment/site/list/?site_id=${site_id}`, config)
        .then((res) => res.data);
    }
  );

  return {
    segmentList: data,
    isError,
    segmentLoading: isLoading,
    refetchSegment: refetch,
    segmentIsRefetching: isRefetching,
  };
};

export const useFetchGroupList = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data, isError, isLoading, isRefetching } = useQuery(
    ["GroupeList"],
    () => {
      return axios.get(API + "api/group/list", config).then((res) => res.data);
    }
  );

  return {
    groupeList: data,
    isError,
    groupLoading: isLoading,
    groupIsRefetching: isRefetching,
  };
};

export const useFetchFamilyList = (group_id) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data, isError, isLoading, refetch, isRefetching } = useQuery(
    ["FamilyList"],
    () => {
      return axios
        .get(API + `api/subgroup/group/list?group_id=${group_id}`, config)
        .then((res) => res.data);
    }
  );

  return {
    familyList: data,
    isError,
    familyLoading: isLoading,
    familyListRefetch: refetch,
    familyIsRefetching: isRefetching,
  };
};

export const useFetchSeSpeciesList = (subgroup_id) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data, isError, isLoading, refetch, isRefetching } = useQuery(
    ["speciesList"],
    () => {
      return axios
        .get(
          API + `api/specie/subgroup/list?subgroup_id=${subgroup_id}`,
          config
        )
        .then((res) => res.data);
    }
  );

  return {
    speciesList: data,
    isError,
    speciesLoading: isLoading,
    speciesIsRefetching: isRefetching,
    refetchSpeciesList: refetch,
  };
};

export const useFetchFilteredObsertionTypeList = (projectId) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { t, i18n } = useTranslation();
  const [finalData, setfinalData] = useState([]);
  const token = userInfo.data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data, isError, isLoading, refetch } = useQuery(
    ["FilterObsList"],
    () => {
      return axios
        .get(API + `api/user/typeObservation/project?id=${projectId}`, config)
        .then((res) => res.data);
    }
  );
  const currentLangage = i18n.language;

  useEffect(() => {
    if (currentLangage === "en" && data) {
      setfinalData(data.data);
    } else if (currentLangage === "fr" && data) {
      const updatedData = data.data.map((item) => {
        if (item.name === "Flash Observation") {
          return {
            ...item,
            name: `${t("dbData.obsTypes.flashObservation")}`,
          };
        } else if (item.name === "Animal") {
          return {
            ...item,
            name: `${t("dbData.obsTypes.animal")}`,
          };
        } else if (item.name === "Threat") {
          return {
            ...item,
            name: `${t("dbData.obsTypes.threat")}`,
          };
        } else if (item.name === "Crawl") {
          return {
            ...item,
            name: `${t("dbData.obsTypes.crawl")}`,
          };
        } else if (item.name === "Female Encounter") {
          return {
            ...item,
            name: `${t("dbData.obsTypes.femaleEncounter")}`,
          };
        } else if (item.name === "Nest") {
          return {
            ...item,
            name: `${t("dbData.obsTypes.nest")}`,
          };
        } else if (item.name === "Other") {
          return {
            ...item,
            name: `${t("dbData.obsTypes.others")}`,
          };
        }
        return item;
      });
      setfinalData(updatedData);
    }
  }, [data, currentLangage, t]);

  return {
    FilterObsList: finalData,
    isError,
    isLoading,
    refetchFilterObs: refetch,
  };
};

export const useFetchSingleObs = (obsId) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data, isError, isLoading, refetch } = useQuery(
    ["singleObs"],
    () => {
      if (obsId.singleObsId === null) {
        return Promise.resolve(null);
      }
      return axios
        .get(API + `api/observation/readOne/?id=${obsId.singleObsId}`, config)
        .then((res) => res.data);
    },
    {
      onError: (error) => {
        console.error(error.message);
      },
    }
  );
  console.log(data, "readone data");
  return {
    singleObs: data,
    isError,
    singleObsLoaing: isLoading,
    refetchSIngleObs: refetch,
  };
};

export const useFetchCountryList = () => {
  const { data, isError, isLoading, refetch } = useQuery(
    ["countryList"],
    () => {
      return axios
        .get(API + `api/noconnect/country/list`)
        .then((res) => res.data);
    },
    {
      onError: (error) => {
        console.error(error.message);
      },
    }
  );

  return {
    queryCountryList: data?.data,
    isError,
    countryListLoading: isLoading,
    refetchCountryList: refetch,
  };
};

export const useFetchCFunctionList = () => {
  const { data, isError, isLoading, refetch } = useQuery(
    ["functionList"],
    () => {
      return axios
        .get(API + `api/noconnect/function/list`)
        .then((res) => res.data);
    },
    {
      onError: (error) => {
        console.error(error.message);
      },
    }
  );

  const { t, i18n } = useTranslation();

  const lang = localStorage.getItem("localLangVal");
  const [language, setLanguage] = useState(i18n.language);

  // Listen for language change and update the state
  useEffect(() => {
    const handleLanguageChange = () => {
      setLanguage(i18n.language);
    };
    i18n.on("languageChanged", handleLanguageChange);
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);
  useEffect(() => {
    if (!lang || lang === "English") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("fr");
    }
  }, [lang, i18n]);

  const currentLangage = i18n.language;
  const [finalData, setFinalData] = useState([]);
  useEffect(() => {
    if (currentLangage === "en" && data) {
      setFinalData(data.data);
    } else if (currentLangage === "fr" && data) {
      const updatedData = data.data.map((item) => {
        if (item.id === 5) {
          return {
            ...item,
            title: `${t("dbData.job.corporation")}`,
          };
        } else if (item.id === 3) {
          return {
            ...item,
            title: `${t("dbData.job.diver")}`,
          };
        } else if (item.id === 10) {
          return {
            ...item,
            title: `${t("dbData.job.student")}`,
          };
        } else if (item.id === 4) {
          return {
            ...item,
            title: `${t("dbData.job.tourist")}`,
          };
        } else if (item.id === 2) {
          return {
            ...item,
            title: `${t("dbData.job.research")}`,
          };
        } else if (item.id === 12) {
          return {
            ...item,
            title: `${t("dbData.job.fishSeller")}`,
          };
        } else if (item.id === 1) {
          return {
            ...item,
            title: `${t("dbData.job.fisherMen")}`,
          };
        } else if (item.id === 9) {
          return {
            ...item,
            title: `${t("dbData.job.generalPublic")}`,
          };
        } else if (item.id === 11) {
          return {
            ...item,
            title: `${t("dbData.job.government")}`,
          };
        } else if (item.id === 8) {
          return {
            ...item,
            title: `${t("dbData.job.localCom")}`,
          };
        } else if (item.id === 7) {
          return {
            ...item,
            title: `${t("dbData.job.nonProfit")}`,
          };
        } else if (item.id === 6) {
          return {
            ...item,
            title: `${t("dbData.job.others")}`,
          };
        }
        return item;
      });

      setFinalData(updatedData);
    }
  }, [data, currentLangage, t, i18n, language, lang]);

  return {
    functionListData: finalData,
    originalFuncData: data?.data,
    functionListError: isError,
    functionListLoading: isLoading,
    refetchFunctionList: refetch,
  };
};
