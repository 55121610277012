import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Val = ({ data }) => {
  return (
    <div>
      <div className="">
        <Link
          to={`/patrols/observations/valid/${data.id}`}
          style={{
            textAlign: "center",
            display: "flex",
            width: "50px",
            fontWeight: "bold",
            cursor: "pointer",
            textDecoration: "underline",
            color: "#138101",
            justifyContent: "center",
          }}
        >
          {data.valObs}
        </Link>
      </div>
    </div>
  );
};

export default Val;
