import React, { useEffect } from "react";
import "./styles.css";
import { Link } from "react-router-dom";

import NavHeader from "../../components/NavHeader";
import SubHeader from "../../components/SubHeader";
import LandingCarousel from "../../components/LandingPageSections/LandingCarousel";
import HowItWorks from "../../components/LandingPageSections/HowItWorks";
import AtGlance from "../../components/LandingPageSections/AtGlance";
import SightingMap from "../../components/LandingPageSections/SightingMap";
import ProductHighlights from "../../components/LandingPageSections/ProductHighlights";
import OurPartners from "../../components/LandingPageSections/OurPartners";
import LandingPageFooter from "../../components/LandingPageSections/LandingPageFooter";
import LandingFooter from "../../components/LandingPageSections/LandingFooter";
import LandingEntrance from "../../components/LandingPageSections/LandingEntrance";
import LatestNews from "../../components/LandingPageSections/LatestNews";
import GetInvolved from "../../components/LandingPageSections/GetInvolved";
import MobileApp from "../../components/LandingPageSections/MobileApp";
import { useTranslation } from "react-i18next";
import ProgressBarComponent from "../../components/progressBar/ProgressBar";

const Home = () => {
  const { t, i18n } = useTranslation();

  const lang = localStorage.getItem("localLangVal");
  useEffect(() => {
    if (!lang || lang === "English") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("fr");
    }
  }, [lang, i18n]);
  return (
    <div className="p-0 landingPage">
      <header className="LandingHeaderWrapper">
        <NavHeader />
        {/* <SubHeader /> */}
      </header>
      <LandingCarousel />
      {/* <LandingEntrance /> */}
      {/* <ProgressBarComponent /> */}
      <LatestNews />
      <AtGlance />
      <SightingMap />
      <ProductHighlights />
      <HowItWorks />
      <GetInvolved />
      <OurPartners />
      <LandingFooter />

      {/* <MobileApp /> */}
      {/* <LandingPageFooter /> */}
    </div>
  );
};

export default Home;
