import React from "react";
import Card from "react-bootstrap/Card";
import SecondaryBtn from "../LandingPageSections/SecondaryBtn";
import "./styles.css";

export default function GetInvolvedCard(params) {
  const { img, title, text, btnText, link, onClick } = params;
  return (
    <Card className="getInvolvedCard">
      <Card.Img className="GetInvolvedCardImage" src={img}></Card.Img>
      <Card.Body className="GetInvolvedCardBody">
        <Card.Title>
          <h4 className="GetInvolvedCardTitle lh-1 ms-sm-0">{title}</h4>
        </Card.Title>
        <Card.Text className="GetInvolvedCardText ms-sm-0 p-0">
          {text}
        </Card.Text>
      </Card.Body>
      <div className="getInvolvedCard-btnWrapper">
        <SecondaryBtn text={btnText} link={link} onClick={onClick} />
      </div>
    </Card>
  );
}
