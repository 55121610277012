import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";

import { useTranslation } from "react-i18next";
import Loading from "../../../components/LoadingError/Loading";
import { useFetchVIPObsByGroup } from "../../../queries/useFetchVIPDashboard";

const VIPDoughnutChart = ({ dates }) => {
  const { obsByGroup, obsByGroupError, obsByGroupLoading } =
    useFetchVIPObsByGroup({ dates });
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  useEffect(() => {
    if (obsByGroup) {
      const values = Object.values(obsByGroup);
      const labels = [
        `${t("dashboard.species.mammals")}`,
        `${t("dashboard.species.reptiles")}`,
        `${t("dashboard.species.sharks")}`,
        `${t("dashboard.species.fish")}`,
        `${t("dashboard.species.birds")}`,
        `${t("dashboard.species.rays")}`,
        `${t("dashboard.species.others")}`,
      ];

      const ctx = chartRef.current.getContext("2d");

      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      chartInstance.current = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: labels,
          datasets: [
            {
              label: `${t("dashboard.obsByGroup")}`,
              data: values,
              backgroundColor: [
                "#E4F5FF",
                "#B2DBFC",
                "#72B2E3",
                "#3A8AC9",
                "#72B2E3",
                "#72B2E3",
                "#72B2E3",
              ],
              borderWidth: 0,
            },
          ],
        },
        options: {
          cutout: "70%",
          plugins: {
            legend: {
              display: true,
              position: "bottom",
              labels: {
                usePointStyle: true,
                boxWidth: 5,
                boxHeight: 5,
                padding: 30,
              },
            },
          },
        },
      });

      return () => {
        if (chartInstance.current) {
          chartInstance.current.destroy();
        }
      };
    }
  }, [obsByGroup, dates, currentLanguage, t]);
  useEffect(() => {
    setCurrentLanguage(i18n.language); // Update current language when language changes
  }, [i18n.language]);
  if (obsByGroupLoading) {
    return (
      <div className="doghnutContainer">
        <Loading />
      </div>
    );
  }

  return <canvas ref={chartRef} width={300} height={300} />;
};

export default VIPDoughnutChart;
