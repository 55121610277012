import React, { useEffect, useState } from "react";
import "./detailproject.css";

const DetailProject = ({ childrenCheck, project, goForm }) => {
  const goToForm = () => {
    goForm();
  };

  return (
    <div className="admin-content flex-fill">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-end">
            <div
              className="btn modal_obs__btn this px-1 d-inline-block me-3"
              onClick={() => {
                goToForm();
              }}
            >
              <span className="mr-5 fs-4 d-inline-block txtplus">+ </span>
              <span className="txtadd"> New project </span>
            </div>
          </div>
          <div className="col-md-4 offset-md-1">
            <span className="title-text d-block mb-3 hov">{project.name}</span>
            <div className="mt-4 mb-2  position-relative">
              <div>Project name</div>
              <div className="mt-2 colb ms-4">{project.name}</div>
            </div>

            <div className="mt-4 mb-2  position-relative">
              <div>Location (City/ town/ beach/ Island,...) </div>
              <div className="mt-2 colb ms-4">{project.location}</div>
            </div>

            <div className="mt-4 mb-2  position-relative">
              <div>Organization</div>
              <div className="mt-2 colb ms-4">{project.organization}</div>
            </div>
          </div>
          <div className="col-md-4 offset-md-1">
            <span className="title-text d-block mb-3">&nbsp;</span>

            <div className="mt-4 mb-2  position-relative">
              <div>Country</div>
              <div className="mt-2 colb ms-4">{project.country}</div>
            </div>

            <div className="mt-4 mb-2  position-relative">
              <div>Project time zone</div>
              <div className="mt-2 colb ms-4">{project.timezone}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailProject;
