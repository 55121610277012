import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { useFetchObsByMonth } from "../../queries/useFetchDashboardData";
import Loading from "../LoadingError/Loading";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const VerticalBarChart = ({ dates }) => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const { obsStatsByMonth, obsStatsByMonthError, obsStatsByMonthLoading } =
    useFetchObsByMonth({ dates, localProjectId });

  useEffect(() => {
    if (chartRef.current && obsStatsByMonth && !chartInstance.current) {
      const ctx = chartRef.current.getContext("2d");

      const devicePixelRatio = window.devicePixelRatio || 1;
      chartRef.current.width = chartRef.current.clientWidth * devicePixelRatio;
      chartRef.current.height = 250 * devicePixelRatio;

      const labels = [
        `${t("dashboard.months.JAN")}`,
        `${t("dashboard.months.FEB")}`,
        `${t("dashboard.months.MAR")}`,
        `${t("dashboard.months.APR")}`,
        `${t("dashboard.months.MAY")}`,
        `${t("dashboard.months.JUN")}`,
        `${t("dashboard.months.JUL")}`,
        `${t("dashboard.months.AUG")}`,
        `${t("dashboard.months.SEP")}`,
        `${t("dashboard.months.OCT")}`,
        `${t("dashboard.months.NOV")}`,
        `${t("dashboard.months.DEC")}`,
      ];
      const data = Object.values(obsStatsByMonth);

      chartInstance.current = new Chart(ctx, {
        type: "bar",
        data: {
          labels: labels,
          datasets: [
            {
              label: `${t("dashboard.obsByMonth")}`,
              data: data,
              backgroundColor: "#3A8AC9",
              barThickness: 30,
            },
          ],
        },
        options: {
          scales: {
            x: {
              display: true,
              beginAtZero: true,
            },
            y: {
              display: true,
            },
          },
        },
      });
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
        chartInstance.current = null;
      }
    };
  }, [obsStatsByMonth, localProjectId, dates, currentLanguage, t]);
  useEffect(() => {
    setCurrentLanguage(i18n.language); // Update current language when language changes
  }, [i18n.language]);
  if (obsStatsByMonthLoading) {
    return (
      <div className="verticalBarLoadingContaier">
        <Loading />
      </div>
    );
  }

  return <canvas ref={chartRef} className="verticalBarChartCanvas" />;
};

export default VerticalBarChart;
