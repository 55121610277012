import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./styles/style.css";
import logo from "../../assets/images/logos.png";
// Css import
import "./login.css";
// form Validation imports
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Loading from "../../components/LoadingError/Loading";
import Message from "../../components/LoadingError/Error";
import axios from "axios";
import { API_URL as API } from "../../services/API";
import turtle from "../../assets/images/turtleCompressed.png";
import dolphin from "../../assets/images/DolphinCompressed.png";
import shark from "../../assets/images/SharkimageCompressed.png";
import turtlesmall from "../../assets/images/Turtle-image-small.png";
import dolphinsmall from "../../assets/images/Dolphin-image-small-3.png";
import sharksmall from "../../assets/images/Shark-image-small-2.png";
import { confirmregistration } from "../../actions/userActions";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import { BsArrowLeftShort } from "react-icons/bs";
// Randomly renderin login images
const loginImagesPath = [turtle, dolphin, shark];
// mobile images imports
const loginImagesPathMoble = [turtlesmall, dolphinsmall, sharksmall];

let imagePathId = Math.floor(Math.random() * 3);
const mystyle = {
  background: `url(${loginImagesPath[imagePathId]})`,
  // backgroundSize: "cover",
  backgroundSize: " 100% 100%",
  backgroundRepeat: "no-repeat",
};
const mystyleMobile = {
  background: `url(${loginImagesPathMoble[imagePathId]})`,
  // backgroundSize: "cover",
  backgroundSize: "  cover",

  backgroundRepeat: "no-repeat",
};

const RegisterConfirmation = () => {
  const searchParams = new URLSearchParams(document.location.search);

  const userConfrimAccont = useSelector((state) => state.userConfrimAccont);
  const { userInfo, error, loading } = userConfrimAccont;

  const dispatch = useDispatch();
  const userToken = searchParams.get("token");

  useEffect(() => {
    if (userToken) {
      const onSubmit = async () => {
        dispatch(confirmregistration(userToken));
      };
      onSubmit();
    }
  }, []);
  const validationSchema = yup.object().shape({
    code: yup
      .number()
      .required("confirmation code is required")
      .typeError("code is required and must be a number"),
  });
  const {
    register,

    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = (data) => {
    dispatch(confirmregistration(data.code));
  };

  return (
    <>
      {/* {userInfo && (
        <SuccessAlert message={"Account Activated.  redirecting to Login"} />
      )} */}
      <div
        className="container-fluid p-0 login-page"
        style={{ overflow: "hidden" }}
      >
        <div className="row header bg-cl-bl d-md-none d-lg-none d-sm-none ">
          <div className="col-xs-12 text-center">
            <img src={logo} alt="" className="logo" />
          </div>
        </div>
        <div
          className="row header-img bg-cl-bl d-md-none d-lg-none d-sm-none "
          style={mystyleMobile}
        ></div>

        <div
          className={`row ${1} ,row-cols-2,row-cols-3, auto,justify-content-md-center vh-100`}
        >
          <div className="col-md-6 col-sm-9 p-5">
            <div className="row d-none d-sm-block">
              <Link to={"/"}>
                <img src={logo} alt="" className="logos" />
              </Link>
            </div>

            <div
              className="row  position-relative"
              style={{ marginTop: "5.5rem" }}
            >
              <div
                className="col-md-8 offset-md-2 align-self-center"
                id="block-reset"
              >
                {error ? (
                  <Message variant="alert-danger">Validation error</Message>
                ) : (
                  ""
                )}
                {loading && <Loading />}

                {!userInfo && (
                  <div className="mb-3">
                    <div className="mb-5 mt-3 pb-5 h4 fw-bold">
                      Registration confirmation{" "}
                    </div>
                    {/* <div className="cl-gr">Enter the code received via mail</div> */}
                  </div>
                )}
                {userInfo && (
                  <>
                    <div className="mb-3">
                      <div className="mb-5 mt-5 pb-5 h4 ">
                        Your account has been successfully activated. You will
                        be automatically redirected to the login page within 5
                        seconds or click below.
                      </div>
                      {/* <div className="cl-gr">Enter the code received via mail</div> */}
                    </div>

                    <div
                      className="position-relative mt-5 pt-5"
                      style={{
                        color: "#096DBB",
                      }}
                    >
                      <Link className=" noDeco" to={"/login"}>
                        {/* <img
                          src="assets/elements/images/icon/arrow-left.svg"
                          alt=""
                          className="d-inline-block"
                        />{" "} */}
                        <BsArrowLeftShort
                          className="d-inline-block"
                          style={{
                            fontSize: "60px",
                            paddingBottom: "5px",
                            fontWeight: "bold",
                          }}
                        />
                        <span
                          // className="ps-1"
                          style={{
                            fontSize: "22px",
                            fontWeight: "bold",
                          }}
                        >
                          Back to login
                        </span>{" "}
                      </Link>
                    </div>
                  </>
                )}
                <div>
                  {/* <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-4 mb-1 position-relative">
                      <label>Confirmation code </label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter your cnfirmation code "
                        {...register("code")}
                        className="w-100 emailtxt form-control"
                      />
                    </div>
                    <p className="error">{errors.code?.message}</p>
                    <div className="mt-5"></div>
                    {userInfo && (
                    <div className="text-center mt-3">
                      <button
                        // type="submit"
                        className="btn btn-primary btn-lg btn-block w-100 mt-4 setsend"
                      >
                      
                        <Link
                          className="lien noDeco"
                          to={"/login"}
                          style={{ color: "white", textDecoration: "none" }}
                        >
                          {" "}
                          <img
                            src="assets/elements/images/icon/arrow-left.svg"
                            alt=""
                            className="d-inline-block"
                          />{" "}
                          <span className="ps-2">Go to login</span>
                        </Link>
                      </button>
                    </div>
                  )}

                    <div className="text-center mt-3">
                      <button
                        type="submit"
                        disabled={!isDirty || !isValid}
                        className=" btn-lg btn-block w-100 mt-4 login-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </form> */}
                </div>
              </div>
              <div
                className="col-md-8 offset-md-2 d-none align-self-center"
                id="block-mail"
              >
                {/* <div className="mb-3">
                <div className="mb-3 h3 f-bold">Check your email</div>
                <div className="cl-gr">
                  The instructions to reset your password have been sent to your
                  email address
                </div>
              </div> */}
                <div className="position-relative mt-5">
                  <Link className="lien noDeco" to={"/login"}>
                    <img
                      src="assets/elements/images/icon/arrow-left.svg"
                      alt=""
                      className="d-inline-block"
                    />{" "}
                    <span className="ps-2">Back to login</span>{" "}
                  </Link>
                </div>
              </div>
              <div
                className="position-absolute d-none"
                id="block-try"
                style={{ bottom: "0px" }}
              >
                {/* <div className="text-center mt-3">
                Did not receive the email? Check your spam filter, <br />
                or{" "}
                <a className="lien" href="#">
                  {" "}
                  try another email address
                </a>
              </div> */}
              </div>
            </div>
            <div className="h-70"></div>
          </div>
          <div
            className="col-md-6 col-sm-3 d-none d-md-block"
            //   style={{ background: `url(assets/elements/images/turtle.jpg)` }}
            style={mystyle}
          ></div>
        </div>
      </div>
    </>
  );
};

export default RegisterConfirmation;
