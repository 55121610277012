import React from "react";
import Loading from "./Loading";
import "./Loading.css";
const Loading2 = () => {
  return (
    <div className="loader__container">
      <Loading />
    </div>
  );
};

export default Loading2;
