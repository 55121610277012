import React from "react";
import "./styles.css";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SignUpBtn() {
  const { t } = useTranslation();
  return (
    <Link to="/register" className="signUpBtn">
      {t("signup")}
      <Image
        src="assets/elements/images/icon/blackDownArrow.svg"
        alt="dropDownArrow"
        className="signUpBtnRotate"
      />
    </Link>
  );
}
