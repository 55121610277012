import "./styles.css";
import Card from "react-bootstrap/Card";
import TitleComponent from "../../TitleComponent";
import SignUpBtn from "../SignUpBtn";

import { useTranslation } from "react-i18next";

function HowItWorks() {
  const { t } = useTranslation();

  const howItWorksCards = [
    {
      img: "./assets/apply.png",
      title: `${t("landingPage.content.howWorks.1.title")}`,
      text: `${t("landingPage.content.howWorks.1.details")}`,
    },
    {
      img: "./assets/create.png",
      title: `${t("landingPage.content.howWorks.2.title")}`,
      text: `${t("landingPage.content.howWorks.2.details")}`,
    },
    {
      img: "./assets/record.png",
      title: `${t("landingPage.content.howWorks.3.title")}`,
      text: `${t("landingPage.content.howWorks.3.details")}`,
    },
    {
      img: "./assets/track.png",
      title: `${t("landingPage.content.howWorks.4.title")}`,
      text: `${t("landingPage.content.howWorks.4.details")}`,
    },
    {
      img: "./assets/share.png",
      title: `${t("landingPage.content.howWorks.5.title")}`,
      text: `${t("landingPage.content.howWorks.5.details")}`,
    },
  ];

  return (
    <section id="howItWorks" className="howItWorksSection">
      <TitleComponent>{t("landingPage.content.howWorks.title")}</TitleComponent>
      <div
        fluid
        className="justify-content-center howItWorksRow container-fluid"
      >
        {howItWorksCards.map((card, index) => (
          <div className="mt-0 howItWorksSmall" key={index}>
            <Card className="worksCardMeasures">
              <Card.Text className="cardNumber">{index + 1}</Card.Text>
              <Card.Img
                className="cardImage img-fluid"
                variant="top"
                src={card.img}
              />
              <Card.Body className="cardBody">
                <Card.Title>
                  <h4 className="cardTitle text-uppercase fw-semibold lh-1 ms-sm-0">
                    {card.title}
                  </h4>
                </Card.Title>
                <Card.Text className="cardText lh-base ms-sm-0 p-0">
                  {card.text}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
      <div className="mt-0 howItWorksbtnWrapper">
        <SignUpBtn />
      </div>
    </section>
  );
}

export default HowItWorks;
