import React from "react";
import "./Confirmation.css";
import { Modal } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";

const ConfirmationModal = ({
  cofirmSourceIcon,
  message,
  details,
  btn1Text,
  btn2Text,
  color,
  handleHideConfirmationModal,
  handleConfirmAction,
  closeConfirm,
}) => {
  return (
    <div className="confrim__modal">
      <Modal
        show={true}
        onHide={handleHideConfirmationModal}
        backdrop="static"
        centered
        className="mx-auto confirmModal"
        keyboard={false}
        size="md"
        dialogClassName="tailledeletemodal"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <div className=" text-end p-2 ">
          <span
            className="pointer "
            onClick={() => {
              closeConfirm();
            }}
          >
            <RxCross2 className="close__icon" />
          </span>
        </div>

        <Modal.Body>
          <div className=" d-flex justify-content-center">
            {cofirmSourceIcon}
            {/* <img src={
            } alt=""  /> */}
          </div>
          <div className="text-center h5 mt-3" style={{ color: " #404040" }}>
            {message}
          </div>
          <div
            className="text-center mb-3 mt-3 px-3"
            style={{ color: " #191919", fontSize: "0.9rem" }}
          >
            {details}
          </div>
        </Modal.Body>

        <div
          className="d-flex mt-3"
          style={{
            borderTop: ".3px solid #CCCCCC",
          }}
        >
          <div
            className="text-center p-2 fs-7 validbtn"
            style={{
              borderRight: ".3px solid #CCCCCC",
              width: "50%",
              cursor: "pointer",
              color: "#404040",
            }}
            onClick={() => {
              handleHideConfirmationModal();
            }}
          >
            {btn1Text}
          </div>
          <div
            className="p-2 text-center fs-7 validbtn"
            style={{
              width: "50%",
              color: `${color}`,
              cursor: "pointer",
            }}
            onClick={() => {
              handleConfirmAction();
            }}
          >
            {btn2Text}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
