import GridTable from "@nadavshaar/react-grid-table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GetQuestionsColumn from "./getQuestionsColumn";
import carret from "../../../assets/images/pointbas.png";
import { useDispatch, useSelector } from "react-redux";
import { addQuestionData } from "../../../actions/projectActions";

const QuestionsTable = () => {
  const dispatch = useDispatch();
  const lang = localStorage.getItem("localLangVal");

  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    if (!lang || lang === "English") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("fr");
    }
  }, [lang, i18n]);
  // Listen for language change and update the state
  useEffect(() => {
    const handleLanguageChange = () => {
      setLanguage(i18n.language);
    };
    i18n.on("languageChanged", handleLanguageChange);
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);

  const { questionData } = useSelector((state) => state.addQuestionData);
  const [rowsData, setRowsData] = useState([
    { id: 1, title: "Number of small spechies", type: "text", answers: [] },
    { id: 2, title: "Number of big spechies", type: "text", answers: [] },
    { id: 3, title: "How many have you seen ", type: "text", answers: [] },
    {
      id: 4,
      title: "Hunted with ?",
      type: "select",
      answers: ["Gun", "I don't know"],
    },
    {
      id: 5,
      title: "Fishing tool hang on the rang ",
      type: "select",
      answers: [],
    },
    {
      id: 6,
      title: "In what type of fishing tools",
      type: "Select",
      answers: [],
    },
  ]);
  useEffect(() => {
    if (questionData.id) {
      if (questionData.id === -1) {
        setRowsData((prev) => {
          const newQuestionData = { ...questionData, id: prev.length + 1 };
          return [...prev, newQuestionData];
        });
      } else {
        setRowsData((prev) => {
          return prev.map((item) => {
            if (item.id === questionData.id) {
              return { ...item, ...questionData };
            }
            return item;
          });
        });
      }
      dispatch(
        addQuestionData({
          questionData: {
            id: null,
            title: "",
            type: "",
            answers: [],
          },
        })
      );
    }
  }, [questionData, dispatch]);
  const { questionId } = useSelector((state) => state.deleteQuestion);

  useEffect(() => {
    if (questionId) {
      setRowsData((prev) => {
        return prev.filter((item) => item.id !== questionId);
      });
    }
  }, [questionId]);

  return (
    <div>
      <GridTable
        enableColumnsReorder={false}
        columns={GetQuestionsColumn({ setRowsData })}
        key={language}
        //   isLoading={patrolListLoading}
        selectAllMode={"all"}
        //   selectedRowsIds={selectedRows}
        //   onSelectedRowsChange={setSelectedRows}
        icons={{
          sortAscending: (
            <img className="sortB rBottom" src={carret} alt="sortB" />
          ),
          sortDescending: <img className="sortB" src={carret} alt="sortB" />,
        }}
        texts={{
          search: `${t("gridTable.search")}`,
          totalRows: `${t("gridTable.totalRows")}`,
          rows: `${t("gridTable.rows")}`,
          selected: `${t("gridTable.selected")}`,
          rowsPerPage: `${t("gridTable.rowsPerPage")}`,
          page: "Page:",
          of: `${t("gridTable.of")}`,
          prev: `${t("gridTable.prev")}`,
          next: `${t("gridTable.next")}`,
        }}
        //   components={{
        //     Header: CustomHeader,
        //     Loader: TableLoader,
        //     NoResults: NoPatrolFound,
        //   }}
        showSearch={false}
        rows={rowsData}
      />
    </div>
  );
};

export default QuestionsTable;
