import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../services/API";
import { refetchObs } from "../actions/projectActions";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useFetchVIPObservaionList = ({ id }) => {
  const { observationTypesListFromReducer } = useSelector(
    (state) => state.observationTypesList
  );
  const { dateRangesArray } = useSelector(
    (state) => state.dateRangeStoreFunction
  );

  const refetchObsList = useSelector((state) => state.setRefetchObsList);
  const { refetchObsValue } = refetchObsList;
  const dispatch = useDispatch();

  const LIMIT = 2500;
  const [page, setPage] = useState();
  useMemo(() => {
    return setPage(id);
  }, [id]);
  const defaultApiBody = "api/user/project/vip/observation/byPage/list";
  const fetchObservations = async (page) => {
    const response = await fetch(
      API_URL +
        `${
          // Number(localProjectId) !== sirenInterNationalId
          //   ? defaultApiBody
          //   : defaultApiBody
          defaultApiBody
        }?numPage=${page}&id=${localStorage.getItem(
          "vid_projectId"
        )}&itemsPerPage=${2500}&data=${[
          observationTypesListFromReducer,
        ]}&date=${dateRangesArray}`,
      {
        code: id,
      }
    );

    dispatch(refetchObs({ refetchObsValue: false }));
    return response.json();
  };

  const {
    data,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    failureReason,
    isFetchingNextPage,
    isLoading,
    isRefetching,
    error,
    refetch,
    isError,
  } = useInfiniteQuery(
    ["VIPValidatedobservationsList", id],
    ({ pageParam = 1 }) => fetchObservations(pageParam),
    {
      queryFn: ({ pageParam = 1 }) => fetchObservations(pageParam),
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage.data.length === LIMIT ? allPages.length + 1 : undefined;

        return nextPage;
      },
      queryKey: id,
      refetchIntervalInBackground: true,
    }
  );

  if (failureReason && failureReason?.response?.data?.code === 401) {
    document.location.href = "/";
  }

  // console.log(data, "datadatadata");
  useEffect(() => {
    if (refetchObsValue[0] !== 0) {
      refetch();
    }
  }, [refetchObsValue, refetch, id]);
  useEffect(() => {
    if (dateRangesArray[0] !== 0) {
      refetch();
    }
  }, [dateRangesArray, refetch]);
  useEffect(() => {
    refetch();
  }, [observationTypesListFromReducer, refetch, dateRangesArray]);
  return {
    data,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isRefetchingObsList: isRefetching,
    obsListLoading: isLoading,
    obsFetchError: isError,
    queryRefetchValidObsList: refetch,
  };
};

export const useFetchVIPMiniCardObservaionList = ({ id }) => {
  const { observationTypesListFromReducer } = useSelector(
    (state) => state.observationTypesList
  );

  const { dateRangesArray } = useSelector(
    (state) => state.dateRangeStoreFunction
  );

  const refetchObsList = useSelector((state) => state.setRefetchObsList);
  const { refetchObsValue } = refetchObsList;
  const dispatch = useDispatch();
  const [page, setPage] = useState();
  const LIMIT = 50;

  const defaultApiBody = "api/user/project/vip/observation/byPage/list";
  useMemo(() => {
    return setPage(id);
  }, [id]);

  const fetchObservations = async (page) => {
    const response = await fetch(
      API_URL +
        `${defaultApiBody}?numPage=${page}&id=${localStorage.getItem(
          "vid_projectId"
        )}&itemsPerPage=${50}&data=${[
          observationTypesListFromReducer,
        ]}&date=${dateRangesArray}`
    );

    dispatch(refetchObs({ refetchObsValue: false }));
    return response.json();
  };

  const {
    data,
    isSuccess,
    hasNextPage,
    failureReason,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    isRefetching,
    refetch,
    isError,
  } = useInfiniteQuery(
    ["VIPMiniCardObservationsList", id],
    ({ pageParam = 1 }) => fetchObservations(pageParam),
    {
      queryFn: ({ pageParam = 1 }) => fetchObservations(pageParam),
      getNextPageParam: (lastPage, allPages) => {
        const nextPage =
          lastPage.data.length === LIMIT ? allPages.length + 1 : undefined;

        return nextPage;
      },
      queryKey: id,
      refetchIntervalInBackground: true,
    }
  );

  if (failureReason && failureReason?.response?.data?.code === 401) {
    document.location.href = "/";
  }

  useEffect(() => {
    if (refetchObsValue[0] !== 0) {
      refetch();
    }
  }, [refetchObsValue, refetch]);
  useEffect(() => {
    if (dateRangesArray[0] !== 0) {
      refetch();
    }
  }, [dateRangesArray, refetch]);
  useEffect(() => {
    refetch();
  }, [observationTypesListFromReducer, refetch, dateRangesArray]);
  return {
    miniCardData: data,
    miniCardIsSuccess: isSuccess,
    miniCardHasNextPage: hasNextPage,
    miniCardFetchNextPage: fetchNextPage,
    miniCardIsFetchingNextPage: isFetchingNextPage,
    miniCardIsRefetcing: isRefetching,
    miniCardObsLoading: isLoading,
    miniCardObsFetchError: isError,
    miniCardQueryRefetchObsList: refetch,
  };
};
