import React, { useState } from "react";
import "./questions.css";
import QuestionsTable from "./tables/QuestionsTable";
import AddQuestionModal from "./AddQuestionModal";
const QuestionsView = () => {
  const [toggleAddQuestions, setToggleAddQuestions] = useState(false);
  const handleToggleModal = () => {
    setToggleAddQuestions(!toggleAddQuestions);
  };
  return (
    <div className="questions_view">
      <div className="button__container">
        <div
          className="btn modal_obs__btn add_questions_btn this px-1 d-inline-block me-2"
          onClick={handleToggleModal}
        >
          <span className="mr-5 fs-4 d-inline-block txtplus">+ </span>
          <span className="txtadd"> Add New Question</span>
        </div>
      </div>
      <div className="questions__table">
        <QuestionsTable />
        {toggleAddQuestions && (
          <AddQuestionModal closeModal={handleToggleModal} />
        )}
      </div>
    </div>
  );
};

export default QuestionsView;
