import React from "react";
import TitleComponent from "../../TitleComponent";
// import SignUpBtn from "../SignUpBtn";
import Image from "react-bootstrap/Image";
import "./styles.css";
import { useTranslation } from "react-i18next";
import SecondaryBtn from "../SecondaryBtn";

export default function SightingMap() {
  const { t } = useTranslation();
  return (
    <section className="SightingMap">
      <TitleComponent>{t("landingPage.content.sampleMap")}</TitleComponent>
      <img
        className="SightingMapImg"
        src="assets/images/SightingMap.png"
        alt=""
      />
      <div className="SightingMapBtnWrapper">
        <a className="signUpBtn" href={"#howItWorks"}>
          {t("landingPage.navbar.howWorks")}
          <Image
            src="assets/elements/images/icon/blackDownArrow.svg"
            alt="dropDownArrow"
            className="signUpBtnRotate"
          />
        </a>
      </div>
    </section>
  );
}
