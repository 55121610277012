import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, resetPassword } from "../../actions/userActions";
// Css import
import "./login.css";
// form Validation imports
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Loading from "../../components/LoadingError/Loading";
import Message from "../../components/LoadingError/Error";
import { BsArrowLeftShort } from "react-icons/bs";

// Randomly renderin login images
const loginImagesPath = [
  `url(assets/elements/images/turtleCompressed.png)`,
  `url(assets/elements/images/SharkimageCompressed.png)`,
  `url(assets/elements/images/DolphinCompressed.png)`,
];
// mobile images imports
const loginImagesPathMoble = [
  `url(assets/elements/images/Shark-image-small-2.png)`,
  `url(assets/elements/images/Turtle-image-small.png)`,
  `url(assets/elements/images/Dolphin-image-small-3.png)`,
];

let imagePathId = Math.floor(Math.random() * 3);
const mystyle = {
  background: loginImagesPath[imagePathId],
  // backgroundSize: "cover",
  backgroundSize: " 100% 100%",
  backgroundRepeat: "no-repeat",
};
const mystyleMobile = {
  background: loginImagesPathMoble[imagePathId],
  // backgroundSize: "cover",
  backgroundSize: "  cover",

  backgroundRepeat: "no-repeat",
};

const ForgottenPassword = () => {
  // Form inputs validation using react hook forms to handle the form and yup for the validation
  const validationSchema = yup.object().shape({
    email: yup.string().email().required("email adress is required"),
  });

  const {
    register,

    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const userForgotPassword = useSelector((state) => state.userForgotPassword);
  const { userInfo, error, loading } = userForgotPassword;
  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    dispatch(forgotPassword(data.email));
  };

  return (
    <>
      {/* {userInfo && (
        <SuccessAlert
          message={"Please verify your mails and follow the instructions"}
        />
      )} */}
      <div
        className="container-fluid p-0 login-page"
        style={{ overflow: "hidden" }}
      >
        <div className="row header bg-cl-bl d-md-none d-lg-none d-sm-none ">
          <div className="col-xs-12 text-center">
            <img
              src="assets/elements/images/logo/logo.png"
              alt=""
              className="logo"
            />
          </div>
        </div>
        <div
          className="row header-img bg-cl-bl d-md-none d-lg-none d-sm-none "
          style={mystyleMobile}
        ></div>

        <div
          className={`row ${1} ,row-cols-2,row-cols-3, auto,justify-content-md-center vh-100`}
        >
          <div className="col-md-6 col-sm-9 p-5">
            <div className="row d-none d-sm-block">
              <Link to={"/"}>
                <img
                  src="assets/elements/images/logo/logos.png"
                  alt=""
                  className="logos"
                />
              </Link>
            </div>
            <div
              className="row  position-relative"
              style={{ marginTop: "5.5rem" }}
            >
              {!userInfo && (
                <div
                  className="col-md-8 offset-md-2 align-self-center"
                  id="block-reset"
                >
                  {error && (
                    <Message variant="alert-danger">{`Incorrect email`}</Message>
                  )}
                  {/* {loading && <Loading />} */}

                  <div className="mb-3">
                    <div className="mb-3 h3 f-bold">Forgot Password</div>
                    <div className="loadingContainer">
                      {loading && <Loading />}
                    </div>
                    <div className="cl-gr">
                      Enter the email associated with your account and we’ll
                      send instructions to reset your password.
                    </div>
                  </div>
                  <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="mt-4 mb-1 position-relative">
                        <label>Email</label>
                        <br />
                        <input
                          type="text"
                          placeholder="Enter your email "
                          {...register("email")}
                          className="w-100 emailtxt form-control"
                        />
                        <div className="fs-11 error mailerror d-none">
                          Please enter a valid email address
                        </div>
                      </div>
                      <p className="error">{errors.email?.message}</p>
                      <button
                        type="submit"
                        disabled={!isDirty || !isValid}
                        className=" btn-lg btn-block w-100 mt-4 login-btn"
                      >
                        Send instructions
                      </button>
                      <div
                        className="position-relative mt-5"
                        style={{
                          color: "#096DBB",
                        }}
                      >
                        <Link className=" noDeco" to={"/login"}>
                          {/* <img
                          src="assets/elements/images/icon/arrow-left.svg"
                          alt=""
                          className="d-inline-block"
                        />{" "} */}
                          <BsArrowLeftShort
                            className="d-inline-block"
                            style={{
                              fontSize: "32px",
                              paddingBottom: "5px",
                            }}
                          />
                          <span
                            // className="ps-1"
                            style={{
                              fontSize: "20px",
                            }}
                          >
                            Back to login
                          </span>{" "}
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              )}
              {userInfo && (
                <>
                  <div
                    className="col-md-8 offset-md-2  align-self-center"
                    id="block-mail"
                  >
                    <div className="mb-3">
                      <div className="mb-3 h4 f-bold">
                        Password reset request sent
                      </div>
                      <div className="cl-gr">
                        <div>
                          A password reset message was sent to your email
                          address. Please click the link in that message to
                          reset your password.
                        </div>
                        <div className="mt-3">
                          If you do not receive the password reset message
                          within a few minutes, please check your Spam folder.
                        </div>
                        {/* The instructions to reset your password have been sent
                        to your email address */}
                      </div>
                    </div>
                    <div
                      className="position-relative mt-5"
                      style={{
                        color: "#096DBB",
                      }}
                    >
                      <Link className=" noDeco" to={"/login"}>
                        {/* <img
                          src="assets/elements/images/icon/arrow-left.svg"
                          alt=""
                          className="d-inline-block"
                        />{" "} */}
                        <BsArrowLeftShort
                          className="d-inline-block"
                          style={{
                            fontSize: "32px",
                            paddingBottom: "5px",
                          }}
                        />
                        <span
                          // className="ps-1"
                          style={{
                            fontSize: "20px",
                          }}
                        >
                          Back to login
                        </span>{" "}
                      </Link>
                    </div>
                  </div>
                </>
              )}
              <div
                className="position-absolute d-none"
                id="block-try"
                style={{ bottom: "0px" }}
              >
                <div className="text-center mt-3">
                  Did not receive the email? Check your spam filter, <br />
                  or{" "}
                  <a className="lien" href="#">
                    {" "}
                    try another email address
                  </a>
                </div>
              </div>
            </div>
            <div className="h-70"></div>
          </div>
          <div
            className="col-md-6 col-sm-3 d-none d-sm-block login-image"
            //   style={{ background: `url(assets/elements/images/turtle.jpg)` }}
            style={mystyle}
          ></div>
        </div>
      </div>
    </>
  );
};

export default ForgottenPassword;
