import React from "react";
import StudyHeader from "../StudyHeader";
import Image from "react-bootstrap/Image";
import "./CaseStudyStyles.css";
import ghophraneImg from "../../../assets/images/caseStudyImg/ghofrane_labyedh.jpeg";
function ElasmobranchiiCaseStudy() {
  const impactImagesPath = "/assets/caseStudyImages";

  const studyHeaderData = {
    titleImg: `${impactImagesPath}/P1410807.jpg`,
    title: [
      "CONSERVING CRITICALLY ENDANGERED",
      "ELASMOBRANCHII (SHARKS & RAYS)",
      "IN WESTERN AFRICA",
    ],
    pdfLink:
      "Conserving-Critically-Endangered-Sharks-and-Rays-in-West-Africa.pdf",
  };

  return (
    <div>
      <StudyHeader studyData={studyHeaderData} />
      <div className="studyLayout">
        <div className="studyIntroduction">
          <p>
            When National Geographic Explorer Ghofrane Labyedh started working
            at AMMCO (African Marine Mammal Conservation Organization) in
            Cameroon, she realized that there is very little data available
            about the critically endangered Sharks and Rays on the region. In
            fact, very little is known about the bio-diversity or the
            distribution of these species - which species exist in the waters,
            what is their sex, size or maturity composition, etc. Sharks and
            rays, especially the critically endangered Scalloped Hammerhead
            Shark and the Blackchin Guitarfish, are caught accidentally in the
            nets of small scale fisheries as well as industrial fisheries, but
            there are currently no data on the catch rates, no laws to protect
            the species, and no management plans for their conservation.
          </p>
          <p>
            In collaboration with the Manta Trust, and with funding from the
            National Geographic and the Save Our Seas Foundation (SOSF),
            Ghofrane started a program that combines citizen science with fish
            market surveys to raise awareness and to bring about socio-economic
            changes that help for the better future of these species that are in
            the urgent need of protection and conservation.
          </p>
        </div>
        <div className="studyApproach">
          <div className="approachLeft">
            <h3 className="studyHeader">THE APPROACH</h3>
            <p>
              Ghofrane decided to follow a 2-prong approach to collecting data
              about the sharks and rays in the region.
            </p>
            <h4 className="studyBulletTag">
              1) Citizen Science with the Siren App
            </h4>
            <p className="studyBullet">
              The first approach is based on citizen science, where fishermen
              participate in the collection of data using the Siren App.
              Ghofrane worked with the fisher community and the coastal
              communities to raise awareness and get their buy-in on data
              collection for conservation. The Siren Network was formed, which
              now includes 80 fishermen.
            </p>
            <p className="studyBullet">
              The fishermen use the Siren app and send pictures of their entire
              catch to AMMCO, so Ghofrane and others can identify the various
              marine species and collect data about the bio-diversity of the
              region. More than 50% of their data comes via the Siren Network.
            </p>

            <h4 className="studyBulletTag">
              2) Scientific Surveys at Fish Market
            </h4>
            <p className="studyBullet">
              The second approach is to have a team of researchers and
              volunteers go to various fish markets in the region to make
              scientific observations about the sharks and rays that are sold
              there.
            </p>
            <p className="studyBullet">
              The team conducts surveys twice a week at 10 fish market sites
              across the country. They collect scientific data such as the
              species, measurements, sex, and maturity of the elasmobranch.
            </p>
            <p  className="studyBullet">
              They also conduct socio-economic interviews with the fishers to
              understand the location of the catch, understand their perception
              on future management plans, determine their nationalities, their
              age, and study other aspects of the fishermen and fisheries of
              Cameroon.
            </p>
          </div>
          <div className="approachRight">
            <div className="studyQuoteBlock">
              <h5>
                “90% of shark and ray captures are accidental and not
                intentional. By reducing the amount of accidental catch, we can
                work to protect these species.”
              </h5>
              <Image
                className="studyQuotee rounded-circle"
                src={ghophraneImg}
                alt="Ghofrane Labyedh"
              />
              <div>
                <p>Ghofrane Labyedh</p>
                <p>National Geographic Explorer</p>
              </div>
            </div>
          </div>
        </div>
        <div className="studyImpact">
          <div className="impactG1">
            <div className="impactG1Left">
              <h3 className="studyHeader">THE IMPACT AND NEXT STEPS</h3>
              <p>
                Using the Siren app, the fishermen of the Siren Network have
                been able to record 7000 observations on sharks and rays, of
                which 1000 observations were in 2023 alone. Since fishers are
                out in the sea, they are more in contact with the marine
                diversity than the researchers surveying fish markets. With data
                from the Siren Network, Ghofrane has been able to identify 45
                species, whereas only 27-30 species from this list were observed
                at fish markets. The app has enabled them to confirm a much
                larger number of species, which is an achievement.
              </p>
              <p>
                The data shows that a lot of juveniles are being caught, which
                indicates that the fishermen are potentially catching in nursery
                grounds for the sharks and rays - something that was not known
                before. They are now working with the fishermen to implement
                live release programs for the 2 critically endangered sharks and
                rays when they are accidentally caught in the nets. This will be
                the first conservation program of this type in Cameroon.
              </p>
            </div>
            <div className="impactG1Right">
              <Image
                src={`${impactImagesPath}/P1410820.jpg`}
                alt="impact image 1"
              />
            </div>
          </div>
          <div className="impactQuote">
            <h5>
              “Fishers are the solution and not the problem. They are not the
              enemies of the ocean. My vision for a better future for the ocean
              and marine wildlife is to build another type of relationship
              between scientist and fishers. Together we can go far. Do more.”
            </h5>
            <p>Ghofrane Labyedh, National Geographic Explorer</p>
          </div>
          <p>
            The impact of the Siren Network goes beyond just sharks and rays. If
            the fishers see an incident, such as meat of the protected African
            Manatee being sold somewhere, they make a record of that. If there
            is a threat such as a sea turtle stuck in a net, they note that as
            well. Ghofrane's plan is to expand this network, and to use the
            findings to raise more awareness in the coastal and fishing
            communities. She believes that to build a better future for the
            ocean and marine wildlife, there must be a closer relationship
            between scientists and the fishing community.
          </p>
          <div className="impactG2">
            <div className="impactG2Left">
              <Image
                src={`${impactImagesPath}/IMG_2861.jpg`}
                alt="impact image 2"
              />
            </div>
            <div className="impactG2Right">
              <p>
                In partnership with the Manta Trust, AMMCO has been able to
                build the Shark & Ray Gulf Of Guinea network, which consists of
                organizations, people and scientists in the region to collect
                and communicate data and to build a regional strategic plan to
                protect the species. The network hosted their first workshop in
                Cameroon, where organizations such as the WCS (Wildlife
                Conservation Society), the IUCN (International Union for
                Conservation of Nature) shark specialistic group, and many
                others from 12 countries across the region participated. In this
                workshop, they drafted the first draft of the shark and rays
                strategic plan for the Gulf of Guinea.
              </p>
              <p>
                The program was presented in Senegal during Marine Megafauna
                Week by the CMS (Convention on Migratory Species), and the
                program has since been expanded to whole of West Africa.
              </p>
            </div>
          </div>
          <p>
            Armed with the data collected and the support of the program,
            Ghofrane is now working to involve the government so they will take
            responsibility and not just be informed in the management plan.
            Ghofrane also hopes to secure further funding so she can expand the
            program to collect data and conduct surveys more regularly
            throughout the year. With more data, they will be able to study
            further trends such as seasonality of various species, their
            migration patterns, birthing seasons, etc.
          </p>
        </div>
        <div className="aboutSiren">
          <h3 className="studyHeader">ABOUT SIREN</h3>
          <p>
            Siren enables marine researchers and scientists to turn their marine
            wildlife observations into actionable conservation data.
          </p>
          <p>
            The Siren app was created by AMMCO (African Marine Mammal
            Conservation Organization) as part of their turtle conservation
            program. It has since expanded to support projects across multiple
            countries covering a variety of species.
          </p>
          <p>
            With Siren, researchers can easily record observations on marine
            fauna - either through citizen science projects with local
            communities and fishermen, or with dedicated teams out in the field.
            Consolidated data can be downloaded offline for detailed analysis of
            species, population distribution and health and growth trends.
            Findings can be shared with peers, government agencies, policy
            makers and funding organizations to get visibility, raise awareness,
            facilitate policy changes, and to secure funds to further expand
            conservation efforts.
          </p>
          <p>
            Learn more about Siren at &nbsp;&nbsp;
            <a
              href="https://sirenammco.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.sirenammco.org
            </a>
          </p>
        </div>
      </div>
      <div className="studyEndBorder" />
    </div>
  );
}

export default ElasmobranchiiCaseStudy;
