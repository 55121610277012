import React, { useEffect, useState } from "react";
import axios from "axios";

import { formatPhoneNumber } from "react-phone-number-input";
import { PhoneInput } from "react-simple-phone-input";
import "react-simple-phone-input/dist/style.css";
import { ChevronUp } from "react-bootstrap-icons";

import "./accountcontent.css";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import Select from "react-select";

import PasswordModal from "../passwordModal/passwordmodal";
import {
  changePass$,
  setAccountData,
  setChangeAccount,
  setChangePass,
  setModifiedAccount,
  setUser,
  setUserjob,
  successModifieAccount$,
  successModifieProject$,
  user$,
  userJob$,
} from "../../services/rxjs";
import { API_URL as API } from "../../services/API";

// translation test import
import { useTranslation } from "react-i18next";
const AccountContent = ({
  user,
  info,
  country,
  userPhone,
  codePhone,
  telNum,
  sigle,
  jobs,
  saveData,
}) => {
  // translation

  const { t, i18n } = useTranslation();

  const [countryList, setCountryList] = useState([]);

  const [countryChoise, setCountryChoise] = useState([]);
  const [langue, setLangue] = useState([
    { value: 1, label: `${t("dbData.languages.english")}` },
    { value: 2, label: `${t("dbData.languages.french")}` },
    { value: 3, label: `${t("dbData.languages.portu")}` },
    { value: 4, label: `${t("dbData.languages.arabic")}` },
  ]);
  const [langueChoice, setLangueChoice] = useState(langue[0]);

  // Update the language options whenever the language changes
  useEffect(() => {
    setLangue([
      { value: 1, label: `${t("dbData.languages.english")}` },
      { value: 2, label: `${t("dbData.languages.french")}` },
      { value: 3, label: `${t("dbData.languages.portu")}` },
      { value: 4, label: `${t("dbData.languages.arabic")}` },
    ]);
  }, [t, i18n]); // Assuming t is a dependency that changes when the language changes

  useEffect(() => {
    setLangueChoice(langue[0]);
  }, [langue, i18n, t]);

  const [showModal, setShowModal] = useState(false);
  const [phone, setPhone] = useState(userPhone);
  const [codeTel, setCodeTel] = useState(codePhone);
  const [telephone, setTelephone] = useState(telNum);
  const [phoneSigle, setPhoneSigle] = useState(sigle);
  const [userInfo, setUserInfo] = useState(user);
  const [job, setJob] = useState({});
  const [modified, setModified] = useState(false);
  const [modifiedPass, setModifiedPass] = useState(false);

  const [show, setShow] = useState("false");

  const [validate, setValidate] = useState(false);
  const [firstname, setFirstName] = useState(
    userInfo.data.firstname !== undefined ? userInfo.data.firstname : ""
  );
  const [lastname, setLastName] = useState(
    userInfo.data.lastname !== undefined ? userInfo.data.lastname : ""
  );
  const [location, setLocation] = useState(
    userInfo.data.city !== undefined ? userInfo.data.city : ""
  );
  const [organization, setOrganization] = useState(
    userInfo.data.organization !== undefined ? userInfo.data.organization : ""
  );
  const [inputEnterTest, setInputEnterTest] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [errorfname, setErrorfname] = useState(false);
  const [errorLname, setErrorLname] = useState(false);
  const [errorLocation, setErrorLocation] = useState(false);
  const [errorOrganization, setErrorOrganization] = useState(false);

  const onChangeFName = (e) => {
    let isempty = false;
    let copy = [...inputEnterTest];
    let vefifAuthersField = true;
    setFirstName(e.target.value);
    // saveData.firstname = e.target.value;
    setAccountData({ name: "first_name", value: e.target.value });
    if (e.target.value === "") {
      setErrorfname(true);
      copy[0] = false;
      isempty = true;
    } else {
      setErrorfname(false);
    }

    if (e.target.value !== userInfo.data.firstname && !isempty) copy[0] = true;

    if (e.target.value === userInfo.data.firstname) {
      vefifAuthersField = verifTouchOne(8);
      copy[8] = false;
    } else copy[8] = true;

    setInputEnterTest(copy);

    if (e.target.value === userInfo.data.firstname && !vefifAuthersField) {
      setValidate(false);
      setChangeAccount(false);
    } else checkValidate(0, copy[0]);
  };

  const onChangeLName = (e) => {
    let isempty = false;
    let copy = [...inputEnterTest];
    let vefifAuthersField = true;

    setLastName(e.target.value);
    setAccountData({ name: "last_name", value: e.target.value });
    if (e.target.value === "") {
      setErrorLname(true); //show error below the field
      copy[1] = false;
      isempty = true;
    } else {
      setErrorLname(false); //hide error below the field
    }

    //is different and is not empty
    if (e.target.value !== userInfo.data.lastname && !isempty) copy[1] = true;

    //touch or not
    if (e.target.value === userInfo.data.lastname) {
      vefifAuthersField = verifTouchOne(9);
      copy[9] = false;
    } else copy[9] = true;

    setInputEnterTest(copy);

    if (e.target.value === userInfo.data.lastname && !vefifAuthersField) {
      setValidate(false);
      setChangeAccount(false);
    } else checkValidate(1, copy[1]);
  };

  const onChangeOrganization = (e) => {
    let isempty = false;
    let copy = [...inputEnterTest];
    let vefifAuthersField = true;

    setOrganization(e.target.value);
    setAccountData({ name: "organization", value: e.target.value });
    if (e.target.value === "") {
      setErrorOrganization(true);
      copy[7] = false;
      isempty = true;
    } else {
      setErrorOrganization(false);
    }

    if (e.target.value !== userInfo.data.organization && !isempty)
      copy[7] = true;
    if (e.target.value === userInfo.data.organization) {
      vefifAuthersField = verifTouchOne(15);
      copy[15] = false;
    } else copy[15] = true;

    setInputEnterTest(copy);

    if (e.target.value === userInfo.data.organization && !vefifAuthersField) {
      setValidate(false);
      setChangeAccount(false);
    } else checkValidate(7, copy[7]);
  };

  const onChangeLocation = (e) => {
    let isempty = false;
    let copy = [...inputEnterTest];
    let vefifAuthersField = true;

    setLocation(e.target.value);
    setAccountData({ name: "city", value: e.target.value });
    if (e.target.value === "") {
      setErrorLocation(true);
      copy[2] = false;
      isempty = true;
    } else {
      setErrorLocation(false);
    }

    if (e.target.value !== userInfo.data.city && !isempty) copy[2] = true;
    if (e.target.value === userInfo.data.city) {
      vefifAuthersField = verifTouchOne(10);
      copy[10] = false;
    } else copy[10] = true;

    setInputEnterTest(copy);

    if (e.target.value === userInfo.data.city && !vefifAuthersField) {
      setValidate(false);
      setChangeAccount(false);
    } else checkValidate(2, copy[2]);
  };

  const checkValidate = (position, val) => {
    let check = false;
    switch (position) {
      case 0:
        check =
          val &&
          inputEnterTest[1] &&
          inputEnterTest[2] &&
          inputEnterTest[3] &&
          inputEnterTest[4] &&
          inputEnterTest[5] &&
          inputEnterTest[6] &&
          inputEnterTest[7];
        break;
      case 1:
        check =
          val &&
          inputEnterTest[0] &&
          inputEnterTest[2] &&
          inputEnterTest[3] &&
          inputEnterTest[4] &&
          inputEnterTest[5] &&
          inputEnterTest[6] &&
          inputEnterTest[7];
        break;
      case 2:
        check =
          val &&
          inputEnterTest[0] &&
          inputEnterTest[1] &&
          inputEnterTest[3] &&
          inputEnterTest[4] &&
          inputEnterTest[5] &&
          inputEnterTest[6] &&
          inputEnterTest[7];
        break;
      case 3:
        check =
          val &&
          inputEnterTest[0] &&
          inputEnterTest[1] &&
          inputEnterTest[2] &&
          inputEnterTest[4] &&
          inputEnterTest[5] &&
          inputEnterTest[6] &&
          inputEnterTest[7];
        break;
      case 5:
        check =
          val &&
          inputEnterTest[1] &&
          inputEnterTest[2] &&
          inputEnterTest[3] &&
          inputEnterTest[4] &&
          inputEnterTest[0] &&
          inputEnterTest[6] &&
          inputEnterTest[7];
        break;
      case 6:
        check =
          val &&
          inputEnterTest[1] &&
          inputEnterTest[2] &&
          inputEnterTest[3] &&
          inputEnterTest[4] &&
          inputEnterTest[5] &&
          inputEnterTest[0] &&
          inputEnterTest[7];
        break;
      case 7:
        check =
          val &&
          inputEnterTest[1] &&
          inputEnterTest[2] &&
          inputEnterTest[3] &&
          inputEnterTest[4] &&
          inputEnterTest[5] &&
          inputEnterTest[0] &&
          inputEnterTest[6];
        break;
      default:
        check = false;
    }

    setValidate(check);
    setChangeAccount(check);
  };

  const handleHideModal = (change) => {
    setShowModal(false);
    setTimeout(() => {
      setChangePass(false);
    }, 4000);
  };

  const setModif = (bool) => {
    setModified(bool);
  };

  const displayMsg = () => {
    const timer = setTimeout(() => {
      setModif(false);
      // if (creation === true) projectRefresh();
    }, 4000);
  };

  const filloption = (data) => {
    let content = [];
    let mycountry = {};

    if (data.length > 0)
      for (let i = 0; i < data.length; i++) {
        let newops = { value: data[i].id, label: data[i].name };
        content.push(newops);
        if (data[i].name === userInfo.data.country) {
          mycountry = { value: data[i].id, label: data[i].name };
        }
      }

    // setOps(content);
    setCountryList(content);

    setCountryChoise({ value: 3, label: "Antarctica" });
  };

  useEffect(() => {
    // InitialiseAccountData();
    successModifieAccount$.subscribe((result) => setModif(result));
    successModifieProject$.subscribe((result) => setModif(result));
    user$.subscribe((user) => setUserInfo(user));
    userJob$.subscribe((ujob) => setJob(ujob));
    changePass$.subscribe((cp) => setModifiedPass(cp));

    const getCountries = async () => {
      const response = await axios
        .get(API + "api/noconnect/country/list")
        .then((data) => {
          let result = data.data.data;
          filloption(result);
        });
    };
    getCountries();
  }, []);
  ///

  // Form inputs validation using react hook forms to handle the form and yup for the validation
  const validationSchema = yup.object().shape({
    fname: yup.string().required(`${t("required")}`),
    lname: yup.string().required(`${t("required")}`),
    location: yup.string().required(`${t("required")}`),
    organization: yup.string().required(`${t("required")}`),
    // uname: yup.string().required("User name required"),
  });

  const {
    register,

    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = () => {
    let pays = {};

    if (countryChoise.label === undefined) {
      pays = country.filter((cn) => cn.label === userInfo.data.country);
    } else {
      pays = country.filter((cn) => cn.label === countryChoise.label);
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    axios
      .post(
        API + "api/user/update",
        {
          city: location,
          country_id: pays[0].value,
          first_name: firstname,
          fonction_id: job.value,
          last_name: lastname,
          organization: organization,
          phone: telephone,
          code: codeTel,
          sigle: phoneSigle,
        },
        config
      )
      .then(function (response) {
        // setModif(true);
        setModifiedAccount(true);
        setUserjob(job);
        setinfo(response.data.data, organization, pays[0]);
        setChangeAccount(false);
        setValidate(false);
        displayMsg();
        resetPointer();
        // reset({}, { keepValues: true });
        // localStorage.setItem("userInfo", JSON.stringify(response.data.data));
        // navigate(`/detailproject`);
        //if email is different log out  here
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const resetPointer = () => {
    setInputEnterTest([
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ]);
  };

  const setinfo = (d, o, p) => {
    let data = {
      countryid: p.value,
      city: d.city,
      country: p.label,
      email: d.email,
      firstname: d.firstname,
      fonction: d.job,
      id: d.id,
      lastname: d.lastname,
      organization: o,
      phone: telephone,
      token: userInfo.data.token,
      codeTel: codeTel,
      sigle: phoneSigle,
    };

    let info = {
      data: data,
    };

    setUser(info);
  };

  const showModalPassword = () => {
    setShowModal(true);
  };

  const setPhoneNumber = (data) => {
    let copy = [...inputEnterTest];
    let vefifAuthersField = false;

    let oldLong = userInfo.data.phone;

    let code = data.dialCode;
    let actualLong = formatPhoneNumber(data.value).replace(/\s/g, "");

    setCodeTel(code.slice(1));
    setTelephone(formatPhoneNumber(data.value));
    // setPhone("+" + 233);
    setPhoneSigle(data.code);

    setAccountData({ name: "codeTel", value: code.slice(1) });
    setAccountData({ name: "phone", value: actualLong });
    setAccountData({ name: "sigle", value: data.code });

    if (actualLong !== oldLong) {
      copy[5] = true;
      copy[13] = true;
    } else {
      vefifAuthersField = verifTouchOne(13);
      copy[5] = true;
      copy[13] = false;
    }
    if (actualLong === "") copy[5] = false;

    setInputEnterTest(copy);

    if (actualLong === oldLong && !vefifAuthersField) {
      setValidate(false);
      setChangeAccount(false);
    } else checkValidate(5, copy[5]);
  };

  const verifTouchOne = (index) => {
    let result = false;

    for (let i = 8; i < 16; i++) {
      if (i !== index && !result) result = inputEnterTest[i];
    }

    return result;
  };

  const setPays = (choice) => {
    let copy = [...inputEnterTest];
    let vefifAuthersField = true;
    if (choice.label !== userInfo.data.country) {
      copy[3] = true;
      copy[11] = true;
    } else {
      copy[11] = false;
    }

    setCountryChoise(choice);
    // setChangeContry(true);
    setAccountData({ name: "country_id", value: choice.value });
    setAccountData({ name: "country", value: choice.label });

    vefifAuthersField = verifTouchOne(11);

    setInputEnterTest(copy);

    if (choice.label === userInfo.data.country && !vefifAuthersField) {
      setValidate(false);
      setChangeAccount(false);
    } else checkValidate(3, copy[3]);
  };

  const selectJob = (choice) => {
    let copy = [...inputEnterTest];
    let vefifAuthersField = true;
    if (choice.label !== userInfo.data.fonction) {
      copy[6] = true;
      copy[14] = true;
    } else {
      copy[14] = false;
    }
    setJob(choice);
    setAccountData({ name: "fonction_id", value: choice.value });
    setAccountData({ name: "fonction", value: choice.label });

    vefifAuthersField = verifTouchOne(14);

    setInputEnterTest(copy);

    if (choice.label === userInfo.data.fonction && !vefifAuthersField) {
      setValidate(false);
      setChangeAccount(false);
    } else checkValidate(6, copy[6]);
  };

  return (
    <div className="admin-content flex-fill">
      <div className="container position-relative">
        {modified && (
          <div className="row pt-3 pb-3 succedmsg">
            <div className="col-md-12 offset-md-1 position-relative ">
              <svg
                width="32"
                height="22"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 7.168 24.832 0 16 0ZM16 28.8C8.944 28.8 3.2 23.056 3.2 16C3.2 8.944 8.944 3.2 16 3.2C23.056 3.2 28.8 8.944 28.8 16C28.8 23.056 23.056 28.8 16 28.8ZM23.344 8.928L12.8 19.472L8.656 15.344L6.4 17.6L12.8 24L25.6 11.2L23.344 8.928Z"
                  fill="#138101"
                />
              </svg>

              <span className="ml-5">
                {t("admin.accountContent.changeSuccess")}
              </span>
            </div>
          </div>
        )}
        {modifiedPass && (
          <div className="row pt-3 pb-3 succedmsg">
            <div className="col-md-12 offset-md-1 position-relative ">
              <svg
                width="32"
                height="22"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 7.168 24.832 0 16 0ZM16 28.8C8.944 28.8 3.2 23.056 3.2 16C3.2 8.944 8.944 3.2 16 3.2C23.056 3.2 28.8 8.944 28.8 16C28.8 23.056 23.056 28.8 16 28.8ZM23.344 8.928L12.8 19.472L8.656 15.344L6.4 17.6L12.8 24L25.6 11.2L23.344 8.928Z"
                  fill="#138101"
                />
              </svg>

              <span className="ml-5">
                {" "}
                {t("admin.accountContent.passChangeSuccess")}
              </span>
            </div>
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)} className="mx-2 ">
          <div className="row">
            <div className="col-md-4 offset-md-1">
              <span className="title-text d-block mb-3">
                {t("admin.profile.accountName")}
              </span>

              <div className="mt-1 mb-2  position-relative">
                <label>{t("admin.profile.firstName")}</label>
                <br />
                <input
                  type="text"
                  name="fname"
                  // value={
                  //   userInfo.data.firstname !== undefined
                  //     ? userInfo.data.firstname
                  //     : ""
                  // }
                  value={firstname}
                  onChange={onChangeFName}
                  placeholder={`${t("namePlaceholder")}`}
                  className={`w-100  form-control ${
                    errorfname ? "is-invalid" : ""
                  } ${inputEnterTest[8] ? "changedInput" : ""}`}
                />
                {/* <p className="error">{errors.fname?.message}</p> */}
                {errorfname && <p className="error">{t("required")}</p>}
              </div>
              <div className="mt-3 mb-2  position-relative">
                <label>{t("admin.profile.lastName")}</label>
                <br />
                <input
                  type="text"
                  name="lname"
                  value={lastname}
                  onChange={onChangeLName}
                  placeholder={`${t("lNamePlaceholder")}`}
                  className={`w-100  form-control ${
                    errorLname ? "is-invalid" : ""
                  } ${inputEnterTest[9] ? "changedInput" : ""}`}
                />
                {/* <p className="error">{errors.lname?.message}</p> */}
                {errorLname && <p className="error">{t("required")}</p>}
              </div>
            </div>
            <div className="col-md-4 offset-md-1">
              <span className="title-text d-block mb-3">
                {t("admin.profile.location")}
              </span>
              <div className="mt-1 mb-2  position-relative">
                <label>{t("admin.profile.locationDetails")}</label>
                <br />
                <input
                  type="text"
                  name="location"
                  value={location}
                  onChange={onChangeLocation}
                  placeholder="Enter the location"
                  className={`w-100  form-control ${
                    errorfname ? "is-invalid" : ""
                  } ${inputEnterTest[10] ? "changedInput" : ""}`}
                />
                {/* <p className="error">{errors.location?.message}</p> */}
                {errorLocation && <p className="error">{t("required")}</p>}
              </div>
              <div className="mt-3 mb-2  position-relative">
                <label>{t("admin.profile.country")}</label>
                <br />
                {/* <input
                    type="text"
                    name="country"
                    placeholder="Enter the country"
                    className={`w-100  form-control`}
                /> */}

                <Select
                  options={countryList}
                  // options={country}
                  placeholder="Select the country"
                  onChange={(choice) => setPays(choice)}
                  // defaultValue={countryList.filter(
                  //   // (cn) => cn.label === country
                  //   (cn) => cn.label === userInfo.data.country
                  // )}

                  defaultValue={{
                    value: userInfo.data.countryid,
                    label: userInfo.data.country,
                  }}
                  className={`${inputEnterTest[11] ? "changedInput" : ""}`}
                />

                <p className="error">{errors.country?.message}</p>
              </div>
              <div className="mt-3 mb-2  position-relative">
                <label>{t("admin.profile.language")}</label>
                <br />

                <Select
                  options={langue}
                  placeholder="Select the language"
                  onChange={(choice) => setLangueChoice(choice)}
                  value={langueChoice}
                />
              </div>
            </div>
          </div>
          <div className="row rowtop mt-4 ">
            <div className="col-md-4 offset-md-1">
              <span className="title-text d-block mb-3">
                {t("admin.profile.contactInfo")}
              </span>
              <div className="mt-1 mb-2  position-relative">
                <label>{t("admin.profile.phone")}</label>
                <br />

                <PhoneInput
                  country={phoneSigle}
                  dialCodeInputField
                  // {...register("phone")}
                  iconComponent={<ChevronUp color="black" size={14} />}
                  search={true}
                  placeholder={`${t(
                    "admin.accountContent.addPhonePlaceHolder"
                  )}`}
                  value={phone}
                  onChange={(data) => setPhoneNumber(data)}
                  containerClass={`${inputEnterTest[13] ? "changedInput" : ""}`}
                  inputClass={`${inputEnterTest[13] ? "textSizeColr" : ""}`}
                />
              </div>

              <div className="mt-3 mb-1  position-relative">
                <label>Email</label>
                <br />
                <input
                  type="text"
                  disabled={true}
                  name="email"
                  placeholder={`${t("mailPlaceholder")}`}
                  defaultValue={
                    userInfo.data.email !== undefined ? userInfo.data.email : ""
                  }
                  className={`w-100  form-control  ${
                    errors.email ? "is-invalid" : ""
                  }`}
                  {...register("email")}
                />

                <p className="error">{errors.email?.message}</p>
              </div>
            </div>
            <div className="col-md-4 offset-md-1">
              <span className="title-text d-block mb-3">
                {t("admin.profile.professional")}
              </span>
              <div className="mt-1 mb-2  position-relative">
                <label> {t("admin.profile.jobTitle")}</label>
                <br />

                <Select
                  options={jobs}
                  // {...country}
                  onChange={(choice) => selectJob(choice)}
                  placeholder={`${t("jobPlaceholder")}`}
                  value={job}
                  className={`${inputEnterTest[14] ? "changedInput" : ""}`}
                />
                {/* <p className="error">{errors.job?.message}</p> */}
              </div>

              <div className="mt-3 mb-2  position-relative">
                <label> {t("admin.profile.organisation")}</label>
                <br />
                <input
                  type="text"
                  name="organization"
                  value={organization}
                  onChange={onChangeOrganization}
                  placeholder={`${t("orgaPlaceholder")}`}
                  className={`w-100  form-control ${
                    errorfname ? "is-invalid" : ""
                  } ${inputEnterTest[15] ? "changedInput" : ""}`}
                />
                {/* <p className="error">{errors.organization?.message}</p> */}
                {errorOrganization && <p className="error">{t("required")}</p>}
              </div>
              <div className="text-end">
                <button
                  // disabled={!isDirty || !isValid}
                  disabled={!validate}
                  type="submit"
                  className=" btn-block mt-4 login-btn admin-btn-dark"
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  {t("saveBtn")}
                </button>
              </div>
            </div>
          </div>
        </form>

        <div className="mx-2 ">
          <div className="row mb-10">
            <div className="col-md-4 offset-md-1">
              <div className=" position-relative" style={{ height: "80px" }}>
                <span className="title-text d-block ">
                  {t("admin.profile.password")}
                </span>

                <input
                  type={show ? "password" : "text"}
                  name=""
                  value="password"
                  typeof="password"
                  disabled={true}
                  placeholder={`${t("passPlaceholder")}`}
                  className={`w-100 rounded password form-control `}
                />
              </div>
            </div>
            <div className="col-md-4 offset-md-1">
              <div className="text-end">
                <button
                  className=" btn-block mt-4 login-btn admin-btn-dark"
                  onClick={() => {
                    showModalPassword();
                  }}
                >
                  {t("admin.profile.changeBtn")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PasswordModal showMod={showModal} setShowMod={handleHideModal} />
    </div>
  );
};

export default AccountContent;
