import React from "react";

const Obs = ({ data }) => {
  return (
    <div>
      <div
        className=""
        style={{
          textAlign: "center",
          display: "flex",
          width: "50px",
          fontWeight: "bold",
          cursor: "pointer",
          justifyContent: "right",
        }}
      >
        {data.observations}
      </div>
    </div>
  );
};

export default Obs;
