import React from "react";
import Image from "react-bootstrap/Image";

import "./StudyHeader.css";

function StudyHeader({ studyData }) {
  return (
    <div className="studyHeader">
      <div className="sirenLogoContainer">
        <Image
          className="sirenLogo"
          src="/assets/images/logo/logo.png"
          alt="ammco logo"
        />
      </div>
      <div className="studyHeaderImgContainer">
        <Image
          className="studyHeaderImg img-fluid"
          src={studyData.titleImg}
          alt="title image"
        />
      </div>
      <div className="studyHeaderFloating">
        <div className="studyHeaderTitle">
          {studyData.title.map((title, i) => (
            <h1 key={i}>{title}</h1>
          ))}
        </div>
        <a
          href={process.env.PUBLIC_URL + `/pdf/${studyData.pdfLink}`}
          className="studyDownloadBtn"
          target="_blank"
          download
        >
          Download PDF
        </a>
      </div>
    </div>
  );
}

export default StudyHeader;
