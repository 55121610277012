import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import position from "../../../assets/images/position.svg";

import "./style.css";
import {
  useFetchFamilyList,
  useFetchGroupList,
  useFetchObservaionTypeList,
  useFetchSeSpeciesList,
  useFetchSiteSgmntListByProject,
} from "../../../queries/useFetchOptions";

import uploadImagePlaceHolder from "../../../assets/images/uploadImagePlaceholder.svg";
import backArrow from "../../../assets/images/backArrow.png";
import plus from "../../../assets/images/plus.png";
import "../style.css";
// Upload images import
import ImageUploading from "react-images-uploading";
import { AiFillDelete, AiOutlineCalendar } from "react-icons/ai";
import { BiErrorCircle } from "react-icons/bi";
import ConfirmationModal from "../ConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import {
  editeObservaion,
  refetchObs,
  singleObservation,
  updateObservationLocally,
} from "../../../actions/projectActions";
import Loading from "../../LoadingError/Loading";

import { API_URL as API } from "../../../services/API";
import axios from "axios";
import { observationStyle } from "../../../utils/TypeObsStyle";
import DatePicker from "react-date-picker";
import { formatDate5 } from "../../../utils/DateFormater";

import { Switch, TimePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { BiTimeFive } from "react-icons/bi";
import ModalMarker from "../../map/leafletMap/ModalMarker";
import {
  useFetchMiniCardObservaionList,
  useFetchObservaionList,
  useFetchUnvalidatedObservaionList,
} from "../../../queries/useMapQueriesHook";
import { useTranslation } from "react-i18next";
import { useTranslateObsType } from "../../../utils/useTranslateObservationType";

dayjs.extend(customParseFormat);
const format = "HH:mm";

function SetMap({ location, lat, long, typeObservation }) {
  const map = useMap();
  if (true) {
    map.flyTo(location, 8);
  }

  return location ? (
    <ModalMarker
      observation={null}
      lat={lat}
      long={long}
      typeObservation={typeObservation}
    />
  ) : null;
}
const EditObservation = ({
  editData,

  closeIsEditModal,
}) => {
  const { t } = useTranslation();

  // siteId
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const { queryRefetchObsList } = useFetchUnvalidatedObservaionList({
    id: localProjectId,
  });
  const { queryRefetchValidObsList } = useFetchObservaionList({
    id: localProjectId,
  });
  const { miniCardQueryRefetchObsList } = useFetchMiniCardObservaionList({
    id: localProjectId,
  });
  const successMessage = useSelector((state) => state.setSuccessMessage);
  const { updateObsSuccessNotif } = successMessage;
  const [editModalState, setEditModalState] = useState(true);

  const [site, setSite] = useState(editData && editData.siteId);
  const [segment, setSegment] = useState(editData && editData.segmentId);
  const [observation, setObservation] = useState(
    editData && editData.TypeObservationId
  );
  const currentObsType = useTranslateObsType(editData.TypeObservation);

  const [displayObserVationType, setDisplayObserVationType] =
    useState(currentObsType);
  const [displayGroupe, setDisplayGroupe] = useState(
    editData && editData.group
  );
  const [displaySpecies, setDisplaySpecies] = useState(
    editData && editData.specie
  );
  const [displayFamily, setDisplayFamily] = useState(
    editData && editData.family
  );

  // display observation type
  const handleSetObservationType = (e) => {
    if (observationList) {
      for (let x of observationList) {
        if (Number(x.id) === Number(e)) {
          setDisplayObserVationType(x.name);
        }
      }
    }
  };

  const [obsId, setObsId] = useState(editData && editData.id);

  const [classData, setClassData] = useState(editData && editData.groupId);

  const [family, setFamily] = useState(editData && editData.familyId);
  const [species, setSpecies] = useState(editData && editData.specieId);
  const [groupChangeTracker, setGroupChangeTracker] = useState(false);
  //****** */ End of data fetching and setting***********

  const {
    speciesList,
    speciesLoading,
    speciesIsRefetching,
    refetchSpeciesList,
  } = useFetchSeSpeciesList(family);

  const {
    siteSegmentList,

    refetchSiteSegment,
    siteSegmentLoading,
    siteSegmentIsRefetching,
  } = useFetchSiteSgmntListByProject(localProjectId);
  const [customSegmentList, setCustomSegmentList] = useState(null);

  const handleSetSegmentList = (seletedSiteId) => {
    if (siteSegmentList) {
      siteSegmentList.data.map((site) => {
        if (site.siteId === Number(seletedSiteId)) {
          setCustomSegmentList(site.segment);
        }
        return site.segment;
      });
    }
  };

  useEffect(() => {
    if (site) {
      handleSetSegmentList(site);
    }
  }, [site, siteSegmentList]);

  useEffect(() => {
    if (localProjectId) {
      refetchSiteSegment();
    }
  }, [localProjectId, refetchSiteSegment]);

  const { observationList } = useFetchObservaionTypeList();

  const { familyList, familyLoading, familyIsRefetching, familyListRefetch } =
    useFetchFamilyList(classData);
  const { groupeList } = useFetchGroupList();

  const [upDatedGroupeList, setUpDatedGroupeList] = useState([]);
  const [upDatedFamilyList, setUpDatedFamilyList] = useState([]);
  const [upDatedSpeciesList, setUpDatedSpeciesList] = useState([]);
  console.log(upDatedSpeciesList, "upDatedSpeciesList");
  console.log(family, "family");

  const handleSetGroupeName = (target) => {
    if (groupeList) {
      groupeList.data.map((s) => {
        if (Number(target) === s.id) {
          setDisplayGroupe(s.name);
        }
        return s.name;
      });
    }
  };

  const handleSetFamilyName = (target) => {
    if (familyList) {
      familyList.data.map((s) => {
        if (Number(target) === s.id) {
          setDisplayFamily(s.name);
        }
        return s.name;
      });
    }
  };

  const handleSetSpeciesName = (target) => {
    if (speciesList) {
      speciesList.data.map((s) => {
        if (Number(target) === s.id) {
          setDisplaySpecies(s.name);
        }
        return s.name;
      });
    }
  };
  useEffect(() => {
    if (groupChangeTracker) {
      if (classData) {
        familyListRefetch();
      }
      if (familyList && familyList.data && familyList.data.length !== 0) {
        setFamily(familyList.data[0].id);
        setDisplayFamily(familyList.data[0].name);
      }
    }
  }, [
    classData,
    familyList,
    observation,
    familyListRefetch,
    groupChangeTracker,
  ]);

  useEffect(() => {
    if (groupChangeTracker) {
      if (family) {
        refetchSpeciesList();
      }
      if (speciesList && speciesList.data && speciesList.data.length !== 0) {
        setSpecies(speciesList.data[0].id);
        setDisplaySpecies(speciesList.data[0].name);
      }
    }
  }, [
    classData,
    family,
    observation,
    displayFamily,
    speciesList,
    groupChangeTracker,
    refetchSpeciesList,
  ]);

  useEffect(() => {
    refetchSpeciesList();
  }, [family, groupeList, familyList, refetchSpeciesList]);

  useEffect(() => {
    if (!groupeList || !familyList || !speciesList) return;

    if ([1, 2, 3].includes(Number(observation))) {
      const filteredGroupe = groupeList.data.filter(
        (item) => Number(item.id) === 2
      );
      if (Number(observation) !== Number(editData.TypeObservationId)) {
        setClassData(filteredGroupe[0].id);
        setDisplayGroupe(filteredGroupe[0].name);
      }
      setUpDatedGroupeList(filteredGroupe.length ? filteredGroupe : []);
    } else {
      setUpDatedGroupeList(groupeList.data);
    }

    if (Number(classData) === 2 && [1, 2, 3].includes(Number(observation))) {
      const newArray = familyList.data.filter((item) =>
        ["4"].includes(item.id.toString())
      );
      if (Number(observation) !== Number(editData.TypeObservationId)) {
        setFamily(newArray[0]?.id);
        setDisplayFamily(newArray[0]?.name);
      }
      setUpDatedFamilyList(newArray);
    } else {
      setUpDatedFamilyList(familyList.data);
    }

    setUpDatedSpeciesList(speciesList.data);
  }, [
    groupeList,
    groupChangeTracker,
    editData,
    displayFamily,
    observation,
    classData,
    familyList,
    family,
    speciesList,
  ]);

  // Upload images function

  // const [images, setImages] = useState([]);
  const maxNumber = 4;

  const [prevImages, setPrevImages] = useState([]);

  useEffect(() => {
    if (editData) {
      setObsId(editData.id);
      const values = Object.values(editData.images);
      const filteredImages = values.filter(
        (value) => value !== "NULL" && value !== ""
      );
      setPrevImages(filteredImages);
    }
  }, [editData]);

  const uploadImages = (imageList, addUpdateIndex) => {
    setChangeTracker(true);
    // Adding images data to array
    const extractedUrls = imageList.map((image) => image.data_url);
    extractedUrls.forEach((url) => {
      if (!prevImages.includes(url)) {
        setPrevImages((prevDataUrls) => [...prevDataUrls, url]);
        // setImages
      }
    });

    // setImages(imageList);
    // setStaticImageList(imageList);
  };

  // delete single image

  const [deleteIndex, setDeleteIndex] = useState();
  const [deleteLink, setDeleteLink] = useState();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [deleteImageLoading, setDeleteImageLoading] = useState(false);
  const [imageWasDeleted, setImgWasDeleted] = useState(false);

  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [trackDeleteImage, setTrackDeleteImage] = useState(false);

  const handleDeleteImage = async () => {
    setShowConfirmModal(false);
    setDeleteImageLoading(true);
    const deleteImg = {
      id: editData.id,
      link: deleteLink,
    };

    try {
      const token = userInfo.data.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.post(
        API + `api/observationImage/delete`,
        {
          id: deleteImg.id,
          lien: deleteImg.link,
        },
        config
      );
      console.log(data);

      deleteNewlyUploadedImage(deleteIndex);
      queryRefetchObsList();
      queryRefetchValidObsList();
      miniCardQueryRefetchObsList();
      setDeleteImageLoading(false);
      setImgWasDeleted(true);
      // setDeleteImageSuccess(!deleteImageSuccess);
      setDeleteSuccess(!deleteSuccess);
      setTrackDeleteImage(true);
    } catch (err) {
      console.log(err);
      setDeleteImageLoading(false);
    }
  };

  const deleteNewlyUploadedImage = (index) => {
    setPrevImages((prevImages) => {
      return prevImages.filter((_, i) => i !== index);
    });
  };

  // end of function Upload images function

  // Delete Image modal state and function
  // delete
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleHideConfirmationModal = () => {
    setShowConfirmModal(false);
  };
  const handleCloseConfirmOnly = () => {
    setShowConfirmModal(false);
  };

  // set coordinates

  const [newLocalLong, setNewLocalLong] = useState(editData && editData.coordY);
  const [newLocalLat, setNewLocalLat] = useState(editData && editData.coordX);

  const cordRef = useRef();
  const [coordsPosition, setCoordsPosition] = useState();
  const [coordsWidth, setCoordsWidth] = useState();
  useEffect(() => {
    if (cordRef && cordRef.current) {
      setCoordsPosition(cordRef.current.offsetHeight);
      setCoordsWidth(cordRef.current.offsetWidth);
    }
  }, []);
  const [showCoordsBox, setShowCoordsBox] = useState(false);
  const [newLat, setNewLat] = useState();
  const [newLong, setNewLong] = useState();

  const [isCoordsSet, setIsCoordsSet] = useState(true);
  const [coordsErrorMessage, setCoordsErrorMessage] = useState("");
  useEffect(() => {
    if (newLat && newLong) {
      setIsCoordsSet(false);
    }
  }, [newLong, newLat]);

  const [isInvalidCoords, setIsInvalidCoords] = useState(false);
  function LongRangeCheck(number) {
    if (Number(number) >= -180 && Number(number) <= 180) {
      // Number is within the range

      return true;
    } else {
      // Number is outside the range

      return false;
    }
  }
  function LatRangeCheck(number) {
    if (Number(number) >= -90 && Number(number) <= 90) {
      // Number is within the range
      // , 29.211659283924526

      return true;
    } else {
      // Number is outside the range

      return false;
    }
  }

  const handleSubmitCoords = () => {
    if (LongRangeCheck(newLong) && LatRangeCheck(newLat)) {
      setNewLocalLat(newLat);
      setNewLocalLong(newLong);
      // LatRangeCheck(newLat);

      setShowCoordsBox(!showCoordsBox);
      setIsCoordsSet(true);

      // hide error message if one

      // // setChangeTracker
      setChangeTracker(true);
      setIsInvalidCoords(false);
    }

    if (!LatRangeCheck(newLat) && !LongRangeCheck(newLong)) {
      setCoordsErrorMessage(`${t("invalidLongLat")}`);

      setIsInvalidCoords(true);
    } else if (!LatRangeCheck(newLat)) {
      setCoordsErrorMessage(`${t("invalidLat")}`);
      setIsInvalidCoords(true);
    } else if (!LongRangeCheck(newLong)) {
      setCoordsErrorMessage(`${t("invalidLong")}`);
      setIsInvalidCoords(true);
    }
  };

  const [newDate, setNewDate] = useState(editData && editData.date.date);

  let setDateObjet = new Date(newDate);

  let convertedDate = formatDate5(setDateObjet);

  const [currentTime, setCurrentTime] = useState(
    editData && editData.date.date.slice(11, 16)
  );
  const [seconds, setSeconds] = useState("");
  const onChangeTime = (time, timeString) => {
    setCurrentTime(timeString);

    setChangeTracker(true);
  };
  function getRandomNumber() {
    let seconds = Math.floor(Math.random() * 60)
      .toString()
      .padStart(2, "0");
    setSeconds(seconds);
  }
  useEffect(() => {
    getRandomNumber();
  }, []);

  const dispatch = useDispatch();
  // const [setsetLocalTimeForUpdat, setsetsetLocalTimeForUpdate] = useState();

  useEffect(() => {
    const handleGetTimeForLocalUpdte = () => {};
    handleGetTimeForLocalUpdte();
  }, [newDate]);
  const [displaySite, setDisplaySite] = useState(editData && editData.site);
  const [displaySegment, setDisplaySegment] = useState(
    editData && editData.segment
  );

  const handleSetSiteName = (target) => {
    if (siteSegmentList) {
      siteSegmentList.data.map((s) => {
        if (Number(target) === s.siteId) {
          setDisplaySite(s.site);
        }
        return s.site;
      });
    }
  };

  const handleSetSegmentName = (target) => {
    if (customSegmentList) {
      customSegmentList.map((s) => {
        if (Number(target) === s.id) {
          setDisplaySegment(s.name);
        }
        return s.name;
      });
    }
  };

  // dead or alive status change
  const [deadOrAlive, setDeadOrAlive] = useState(editData && editData.dead);
  const [checkedAliveValue, setcheckedAliveValue] = useState();
  const [status, setStatus] = useState("");
  const statusChange = (checked) => {
    setChangeTracker(true);
    if (checked) {
      setDeadOrAlive(0);
      // setcheckedAliveValue(true);
    } else {
      setDeadOrAlive(1);
      // setcheckedAliveValue(false);
    }
  };

  useEffect(() => {
    if (editData) {
      if (deadOrAlive === 0) {
        setcheckedAliveValue(true);
        setStatus(`${t("alive")}`);
      } else {
        setcheckedAliveValue(false);
        setStatus(`${t("dead")}`);
      }
    }
  }, [deadOrAlive, editData, t]);

  const handleSaveEdits = async () => {
    const data = {
      obsId,
      newLocalLong,
      newLocalLat,
      site,
      segment,
      observation,
      classData,
      family,
      species,
      prevImages,
      convertedDate,
      time: currentTime + `:${seconds}`,
      dead: deadOrAlive,
    };
    const timeStr = currentTime + `:${seconds}`;
    const dateStr = convertedDate;

    // Splitting time into hours, minutes, and seconds
    const [hours, minutes, secondss] = timeStr.split(":");

    // Splitting date into year, month, and day
    const [year, month, day] = dateStr.split("-");

    // Creating the Date object
    // const ct = new Date(year, month - 1, day, hours, minutes, secondss, 0);

    // Setting the desired timezone
    // Adjusting the UTC offset
    const dt = new Date(
      Date.UTC(year, month - 1, day, hours, minutes, secondss)
    );

    // Creating the final object
    const localdateObject = {
      date: dt.toISOString().slice(0, 23),
      timezone_type: 3,
      timezone: "UTC",
    };

    const updtedObservationData = {
      id: editData.id,
      projectId: editData.projectId,
      projectName: editData.projectName,
      idInaturalist: editData.idInaturalist,
      coordX: newLocalLat,
      coordY: newLocalLong,
      note: editData.note,
      alpha: editData.alpha,
      collector: editData.collector,
      images: {
        img1: prevImages[0] || "",
        img2: prevImages[1] || "",
        img3: prevImages[2] || "",
        img4: prevImages[3] || "",
      },
      dead: deadOrAlive,
      status: editData.status,
      TypeObservationId: observation,
      TypeObservation: displayObserVationType,
      specieId: species,
      specie: displaySpecies,
      familyId: family,
      family: displayFamily,
      groupId: classData,
      group: displayGroupe,
      user: editData.user,
      date: localdateObject,
      updatedate: null,
      segment: displaySegment,
      site: displaySite,
    };

    dispatch(editeObservaion(data));
    dispatch(updateObservationLocally(updtedObservationData));

    closeIsEditModal();
  };
  useEffect(() => {
    if (updateObsSuccessNotif && updateObsSuccessNotif === true) {
      queryRefetchObsList();
      queryRefetchValidObsList();
      miniCardQueryRefetchObsList();
    }
  }, [updateObsSuccessNotif]);

  // confirm exit edit
  const refetchObsList = useSelector((state) => state.setRefetchObsList);
  const { refetchObsValue } = refetchObsList;
  const [confirmExit, setConfirmExit] = useState(false);
  const handleExitEdit = () => {
    if (trackDeleteImage) {
      dispatch(refetchObs({ refetchObsValue: !refetchObsValue }));
    }

    closeIsEditModal();
    setConfirmExit(true);
  };
  const handleCloseConfirm = () => {
    setConfirmExit(false);
  };

  useEffect(() => {
    dispatch(singleObservation({ singleObsId: editData.id }));
  }, [editData, dispatch]);

  // input tracker
  const [changeTracker, setChangeTracker] = useState(false);

  const handleShowConfirmModal = () => {
    if (changeTracker) {
      console.log("changeTracker", changeTracker);

      setConfirmExit(true);
    } else {
      //
      if (imageWasDeleted) {
        console.log("imageWasDeleted", imageWasDeleted);

        handleSaveEdits();
        closeIsEditModal();
      } else {
        console.log("handleExitEdit");

        handleExitEdit();
      }
    }
  };

  return (
    <div className="mvp__modals">
      <Modal
        show={true}
        // showModal

        backdrop="static"
        centered
        className="mx-auto mvp__modals edit__observation "
        keyboard={false}
        size="lg"
        dialogClassName="modal-size"
        aria-labelledby="example-custom-modal-styling-title"
      >
        {/* {updateLoading && (
          <div
            style={{
              position: "fixed",
              top: "30%",
              left: "50%",
              opacity: "1",
              zIndex: "9999",

              transform: "translate(-50%, -50%)}",
            }}
          >
            <Loading />{" "}
          </div>
        )} */}
        <Modal.Header
          className="bgPrimary modal__header "
          style={{
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Modal.Title className="modal__header__title">
            {t("editObs")}
          </Modal.Title>
          <div className="">
            <span
              className="pointer"
              onClick={() => {
                handleShowConfirmModal();
              }}
            >
              <RxCross1 className="close-modal-icon" />
            </span>
          </div>
        </Modal.Header>

        {editModalState ? (
          <>
            <div
              className="modal-top"
              style={{ borderBottom: "0.3px solid #ebf0ec" }}
            >
              <div
                className="modal-map"
                style={{ width: "50%", height: "100%", background: "grey" }}
              >
                <MapContainer
                  center={[
                    newLocalLat && newLocalLat,
                    newLocalLong && newLocalLong,
                  ]}
                  zoom={12}
                  scrollWheelZoom={true}
                  zoomControl={false}
                  layer-type="base"
                  style={{ width: "100%", height: "100%", zIndex: "1" }}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  />
                  {newLat && newLong && (
                    <SetMap
                      location={[newLat, newLong]}
                      project={editData}
                      lat={newLat}
                      long={newLong}
                      typeObservation={displayObserVationType}
                    />
                  )}
                  <ModalMarker observation={editData} />
                </MapContainer>
              </div>
              <div
                className="modal-imgs "
                style={{ width: "50%", height: "100%", position: "relative" }}
              >
                {prevImages && prevImages.length !== 0 ? (
                  <>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                        backgroundImage: `url(${prevImages[0]})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: " center center",
                        backgroundSize: "cover",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <div
                        className="text-center"
                        style={{
                          color: "#096DBB",

                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setEditModalState(!editModalState);
                        }}
                      >
                        <img
                          src={uploadImagePlaceHolder}
                          style={{
                            height: "100%",
                            width: "100%",
                            cursor: "pointer",
                          }}
                          alt=""
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              {prevImages && prevImages.length !== 0 ? (
                <div
                  className="modal-photo-btn edit"
                  onClick={() => {
                    setEditModalState(!editModalState);
                  }}
                >
                  {prevImages.length} + photos {">"}
                </div>
              ) : (
                ""
              )}
            </div>
            <div>
              <div
                className="modal__obs__details container-fluid "
                // style={{ width: "100%" }}
              >
                <div className="row ">
                  <div className="col-12 px-4 pt-2 ">
                    <span className="f-bold me-4" style={{ fontSize: ".9rem" }}>
                      Obs {editData && editData.id}
                    </span>
                    <span
                      className=" p-1   d-inline obs__type "
                      style={
                        displayObserVationType === "Threat" ||
                        displayObserVationType === "Menace"
                          ? observationStyle.Threat
                          : displayObserVationType === "Crawl" ||
                            displayObserVationType === "Trace"
                          ? observationStyle.Crawls
                          : displayObserVationType === "Animal"
                          ? observationStyle.Animal
                          : displayObserVationType === "Flash Observation" ||
                            displayObserVationType === "Observation flash"
                          ? observationStyle.FlashObs
                          : displayObserVationType === "Signs of presence" ||
                            displayObserVationType === "Signe de présence"
                          ? observationStyle.SignsPresence
                          : displayObserVationType === "Female Encounter" ||
                            displayObserVationType ===
                              "Rencontre tortue femelle"
                          ? observationStyle.FemaleEn
                          : displayObserVationType === "Nest" ||
                            displayObserVationType === "Nid"
                          ? observationStyle.Nest
                          : observationStyle.noStyle
                      }
                    >
                      {displayObserVationType}
                    </span>
                  </div>
                  <div className="col-5 text-end"></div>
                </div>
                <div className="row px-3 modal__obs__details__row projectName">
                  <div className="col-5 modal__detail__title">
                    {" "}
                    {t("projectName")}
                  </div>
                  <div className="col-7 modal__title__value author">
                    {editData && editData.projectName}
                  </div>
                </div>
                <div className="row px-3 modal__obs__details__row projectName">
                  <div className="col-5 modal__detail__title">
                    {t("author")}
                  </div>
                  <div className="col-7 modal__title__value author">
                    {editData && editData.user}
                  </div>
                </div>
                <div className="row px-3 modal__obs__details__row mt-1">
                  <div className="col-5 modal__detail__title">
                    {t("dateTime")}
                    <sup>*</sup>
                  </div>

                  <div className="col-7 modal__title__value d-flex">
                    <div className="d-flex  col-12">
                      <div
                        className="col-6 new__date_container"
                        style={{
                          width: "50%",
                        }}
                      >
                        <DatePicker
                          calendarIcon={<AiOutlineCalendar />}
                          clearIcon={null}
                          onChange={(e) => {
                            setNewDate(formatDate5(new Date(e)));

                            setChangeTracker(true);
                          }}
                          calendarClassName="calender__picker"
                          className={`calender__picker`}
                          value={newDate}
                          required={true}
                          format={"y-MM-dd"}
                        />
                      </div>

                      <div
                        className=" col-6 new__date_container"
                        style={{
                          width: "50%",
                        }}
                      >
                        <TimePicker
                          className="time-picker-input"
                          onChange={onChangeTime}
                          defaultValue={dayjs(`${currentTime}`, format)}
                          getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                          }}
                          format={format}
                          clearIcon={null}
                          suffixIcon={<BiTimeFive />}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row px-3 modal__obs__details__row mt-1">
                  <div className="col-5 modal__detail__title coords_mobile">
                    {t("coords")} <sup>*</sup>
                  </div>
                  <div
                    className="col-7 modal__title__value "
                    style={{
                      position: "relative",
                    }}
                  >
                    {/* new coords system */}
                    <div className="coords__wrapper__content">
                      <div
                        className="coords__wrapper "
                        ref={cordRef}
                        onClick={() => {
                          setShowCoordsBox(true);
                        }}
                      >
                        <div className="coords__container">
                          <div className="lat__container">
                            {newLocalLat && newLocalLat}
                          </div>
                          <span>,</span>
                          <div className="log__container">
                            {newLocalLong && newLocalLong}
                          </div>
                        </div>

                        <div className="coords__marker">
                          <img
                            src={position}
                            alt=""
                            width={11}
                            className="mt-1 me-2 "
                          />
                        </div>
                      </div>
                      {showCoordsBox && (
                        <div
                          className="coords__popup__wrapper"
                          style={{
                            // width: `${coordsWidth - 50}px`,
                            top: `${coordsPosition + 5}px`,
                          }}
                        >
                          {isInvalidCoords && (
                            <span
                              className="error"
                              style={{
                                position: "absolute",
                                left: "10px",
                              }}
                            >
                              {coordsErrorMessage}
                            </span>
                          )}
                          <div
                            className="coords__inputs"
                            style={
                              {
                                // width: `${coordsWidth - 50}px`,
                              }
                            }
                          >
                            <div className="coords__inputs__container">
                              <div>
                                <label>Latitude</label>
                                <input
                                  type="number"
                                  placeholder="XX.XXXXXX"
                                  defaultValue={newLocalLat && newLocalLat}
                                  style={{ fontSize: ".6rem" }}
                                  onChange={(e) => {
                                    setNewLat(e.target.value);
                                    setChangeTracker(true);
                                  }}
                                />
                              </div>
                              <div className="ms-2">
                                <label>Longitude</label>
                                <input
                                  style={{ fontSize: ".6rem" }}
                                  placeholder="XX.XXXXXX"
                                  type="number"
                                  defaultValue={newLocalLong && newLocalLong}
                                  onChange={(e) => {
                                    setNewLong(e.target.value);
                                    setChangeTracker(true);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="coords__btn__coords">
                              <div>
                                <button
                                  onClick={() => {
                                    setShowCoordsBox(false);
                                    setIsInvalidCoords(false);
                                    setIsCoordsSet(true);
                                  }}
                                >
                                  {t("cancelBtn")}
                                </button>
                              </div>
                              <div>
                                <button
                                  className="save"
                                  onClick={() => {
                                    handleSubmitCoords();
                                  }}
                                  disabled={isCoordsSet}
                                >
                                  {t("saveBtn")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row px-3 modal__obs__details__row mt-1">
                  <div className="col-5 modal__detail__title">
                    {t("site")}, {t("segment")}
                  </div>
                  <div className="col-7 modal__title__value d-flex">
                    <div className="col-6  me-1" style={{ width: "50%" }}>
                      <select
                        defaultValue={displaySite}
                        onChange={(e) => {
                          setSite(e.target.value);
                          handleSetSiteName(e.target.value);
                          setChangeTracker(true);
                          handleSetSegmentList(e.target.value);
                          setDisplaySegment("");
                          setSegment("");
                        }}
                        style={{
                          cursor: `${
                            siteSegmentLoading || siteSegmentIsRefetching
                              ? "not-allowed"
                              : "pointer"
                          }`,
                          width: "100%",
                        }}
                        disabled={
                          siteSegmentLoading || siteSegmentIsRefetching
                            ? true
                            : false
                        }
                        className="crud_select crud-input"
                      >
                        {siteSegmentLoading ? (
                          <option selected value="">
                            Loading...
                          </option>
                        ) : (
                          <option
                            value={site}
                            // disabled
                            style={{
                              color: "#073b60",
                              fontWeight: "bold",
                            }}
                          >
                            {displaySite}
                          </option>
                        )}
                        {siteSegmentList &&
                          siteSegmentList.data
                            .filter((s) => s.siteId !== editData.siteId) // Filter out the item with matching id
                            .map((s, i) => (
                              <option value={s.siteId} key={i}>
                                {s.site}
                              </option>
                            ))}
                      </select>
                    </div>
                    <div className="col-6 w-100" style={{ width: "52%" }}>
                      <select
                        defaultValue={displaySegment}
                        onChange={(e) => {
                          setSegment(e.target.value);
                          handleSetSegmentName(e.target.value);
                          setChangeTracker(true);
                        }}
                        className="crud_select crud-input"
                        style={{
                          cursor: `${
                            siteSegmentLoading || siteSegmentIsRefetching
                              ? "not-allowed"
                              : "pointer"
                          }`,
                          width: "50%",
                        }}
                        disabled={
                          siteSegmentLoading || siteSegmentIsRefetching
                            ? true
                            : false
                        }
                      >
                        {editData && editData.segment ? (
                          <option
                            value={`${editData && editData.segmentId}`}
                            // disabled
                            style={{
                              color: "#073b60",
                              fontWeight: "bold",
                            }}
                          >
                            {displaySegment}
                          </option>
                        ) : (
                          <option
                            value={`${editData && editData.segmentId}`}
                            // disabled
                            style={{
                              color: "#073b60",
                              fontWeight: "bold",
                            }}
                          >
                            Segment
                          </option>
                        )}

                        {customSegmentList &&
                          customSegmentList
                            .filter((s) => s.id !== editData.segmentId) // Filter out the item with matching id
                            .map((s, i) => (
                              <option value={s.id} key={i}>
                                {s.name}
                              </option>
                            ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row px-3 modal__obs__details__row mt-1">
                  <div className="col-5 modal__detail__title">
                    {t("obsType")} <sup>*</sup>
                  </div>
                  <div className="col-7 modal__title__value">
                    <select
                      defaultValue={editData && editData.TypeObservation}
                      onChange={(e) => {
                        setObservation(e.target.value);
                        handleSetObservationType(e.target.value);
                        console.log(
                          Number(e.target.value),
                          "Number(e.target.value) "
                        );

                        if (
                          Number(e.target.value) === 1 ||
                          Number(e.target.value) === 2 ||
                          Number(e.target.value) === 3
                        ) {
                          setGroupChangeTracker(true);
                        } else {
                          setGroupChangeTracker(false);
                        }
                        setChangeTracker(true);
                      }}
                      style={{ width: "101%" }}
                      className="crud_select crud-input"
                    >
                      <option
                        value={`${editData && editData.TypeObservationId}`}
                        style={{
                          color: "#073b60",
                          fontWeight: "bold",
                        }}
                        // disabled
                      >
                        {displayObserVationType}
                      </option>
                      {observationList &&
                        observationList
                          .filter(
                            (obsType) =>
                              obsType.id !== editData.TypeObservationId
                          )
                          .map((s, i) => (
                            <option value={s.id} key={i}>
                              {s.name}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
                <div
                  style={{
                    borderBottom: ".4px solid #cccc ",
                  }}
                  className="row px-3 pb-3"
                >
                  <div
                    className="col-12 "
                    style={{
                      padding: "0px",
                      paddingLeft: "10px",
                      margin: "0px",
                    }}
                  >
                    <label className="modal__detail__title mb-2">
                      {t("group")} / {t("family")} / {t("species")}
                    </label>
                    <div className="species-select-container d-flex ">
                      <div className="me-2 species-select-item">
                        <select
                          defaultValue={classData}
                          onChange={(e) => {
                            setClassData(e.target.value);
                            setChangeTracker(true);
                            handleSetGroupeName(e.target.value);
                            setGroupChangeTracker(true);
                          }}
                          className="crud_select_class crud-input"
                        >
                          <option
                            value={classData}
                            style={{
                              color: "#073b60",
                              fontWeight: "bold",
                            }}
                            // disabled
                          >
                            {displayGroupe}
                          </option>
                          {upDatedGroupeList &&
                            upDatedGroupeList
                              .filter((s) => s.id !== classData)
                              .map((s, i) => (
                                <option value={s.id} key={i}>
                                  {s.name}
                                </option>
                              ))}
                        </select>
                      </div>
                      <div className="me-2 species-select-item">
                        <select
                          defaultValue={family}
                          onChange={(e) => {
                            setFamily(e.target.value);
                            handleSetFamilyName(e.target.value);
                            setChangeTracker(true);
                          }}
                          className="crud_select_class crud-input"
                          style={{
                            cursor: `${
                              familyLoading || familyIsRefetching
                                ? "not-allowed"
                                : "pointer"
                            }`,
                          }}
                          disabled={
                            familyLoading || familyIsRefetching ? true : false
                          }
                        >
                          {familyLoading || familyIsRefetching ? (
                            <option disabled selected value="">
                              Loading...
                            </option>
                          ) : (
                            <option
                              value={family}
                              style={{
                                color: "#073b60",
                                fontWeight: "bold",
                              }}
                              // disabled
                            >
                              {displayFamily}
                            </option>
                          )}
                          {upDatedFamilyList &&
                            upDatedFamilyList
                              .filter((s) => s.id !== family)
                              .map((s, i) => (
                                <option value={s.id} key={i}>
                                  {s.name}
                                </option>
                              ))}
                        </select>
                      </div>
                      <div className="me-2 species-select-item">
                        <select
                          defaultValue={species}
                          onChange={(e) => {
                            setSpecies(e.target.value);
                            handleSetSpeciesName(e.target.value);
                            console.log(e.target.value, "species");

                            setChangeTracker(true);
                          }}
                          className="crud_select_class crud-input"
                          style={{
                            cursor: `${
                              speciesLoading || speciesIsRefetching
                                ? "not-allowed"
                                : "pointer"
                            }`,
                          }}
                          disabled={
                            familyLoading ||
                            familyIsRefetching ||
                            speciesLoading ||
                            speciesIsRefetching
                              ? true
                              : false
                          }
                        >
                          {familyLoading ||
                          familyIsRefetching ||
                          speciesLoading ||
                          speciesIsRefetching ? (
                            <option selected value="">
                              Loading...
                            </option>
                          ) : (
                            <option
                              value={`${species}`}
                              style={{
                                color: "#073b60",
                                fontWeight: "bold",
                              }}
                              // disabled
                            >
                              {displaySpecies}
                            </option>
                          )}

                          {upDatedSpeciesList &&
                            upDatedSpeciesList
                              .filter((s) => s.id !== species)
                              .map((s, i) => (
                                <option value={s.id} key={i}>
                                  {s.name}
                                </option>
                              ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    // borderBottom: ".4px solid #cccc ",
                    padding: "0 10px",
                  }}
                  className="row   py-1"
                >
                  <div
                    className="dead-alive   d-flex  px-3 py-2"
                    style={{
                      justifyContent: "space-between",
                      fontSize: "14px",
                      color: "#595959",
                      marginBottom: "5px",
                      background: "#fff",
                    }}
                  >
                    <div> {t("status")}</div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          marginRight: "15px",
                        }}
                      >
                        {status}
                      </div>
                      <Switch
                        checked={checkedAliveValue}
                        onChange={statusChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal-footers mt-2"
              style={{
                borderRadius: "8px",
              }}
            >
              <div
                className="d-flex justify-content-end"
                style={{
                  width: "100%",
                }}
              >
                <div
                  className="modal_obs__btn edit"
                  onClick={() => {
                    handleShowConfirmModal();
                  }}
                >
                  {t("cancelBtn")}
                </div>
                <div
                  className="modal_obs__btn edit validate"
                  onClick={() => {
                    // setShowModal(false);
                    handleSaveEdits();
                  }}
                >
                  {t("saveChanges")}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Modal.Body>
              {deleteImageLoading && (
                <div
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    opacity: "9",
                    zIndex: "9999",

                    transform: "translate(-50%, -50%)}",
                  }}
                >
                  <Loading />{" "}
                </div>
              )}
              <div className="p-2">
                <div className="create_image_modal">
                  <div>
                    <ImageUploading
                      multiple
                      // value={images}
                      onChange={uploadImages}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      acceptType={["jpg", "jpeg", "png", "hevc", "heic", "gif"]}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <>
                          {prevImages && prevImages.length === 4 ? (
                            <div className="d-flex justify-content-between align-items-center mx-1 ">
                              <div
                                onClick={() => {
                                  setEditModalState(!editModalState);
                                }}
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <img src={backArrow} alt="back" />
                              </div>

                              <button
                                style={{
                                  background: "#073B60",
                                  opacity: "0.3",
                                  padding: "10px 15px",
                                  color: "white",
                                  borderRadius: "10px",
                                  fontSize: ".8rem",
                                }}
                              >
                                <img src={plus} alt="plus" className="me-2" />
                                {t("uploadNewPhoto")}
                              </button>
                            </div>
                          ) : (
                            <div className="d-flex justify-content-between align-items-center">
                              <div
                                onClick={() => {
                                  setEditModalState(!editModalState);
                                }}
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <img src={backArrow} alt="back" />
                              </div>

                              <button
                                style={{
                                  background: "#073b60",

                                  padding: "10px 15px",
                                  color: "white",
                                  fontSize: ".8rem",
                                  borderRadius: "10px",
                                }}
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                <img src={plus} alt="plus" className="me-2" />
                                {t("uploadNewPhoto")}
                              </button>
                            </div>
                          )}

                          <div className="">
                            <div className="row d-flex ">
                              {prevImages.length === 0 ? (
                                <div
                                  className="d-flex gap-1 align-items-center justify-content-center  ms-4 pt-1 "
                                  style={{ height: "400px" }}
                                >
                                  <div> {t("noImgAvailable")}</div>
                                </div>
                              ) : (
                                <>
                                  {prevImages &&
                                    prevImages.map((image, index) => (
                                      <div
                                        key={index}
                                        style={{
                                          position: "relative",
                                        }}
                                        className="imageWrapper ms-3"
                                      >
                                        {/* <div className="delete__img__icon__container"> */}
                                        <div className="create__delete__img__icon__container   ">
                                          <AiFillDelete
                                            style={{
                                              position: "absolute",
                                              right: "5px",
                                              display: "block",
                                              top: "5px",
                                              zIndex: "9999",
                                              cursor: "pointer",

                                              opacity: "1",
                                            }}
                                            className="deleteIcon"
                                            onClick={() => {
                                              setDeleteLink(image);

                                              if (!image.startsWith("https")) {
                                                // local image delete
                                                deleteNewlyUploadedImage(index);
                                              } else {
                                                // server image
                                                setShowConfirmModal(true);

                                                setDeleteIndex(index);
                                              }
                                            }}
                                          />
                                        </div>
                                        <div
                                          className=" tableImage details "
                                          style={{
                                            backgroundImage: `url(${image})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "cover",
                                            cursor: "pointer",
                                            position: "relative",
                                          }}
                                          onClick={() => {}}
                                        />
                                      </div>
                                      // </div>
                                    ))}
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </ImageUploading>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>

      {/* Confirm delete single image */}

      {showConfirmModal && (
        <ConfirmationModal
          cofirmSourceIcon={
            <BiErrorCircle
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
              color="red"
            />
          }
          message={t("deleteItems")}
          details={t("itemsDeleted")}
          btn1Text={t("cancelBtn")}
          btn2Text={t("deleteBtn")}
          color={"#404040"}
          handleHideConfirmationModal={handleHideConfirmationModal}
          handleConfirmAction={handleDeleteImage}
          closeConfirm={handleCloseConfirmOnly}
        />
      )}

      {/* Confirm edit exist */}
      {confirmExit && (
        <ConfirmationModal
          cofirmSourceIcon={
            <BiErrorCircle
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
              color="red"
            />
          }
          message={`${t("saveChanges")} ?`}
          details={t("lostUnsaved")}
          btn1Text={t("dontSave")}
          btn2Text={t("saveBtn")}
          color={"#404040"}
          handleHideConfirmationModal={handleExitEdit}
          handleConfirmAction={handleSaveEdits}
          closeConfirm={handleCloseConfirm}
        />
      )}
    </div>
  );
};

export default EditObservation;
