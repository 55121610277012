import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { set, useForm } from "react-hook-form";
import { RxCross1 } from "react-icons/rx";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { addQuestionData } from "../../actions/projectActions";
const EditQuestion = ({ closeModal, data }) => {
  const dispatch = useDispatch();
  const validationSchema = yup.object().shape({
    type: yup.string().required(`${t("required")}`),
    question: yup.string().required(`${t("required")}`),
  });

  const {
    register,

    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const [question, setQuestion] = useState(data.title);
  const [singleAnswer, setSingleAnswer] = useState("");
  const [answerList, setAnswerList] = useState([]);
  const handleAddAnswer = () => {
    setAnswerList((prev) => [singleAnswer, ...prev]);
    setSingleAnswer("");
  };
  const handleDeleteAnswer = (index) => {
    setAnswerList((prev) => prev.filter((item, i) => i !== index));
  };
  const handleAddQuestion = () => {
    if (selectedOption) {
      dispatch(
        addQuestionData({
          questionData: {
            id: data.id,
            title: question,
            type: selectedOption.label,
            answers: answerList,
          },
        })
      );

      closeModal();
    }
  };
  const questionsOptions = [
    { value: 1, label: "Text" },

    { value: 2, label: "Select" },
  ];
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    if (data.type === "Text") {
      setSelectedOption(questionsOptions[0]);
    } else {
      setSelectedOption(questionsOptions[1]);
      setAnswerList(data.answers);
    }
  }, [data]);

  return (
    <div className="mvp__modals">
      <Modal
        show={true}
        onHide={closeModal}
        backdrop="static"
        centered
        className="mx-auto mvp__modals view_modal"
        keyboard={false}
        size="lg"
        style={{
          borderTopLeftRadius: "20px",
        }}
        dialogClassName="modal-size"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header
          className="bgPrimary text-left modal__header"
          style={{
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Modal.Title className="modal__header__title">
            Edit Question
          </Modal.Title>
          <div className="">
            <span
              className=" pointer"
              onClick={() => {
                closeModal();
              }}
            >
              <RxCross1 className="close-modal-icon" />
            </span>
          </div>
        </Modal.Header>

        <>
          <Modal.Body>
            <div
              className="p-2"
              style={{
                maxHeight: "100%",
                overflowY: "auto",
              }}
            >
              <div className="create_image_modal">
                <form className="mx-2 auth_forms">
                  <div
                    className="mt-1 mb-1  position-relative "
                    style={{ height: "80px" }}
                  >
                    <label className="question_label">Question Title </label>
                    <br />

                    <input
                      type="text"
                      name="question"
                      value={question}
                      placeholder={`Enter your question`}
                      onChange={(e) => setQuestion(e.target.value)}
                      className={`w-100  form-control `}
                    />

                    <p className="error">{errors.question?.message}</p>
                  </div>
                  <div
                    className="mt-3 mb-1  position-relative "
                    style={{ height: "80px" }}
                  >
                    <label className="question_label">Question Type </label>
                    <br />

                    <Select
                      options={questionsOptions}
                      placeholder={`Select Question Type`}
                      value={selectedOption}
                      onChange={(choice) => setSelectedOption(choice)}
                      // defaultValue={}
                      className="search__select"
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    />

                    <p className="error">{errors.question?.message}</p>
                  </div>

                  {selectedOption && selectedOption.value === 2 && (
                    <>
                      <div
                        className="mt-1 mb-1  position-relative "
                        style={{ height: "80px" }}
                      >
                        <label className="question_label__options">
                          Answers options{" "}
                        </label>
                        <br />

                        <input
                          type="text"
                          name="question"
                          placeholder={`Enter Answers options`}
                          // className="w-100 emailtxt"
                          value={singleAnswer}
                          onChange={(e) => setSingleAnswer(e.target.value)}
                          className={`w-100  form-control  ${
                            errors.email ? "is-invalid" : ""
                          }`}
                        />

                        <p className="error">{errors.question?.message}</p>
                      </div>
                      <div className="answer__btn__container">
                        <button
                          disabled={!singleAnswer || singleAnswer.trim() === ""}
                          onClick={() => handleAddAnswer()}
                        >
                          Add Answer
                        </button>{" "}
                      </div>
                    </>
                  )}
                  <div className="answers__container">
                    <ul>
                      {answerList &&
                        answerList.map((answer, index) => (
                          <li key={index}>
                            <div>
                              <BsDot />
                              {answer}
                            </div>{" "}
                            <small onClick={() => handleDeleteAnswer(index)}>
                              x
                            </small>
                          </li>
                        ))}
                    </ul>
                  </div>

                  <button
                    onClick={handleAddQuestion}
                    type="button"
                    style={{
                      position: "absolute",
                      bottom: "20px",
                      left: "25px",
                      width: "90%",
                    }}
                    className=" btn-block  mt-4 login-btn"
                  >
                    Save Question
                  </button>
                </form>
              </div>
            </div>
          </Modal.Body>
        </>
      </Modal>
    </div>
  );
};

export default EditQuestion;
