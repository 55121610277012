import React, { useEffect, useRef } from "react";
import Loading from "../../LoadingError/Loading";

const InfiniteScroll = ({
  children,
  fetchNextPage,
  isLoadingNextPage,
  fetchNextPageLoader,
}) => {
  const observer = useRef();

  useEffect(() => {
    const callback = (entries) => {
      const target = entries[0];

      if (target.isIntersecting && !isLoadingNextPage) {
        fetchNextPage();
      }
    };

    observer.current = new IntersectionObserver(callback);
    observer.current.observe(document.querySelector("#sentinel"));

    return () => observer.current.disconnect();
  }, [fetchNextPage, isLoadingNextPage]);

  return (
    <div>
      {children}
      <div id="sentinel"></div>

      {fetchNextPageLoader && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            padding: "10px 20px",
          }}
        >
          {/* <Loading /> */}
          <p className="p-2">Loading....</p>
        </div>
      )}
    </div>
  );
};

export default InfiniteScroll;
