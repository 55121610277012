import { observationStyle } from "../../../utils/TypeObsStyle";
import "./style.css";
import { ReactComponent as PlaceholderImage } from "../../../assets/images/no-image.svg";
import Loading from "../../LoadingError/Loading";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import ConfirmationModal from "../../modals/ConfirmationModal";
import EditObservation from "../../modals/editObs/EditObservation";
import ImageCarosel from "../../modals/ImageCarosel";
import DetailModal from "../../modals/detailObs/DetailModal";
import {
  deleteObservaion,
  deleteObservationLocally,
} from "../../../actions/projectActions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useTranslateObsType } from "../../../utils/useTranslateObservationType";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
export const MarkerPopup = ({ project }) => {
  const locationPath = useLocation();
  const [preventModalOpen, setPreventModalOpen] = useState(true);
  useEffect(() => {
    if (locationPath.pathname === "/explore/map") {
      setPreventModalOpen(false);
    } else {
      setPreventModalOpen(true);
    }
  }, [locationPath.pathname]);
  const { t } = useTranslation();
  const handleHideModal = () => {
    setShowProjectModal(false);
  };

  //edit modal hide and show state and function
  const [showEditModal, setShowEditModal] = useState(false);

  // caroel
  const [showCarosel, setShowCarosel] = useState(false);
  const handleShowImageCarosel = () => {
    setShowCarosel(true);
    setShowProjectModal(false);
  };
  const hideImageCarosel = () => {
    setShowCarosel(false);
  };

  const handleHideEditModal = () => {
    setShowEditModal(false);
  };
  const handleOpenEditModal = () => {
    setShowEditModal(true);
    setShowProjectModal(false);
  };
  const dispatch = useDispatch();

  const [showProjectModal, setShowProjectModal] = useState(false);

  // delete modal state and functions
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [observaionId, setObservaionId] = useState();

  const handleHideConfirmationModal = () => {
    setShowConfirmModal(false);
  };
  const deleteObservation = useSelector((state) => state.deleteObservation);
  const { deleteLoading, deleteError } = deleteObservation;

  const handleConfirmAction = () => {
    dispatch(deleteObservaion(observaionId));
    dispatch(deleteObservationLocally([observaionId]));
    handleHideConfirmationModal();
  };

  // deleteObsFromView

  const [deleteObsFromView, setDeleteObsFromView] = useState(false);
  const handleDeleteObsFromView = () => {
    setDeleteObsFromView(!deleteObsFromView);
  };
  const closeConfirm = () => {
    setDeleteObsFromView(false);
  };
  const closeConfirm2 = () => {
    setShowConfirmModal(false);
  };
  const translatedObsType = useTranslateObsType(project.TypeObservation);
  function getCurrentURL() {
    return window.location.pathname;
  }
  const url = getCurrentURL();
  return (
    <>
      <div className="marker-popup marker-obs">
        <div className="marker-img marker-details">
          {project?.images.img1 !== "NULL" ? (
            <img
              src={project.images.img1}
              alt="marker-img"
              width={`90px`}
              height={`90px`}
            />
          ) : (
            <div className="marker__img__container">
              <PlaceholderImage className="no-image" />
            </div>
          )}
        </div>
        <table
          className=" popup__table"
          style={{
            height: "150px",
          }}
        >
          <thead>
            <tr>
              <td>
                {preventModalOpen ? (
                  <div
                    className="marker-head"
                    onClick={() => {
                      if (url === "/map") {
                        setShowProjectModal(true);
                      }
                    }}
                  >
                    Obs {project.id}
                  </div>
                ) : (
                  <div
                    className="marker-head"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    {" "}
                    Obs {project.id}
                  </div>
                )}
              </td>
              <td>
                <div className="obs__btn__container">
                  <div
                    className="obs-btn"
                    style={
                      project.TypeObservation === "Threat"
                        ? observationStyle.Threat
                        : project.TypeObservation === "Crawl"
                        ? observationStyle.Crawls
                        : project.TypeObservation === "Animal"
                        ? observationStyle.Animal
                        : project.TypeObservation === "Flash Observation"
                        ? observationStyle.FlashObs
                        : project.TypeObservation === "Signs of presence"
                        ? observationStyle.SignsPresence
                        : project.TypeObservation === "Female Encounter"
                        ? observationStyle.FemaleEn
                        : project.TypeObservation === "Nest"
                        ? observationStyle.Nest
                        : observationStyle.noStyle
                      //  styles.noStyle
                    }
                  >
                    <p>{translatedObsType}</p>
                  </div>
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="obs_title">{t("author")}:</div>
              </td>
              <td>
                <div className="obs_details obs_author">{project.user}</div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="obs_title">{t("location")}:</div>
              </td>
              <td>
                <div className="obs_details">{project.site}</div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="obs_title">Date:</div>
              </td>
              <td>
                <div className="obs_details obs__time">
                  {/* {formatDate2(project.date.date)} */}
                  {project.date.date.slice(0, 10)}{" "}
                  <div>{project.date.date.slice(11, 16)}</div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="obs_title">{t("group")}:</div>
              </td>
              <td>
                <div className="obs_details">{project.group}</div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="obs_title">{t("family")}:</div>
              </td>
              <td>
                <div className="obs_details">{project.family}</div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="obs_title">{t("species")}:</div>
              </td>
              <td>
                <div className="obs_details">{project.specie}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {showProjectModal && (
        <DetailModal
          editData={project}
          closeDetailModal={handleHideModal}
          openIsEditModal={handleOpenEditModal}
          handleShowImageCarosel={handleShowImageCarosel}
        />
      )}
      {showEditModal && (
        <EditObservation
          editData={project}
          closeIsEditModal={handleHideEditModal}
        />
      )}

      {showCarosel && (
        <ImageCarosel
          // handleShowImageCarosel={handleShowImageCarosel}
          hideImageCarosel={hideImageCarosel}
          images={project.images}
        />
      )}

      {showConfirmModal && (
        <ConfirmationModal
          cofirmSourceIcon={
            <AiOutlineQuestionCircle
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
              color="red"
            />
          }
          message={"Delete Items?"}
          details={"items will be deleted. This action cannot be undone"}
          btn1Text={"Cancel"}
          btn2Text={"Delete"}
          color={"red"}
          handleHideConfirmationModal={handleHideConfirmationModal}
          handleConfirmAction={handleConfirmAction}
          closeConfirm={closeConfirm2}
        />
      )}
      {deleteObsFromView && (
        <ConfirmationModal
          cofirmSourceIcon={
            <AiOutlineQuestionCircle
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
              color="red"
            />
          }
          message={"Delete Items?"}
          details={"items will be deleted. This action cannot be undone"}
          btn1Text={"Cancel"}
          btn2Text={"Delete"}
          color={"red"}
          handleHideConfirmationModal={handleDeleteObsFromView}
          closeConfirm={closeConfirm}
          handleConfirmAction={handleConfirmAction}
        />
      )}

      {deleteLoading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",

            transform: "translate(-50%, -50%)}",
          }}
        >
          <Loading />
        </div>
      )}
    </>
  );
};
