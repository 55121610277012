import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { API_URL as API } from "../services/API";
import { logout } from "../actions/userActions";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";

// Fetch Top contributors

export const useFetchTopContributors = ({ dates }) => {
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data, isError, error, failureReason, isLoading, refetch } = useQuery(
    ["topContributors", localProjectId, dates],
    () => {
      return axios
        .get(
          API +
            `api/dashboard/topContributors?project_id=${localProjectId}&start_date=${dates[0]}&end_date=${dates[1]}`,

          config
        )
        .then((res) => res.data);
    }
  );
  if (failureReason && failureReason?.response?.data?.code === 401) {
    dispatch(logout());
    document.location.href = "/";
  }

  useEffect(() => {
    refetch();
  }, [refetch, dates, localProjectId]);

  return {
    topContributor: data?.topContributors,
    topContriError: error,
    topContriLoading: isLoading,
    refechTopContributors: refetch,
  };
};

// Fetch Active contributors

export const useFetchActiveContributors = ({ dates }) => {
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data, isError, error, failureReason, isLoading, refetch } = useQuery(
    ["activeContributors", localProjectId, dates],
    () => {
      return axios
        .get(
          API +
            `api/dashboard/totalActiveContributors?project_id=${localProjectId}&start_date=${dates[0]}&end_date=${dates[1]}`,

          config
        )
        .then((res) => res.data);
    }
  );
  if (failureReason && failureReason?.response?.data?.code === 401) {
    dispatch(logout());
    document.location.href = "/";
  }

  useEffect(() => {
    refetch();
  }, [refetch, dates, localProjectId]);

  return {
    activeContributor: data?.totalActiveContributors,
    activeContriError: error,
    activeContriLoading: isLoading,
    refechActiveContributors: refetch,
  };
};

// Total unvalide Obs
// api/dashboard/totalUnvalidObs

export const useFetchTotalUnvalidObs = ({ dates }) => {
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data, isError, error, failureReason, isLoading, refetch } = useQuery(
    ["totalUnvalidObs", localProjectId, dates],
    () => {
      return axios
        .get(
          API +
            `api/dashboard/totalUnvalidObs?project_id=${localProjectId}&start_date=${dates[0]}&end_date=${dates[1]}`,

          config
        )
        .then((res) => res.data);
    }
  );
  if (failureReason && failureReason?.response?.data?.code === 401) {
    dispatch(logout());
    document.location.href = "/";
  }

  useEffect(() => {
    refetch();
  }, [refetch, dates, localProjectId]);

  return {
    totalUnvalidObs: data?.totalUnvalidObs,
    totalUnvalidObsError: error,
    totalUnvalidObsLoading: isLoading,
    refechTotalUnvalidObs: refetch,
  };
};

// Total unvalide Obs
// api/dashboard/totalUnvalidObs

export const useFetchTotalObservations = ({ dates }) => {
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data, isError, error, failureReason, isLoading, refetch } = useQuery(
    ["totalObs", localProjectId, dates],
    () => {
      return axios
        .get(
          API +
            `api/dashboard/totalObservationsDated?project_id=${localProjectId}&start_date=${dates[0]}&end_date=${dates[1]}`,

          config
        )
        .then((res) => res.data);
    }
  );
  if (failureReason && failureReason?.response?.data?.code === 401) {
    dispatch(logout());
    document.location.href = "/";
  }

  useEffect(() => {
    refetch();
  }, [refetch, dates, localProjectId]);

  return {
    totalObs: data,
    totalObsError: error,
    totalObsLoading: isLoading,
    refechTotalObs: refetch,
  };
};

// Total Patrol Time

export const useFetchTotalPatrolTime = ({ dates }) => {
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data, isError, error, failureReason, isLoading, refetch } = useQuery(
    ["totalPatrolTime", localProjectId, dates],
    () => {
      return axios
        .get(
          API +
            `api/dashboard/totalPatrolTime?project_id=${localProjectId}&start_date=${dates[0]}&end_date=${dates[1]}`,

          config
        )
        .then((res) => res.data);
    }
  );
  if (failureReason && failureReason?.response?.data?.code === 401) {
    dispatch(logout());
    document.location.href = "/";
  }

  useEffect(() => {
    refetch();
  }, [refetch, dates, localProjectId]);

  return {
    totalPatrolTime: data?.totalPatrolTime,
    totalPatrolTimeError: error,
    totalPatrolTimeLoading: isLoading,
    refechTotalPatrolTime: refetch,
  };
};

// obsByGroup

export const useFetchObsByGroup = ({ dates }) => {
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const dispatch = useDispatch();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data, isError, error, failureReason, isLoading, refetch } = useQuery(
    ["obsStatsByGroup", localProjectId, dates],
    () => {
      return axios
        .get(
          API +
            `api/dashboard/obsStatsByGroup?project_id=${localProjectId}&start_date=${dates[0]}&end_date=${dates[1]}`,

          config
        )
        .then((res) => res.data);
    }
  );
  if (failureReason && failureReason?.response?.data?.code === 401) {
    dispatch(logout());
    document.location.href = "/";
  }

  useEffect(() => {
    refetch();
  }, [refetch, dates, localProjectId]);

  return {
    obsByGroup: data,
    obsByGroupError: error,
    obsByGroupLoading: isLoading,
    refechObsByGroup: refetch,
  };
};

// top species

export const useFetchTopSpecies = ({ dates, localProjectId }) => {
  const [topSpecies, setTopSpecies] = useState(null);
  const [topSpeciesError, setTopSpeciesError] = useState(null);
  const [topSpeciesLoading, setTopSpeciesLoading] = useState(false);

  useEffect(() => {
    const fetchTopSpecies = async () => {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const token = userInfo.data.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setTopSpeciesLoading(true);
        const response = await axios.get(
          API +
            `api/dashboard/topSpecies?project_id=${localProjectId}&start_date=${dates[0]}&end_date=${dates[1]}`,
          config
        );
        setTopSpecies(response.data.topSpecies);
        setTopSpeciesLoading(false);
      } catch (error) {
        setTopSpeciesError(error);
        setTopSpeciesLoading(false);
      }
    };

    fetchTopSpecies();
  }, [dates, localProjectId]);

  return {
    topSpecies,
    topSpeciesError,
    topSpeciesLoading,
  };
};

// top species

export const useFetchObsByMonth = ({ dates }) => {
  const [obsStatsByMonth, setObsStatsByMonth] = useState(null);
  const [obsStatsByMonthError, setObsStatsByMonthError] = useState(null);
  const [obsStatsByMonthLoading, setObsStatsByMonthLoading] = useState(false);
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;

  useEffect(() => {
    const fetchObsByMonth = async () => {
      setObsStatsByMonth(null);
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const token = userInfo.data.token;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        setObsStatsByMonthLoading(true);
        const response = await axios.get(
          API +
            `api/dashboard/obsStatsByMonth?project_id=${localProjectId}&start_date=${dates[0]}&end_date=${dates[1]}`,
          config
        );
        setObsStatsByMonth(response.data);
        setObsStatsByMonthLoading(false);
      } catch (error) {
        setObsStatsByMonthError(error);
        setObsStatsByMonthLoading(false);
      }
    };

    fetchObsByMonth();
  }, [dates, localProjectId]);

  return {
    obsStatsByMonth,
    obsStatsByMonthError,
    obsStatsByMonthLoading,
  };
};
