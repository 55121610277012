import React from "react";

const Message = ({ variant, children, dynamicClass }) => {
  return (
    <div className={` alert error__notif ${dynamicClass} ${variant}`}>
      {children}
    </div>
  );
};

Message.defaultProps = {
  variant: "alert-info",
};

export default Message;
