import React, { useEffect, useState } from "react";

import "./styles.css";
import { Button, DatePicker, Form } from "antd";
import searchIcon from "../../../assets/images/icon/search2.svg";

import { useDispatch, useSelector } from "react-redux";
import {
  changeMarker,
  listProjects,
  observationTypesAction,
} from "../../../actions/projectActions";

import dayjs from "dayjs";

import { useTranslation } from "react-i18next";
import VIPHeader from "../../../components/vip/header/VIPHeader";

import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useFetchVIPObservaionList } from "../../../queries/useFetchVIPData";
import VIPListViewTable from "./VIPListViewTable";

const { RangePicker } = DatePicker;

function VIPListView() {
  const { t } = useTranslation();

  useEffect(() => {
    document
      .querySelector(".openSearch")
      .addEventListener("click", function () {
        document.querySelector(".content-search").style.left =
          document.querySelector(".shorsearch").offsetLeft - 150 + "px";
        document.querySelector(".content-search").classList.remove("d-none");
        // if (wwidth <= 1285) document.querySelector(".content-search").style.right = "0";
      });
  }, []);

  // Show project list

  const userLogin = useSelector((state) => state.userLogin);

  const [projectId, setProjectId] = useState();

  // local storage change
  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;

  useEffect(() => {
    // setTime
    let projectId = localStorage.getItem("projectId");

    setProjectId(projectId);
  }, [localProjectId]);

  const { userInfo } = userLogin;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      observationTypesAction({
        observationTypesListFromReducer: [],
      })
    );
    dispatch(listProjects());
  }, [dispatch, userInfo]);
  // const { projects } = listOfProjects;
  useEffect(() => {
    dispatch(changeMarker({ id: "", index: "" }));
  }, [dispatch]);

  // date range picker functions
  const [openCalendar, setOpenCalendar] = useState(false);
  // Set dates
  const [dates, setDates] = useState([]);
  // date range picker functions
  const [form] = Form.useForm();
  const onButton = () => {
    form.resetFields();
    setDates([]);
    setOpenCalendar(false);
  };
  const onChangeRangeDate = (value, dateString) => {
    if (value) {
      let rd = value.map((items) => {
        let m = dayjs(items).toISOString();

        return m;
      });
      setDates(rd);
      setOpenCalendar(false);
    }
  };

  useEffect(() => {
    onChangeRangeDate();
  }, [dates]);

  // Upload to iNaturalist nottifications

  // fetch observaion with react query
  const location = useLocation();
  const [projectID, setProjectID] = useState(null);
  useEffect(() => {
    const currentUrl = location.pathname;
    const match = currentUrl.match(/vid=([^&]+)/);
    const vid = match ? match[1] : null;
    setProjectID(vid);
  }, [location.pathname]);
  const {
    data,
    obsListLoading,
    isRefetchingObsList,

    queryRefetchValidObsList,
    fetchNextPage,
  } = useFetchVIPObservaionList({ id: localStorage.getItem("vid_projectId") });
  useEffect(() => {
    queryRefetchValidObsList();
  }, [queryRefetchValidObsList]);

  const [page, setPage] = useState(0);
  useEffect(() => {
    if (data && data !== "undefined") {
      if (data.pages[0].param.Page > page) {
        setPage(page + 1);
        fetchNextPage();
      }
    }
  }, [data]);
  const [rowData, setRowsData] = useState([]);

  // setting observtion List to a use state
  const [allValideObs, setAllValideObs] = useState([]);

  useEffect(() => {
    setRowsData(allValideObs);
  }, [allValideObs]);

  return (
    <>
      <div
        className="container-fluid p-0"
        style={{
          overflowY: "hidden",
        }}
      >
        <div className="header-list-view">
          <VIPHeader />

          <div
            className="d-flex flex-column flex-shrink-0 menu-list position-absolute w-100 h-100 d-none"
            // style="z-index: 20;"
            style={{ zIndex: "20" }}
          >
            <div className="menu-close text-end p-2">
              <span className="close-menu">
                <span className="pe-sm-2">
                  <img
                    src="assets/elements/images/icon/close.svg"
                    alt=""
                    // style="height: 17px"
                    style={{ height: "17px " }}
                  />
                </span>
              </span>
            </div>
          </div>

          <div className="row head02 px-2  ">
            <div
              className="menu-button-bas align-items-stretch pointer px-0 "
              style={{ width: "100%" }}
            >
              <div className=" d-flex align-items-center borderright menelem bpro pointer ">
                <div
                  className="d-none content-projet "
                  style={{ zIndex: "10" }}
                >
                  <div className="text-end">
                    <span className="ps-2 pe-2  d-inline-block projet-close pointer ">
                      <img
                        src="assets/elements/images/icon/close.svg"
                        alt=""
                        style={{ height: "15px" }}
                        // onClick={() => {
                        //   setToggleProjectList(false);
                        // }}
                      />
                    </span>
                  </div>
                </div>
              </div>

              <div className="px-4 d-flex align-items-center justify-content-center borderright flex-fill stafdate">
                <div className="px-2 d-flex align-items-center   flex-fill stafdate">
                  <span
                    className=" pe-sm-2 "
                    onClick={() => {
                      setOpenCalendar(!openCalendar);
                    }}
                  >
                    <img
                      src="assets/elements/images/icon/calander.svg"
                      alt=""
                      className="filtre"
                    />
                  </span>
                  <Form
                    form={form}
                    name="advanced_assessment_form"
                    className="ant-date-picker"
                  >
                    <Form.Item name="field">
                      <RangePicker
                        onClick={() => {
                          setOpenCalendar(true);
                        }}
                        className="dateRange"
                        placeholder={[
                          `${t("admin.exportData.startDate")}`,
                          `${t("admin.exportData.endDate")}`,
                        ]}
                        allowClear={true}
                        onChange={onChangeRangeDate}
                        clearIcon={null}
                        open={openCalendar}
                      ></RangePicker>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        onClick={onButton}
                        className={`${
                          dates.length === 0
                            ? "clear__filters__btn__disabled"
                            : "clear__filters__btn"
                        }`}
                        style={{ marginLeft: "3rem", width: "92px" }}
                      >
                        {t("clear")}
                      </Button>
                    </Form.Item>
                  </Form>
                  <div
                    className="cont-date d-none px-4 "
                    style={{ zIndex: "10" }}
                  >
                    <div className="text-end">
                      <span className="p-2  d-inline-block date-close pointer ">
                        <img
                          src="assets/elements/images/icon/close.svg"
                          alt=""
                          style={{ height: "5px" }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="cont-date d-none px-4 "
                  style={{ zIndex: "10" }}
                >
                  <div className="text-end">
                    <span className="p-2  d-inline-block date-close pointer ">
                      <img
                        src="assets/elements/images/icon/close.svg"
                        alt=""
                        // style="height: 15px"
                        style={{ height: "15px" }}
                      />
                    </span>
                  </div>
                </div>
              </div>

              <div className="px-3 d-flex align-items-center justify-content-center shorsearch borderright ">
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="openSearch"
                >
                  <button
                    type="button"
                    className="btn btn-light btn-sort ml-auto "
                  >
                    <img src={searchIcon} alt="" />
                  </button>
                  <span
                    className="ms-sm-1 search__text text-center  d-block"
                    style={{ fontSize: ".9rem" }}
                  >
                    {t("search")}
                  </span>
                </div>
              </div>

              {/* hey */}
            </div>
          </div>
        </div>
        <main style={{ zIndex: "2" }} className="main main-list-view">
          <VIPListViewTable
            handleCallback={() => {}}
            projectId={projectId}
            dates={dates}
            obsData={rowData}
            obsListLoading={obsListLoading}
            isRefetching={isRefetchingObsList}
            selectedProject={setProjectId}
          />
        </main>
      </div>
    </>
  );
}

export default VIPListView;
