import React from "react";
import Image from "react-bootstrap/Image";
import "./styles.css";

export default function SecondaryBtn(params) {
  const { text, link, onClick, renderIcon = true } = params;
  if (!link && !onClick) return;

  return (
    <>
      {onClick ? (
        <button className="secondaryBtn" onClick={onClick}>
          {text}
          {renderIcon && (
            <Image
              src="assets/elements/images/icon/blackDownArrow.svg"
              alt="dropDownArrow"
            />
          )}
        </button>
      ) : (
        <a className="secondaryBtn" href={link}>
          {text}
          <Image
            src="assets/elements/images/icon/blackDownArrow.svg"
            alt="dropDownArrow"
          />
        </a>
      )}
    </>
  );
}
