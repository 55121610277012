import React from "react";

import { Switch } from "react-router-dom";
import PrivateRoute from "../auth/PrivateRoute";

import MapContainer from "../pages/map/MapContainer";
import ListView from "../pages/listviewPage/ListView";
import UnvalidatedView from "../pages/listviewPage/UnvalidatedView";
import Admin from "../pages/admin/Admin";
import AdminRoute from "../auth/AdminRoute";
import Patrols from "../pages/patrols/Patrols";
import PatrolValidObs from "../pages/patrols/PatrolValidObs";
import PatrolUnvalidObs from "../pages/patrols/PatrolUnvalidObs";
import SinglePatrols from "../pages/patrols/SinglePatrol";
import Dashboard from "../pages/dashboard/Dashboard";
import TableQuestions from "../components/ListViewTable/TableQuestions";

const Navigation = () => {
  return (
    <Switch>
      <PrivateRoute path="/map" exact component={MapContainer} />
      <PrivateRoute path={`/listview-validate`} exact component={ListView} />
      <PrivateRoute path={`/admin`} exact component={Admin} />
      <PrivateRoute path={`/dashboard`} exact component={Dashboard} />
      <PrivateRoute path={`/patrols`} exact component={Patrols} />
      <PrivateRoute
        path={`/patrols/:patrolId`}
        exact
        component={SinglePatrols}
      />
      <PrivateRoute
        path={`/patrols/observations/valid/:patrolId`}
        exact
        component={PatrolValidObs}
      />
      <PrivateRoute
        path={`/patrols/observations/unvalid/:patrolId`}
        exact
        component={PatrolUnvalidObs}
      />
      <PrivateRoute
        path={`/questions-answers/observations/:id`}
        exact
        component={TableQuestions}
      />

      <AdminRoute
        path="/listview-unvalidate"
        exact
        component={UnvalidatedView}
      />
    </Switch>
  );
};
export default Navigation;
