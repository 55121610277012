import axios from "axios";
import { toast } from "react-toastify";
import {
  PROJECT_LIST_REQUEST,
  PROJECT_LIST_SUCCESS,
  PROJECT_LIST_FAIL,
  CREATE_OBSERVATION_REQUEST,
  CREATE_OBSERVATION_SUCCESS,
  CREATE_OBSERVATION_FAILD,
  UPDATE_OBSERVATION_REQUEST,
  UPDATE_OBSERVATION_SUCCESS,
  UPDATE_OBSERVATION_FAILD,
  DELETE_OBSERVATION_REQUEST,
  DELETE_OBSERVATION_SUCCESS,
  DELETE_OBSERVATION_FAILD,
  VALIDATE_UNVALIDATE_OBSERVATIONS_REQUEST,
  VALIDATE_UNVALIDATE_OBSERVATIONS_SUCCESS,
  VALIDATE_UNVALIDATE_OBSERVATIONS_FAILD,
  DELETE_LIST_OF_OBSERVATION_REQUEST,
  DELETE_LIST_OF_OBSERVATION_SUCCESS,
  DELETE_LIST_OF_OBSERVATION_FAILD,
  DELETE_OBSERVATION_IMAGE_SUCCESS,
  DELETE_OBSERVATION_IMAGE_FAILD,
  DELETE_OBSERVATION_IMAGE_REQUEST,
  SET_DATA,
  CHANGE_MARKER,
  SUCCESS_NOTIFICATION,
  ERROR_NOTIFICATION,
  REFETCH_OBS_LIST,
  SET_USER_ROLE_ON_PROJECT,
  UPDATE_OBSERVATION_LOCALY,
  DELETE_OBS_LOCALLY,
  ValIDATE_OBS_LOCALLY,
  CREATE_OBS_AND_STORE_LOCALLY,
  NEW_OBS_ID,
  MAP_ZOOM_LEVEL,
  OBSERVATIONS_TYPES,
  SINGLE_OBSERVATION,
  PAN_TRAKER,
  OPEN_POPUP,
  DATE_RANGE,
  SITE_SEGMENT,
  EDIT_SITE_SEGMENT,
  UPLOAD_OBSERVATION_TO_INATURALIST_REQUEST,
  UPLOAD_OBSERVATION_TO_INATURALIST_SUCCESS,
  UPLOAD_OBSERVATION_TO_INATURALIST_FAILD,
  UPLOAD_OBS_PROGRESS,
  ADD_QUESTION_DATA,
  DELETE_QUESTION,
  // SITE_SEGMENT_COMPONENT_LIST,
} from "../constants/projectConstants";
import { API_URL as API } from "../services/API";

export const listProjects = (sw, ne) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROJECT_LIST_REQUEST,
      payload: [],
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    const response = await axios.get(API + "api/user/project", config);

    dispatch({
      type: PROJECT_LIST_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch(ErrorNofit({ errorNotif: true }));
    setTimeout(() => {
      dispatch(ErrorNofit({ errorNotif: false }));
    }, 7000);
    dispatch({
      type: PROJECT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    console.log(error);
  }
};

// Create observation
export const createObservaion =
  (createObject) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_OBSERVATION_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.data.token}`,
        },
      };

      const { data } = await axios.post(
        API + `api/observation/create`,
        {
          project_id: createObject.projectId,
          segment_id: createObject.segment,
          siteId: createObject.site,
          type_observation_id: createObject.obsType,
          groupe: createObject.groupe,
          family: createObject.family,
          specie_id: createObject.species,
          img1: createObject.imgs.img1,
          img2: createObject.imgs.img2,
          img3: createObject.imgs.img3,
          img4: createObject.imgs.img4,
          date: createObject.date,
          time: createObject.time,
          coordX: createObject.coordX,
          coordY: createObject.coordY,
          dead: createObject.dead,
        },
        config
      );

      dispatch(successNofit({ createObsSuccessNotif: true }));
      dispatch(successNofit({ createObsSuccessNotif: true }));
      dispatch(newObservationId(data.data.id));
      dispatch(singleObservation({ singleObsId: data.data.id }));

      setTimeout(() => {
        dispatch(successNofit({ createObsSuccessNotif: false }));
      }, 7000);

      dispatch({
        type: CREATE_OBSERVATION_SUCCESS,
        payload: data.message,
      });
    } catch (error) {
      dispatch(ErrorNofit({ createObsErrorNotif: true }));
      console.log(error, "error");
      setTimeout(() => {
        dispatch(ErrorNofit({ createObsErrorNotif: false }));
      }, 7000);

      dispatch({
        type: CREATE_OBSERVATION_FAILD,
        payload: error.message,
      });
    }
  };

// edit observation
export const editeObservaion = (updateData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_OBSERVATION_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    const { data } = await axios.post(
      API + `api/observation/update`,
      {
        id: updateData.obsId,
        segment_id: updateData.segment,
        // site,
        groupe: updateData.classData ? Number(updateData.classData) : "",
        familly: updateData.family,
        specie_id: updateData.species,
        siteId: updateData.site,
        img1: updateData.prevImages[0] || "",
        img2: updateData.prevImages[1] || "",
        img3: updateData.prevImages[2] || "",
        img4: updateData.prevImages[3] || "",
        type_observation_id: updateData.observation,
        coordX: updateData.newLocalLat,
        coordY: updateData.newLocalLong,
        date: updateData.convertedDate,
        time: updateData.time,
        dead: updateData.dead,
        // dates,
      },

      config
    );

    dispatch(successNofit({ updateObsSuccessNotif: true }));
    setTimeout(() => {
      dispatch(successNofit({ updateObsSuccessNotif: false }));
    }, 7000);

    dispatch({
      type: UPDATE_OBSERVATION_SUCCESS,
      payload: data.message,
    });
  } catch (error) {
    console.log(error);
    dispatch(ErrorNofit({ updateObsErrorNotif: true }));
    setTimeout(() => {
      dispatch(ErrorNofit({ updateObsErrorNotif: false }));
    }, 7000);
    dispatch({
      type: UPDATE_OBSERVATION_FAILD,
      payload: error.message,
    });
  }
};
export const deleteObservaion = (obsId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_OBSERVATION_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    const { data } = await axios.delete(
      API + `api/observation/delete?id=${obsId}`,
      // {
      //   projectId,

      // },
      config
    );

    // dispatch(refetchObs({ refetchObsValue: true }));
    dispatch(singleObservation({ singleObsId: obsId }));
    dispatch(successNofit({ deleteObsSuccessNotif: true }));
    setTimeout(() => {
      dispatch(successNofit({ deleteObsSuccessNotif: false }));
    }, 7000);
    dispatch({
      type: DELETE_OBSERVATION_SUCCESS,
      payload: data.message,
    });
  } catch (error) {
    console.log(error);
    dispatch(ErrorNofit({ deleteObsErrorNotif: true }));
    setTimeout(() => {
      dispatch(ErrorNofit({ deleteObsErrorNotif: false }));
    }, 7000);
    dispatch({
      type: DELETE_OBSERVATION_FAILD,
      payload: error.message,
    });
  }
};

// Validate unvalidated observations
export const validateUnValidatedObservations =
  (observationsList) => async (dispatch, getState) => {
    try {
      dispatch({
        type: VALIDATE_UNVALIDATE_OBSERVATIONS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.data.token}`,
        },
      };

      const { data } = await axios.put(
        API + `api/ArrayObservation/validated`,
        {
          observations: observationsList,
        },
        config
      );

      dispatch(successNofit({ validateObsSuccessNotif: true }));
      setTimeout(() => {
        dispatch(successNofit({ validateObsSuccessNotif: false }));
      }, 7000);

      dispatch({
        type: VALIDATE_UNVALIDATE_OBSERVATIONS_SUCCESS,
        payload: data.message,
      });
    } catch (error) {
      // console.log(error);
      dispatch(ErrorNofit({ validateObsErrorNotif: true }));
      setTimeout(() => {
        dispatch(ErrorNofit({ validateObsErrorNotif: false }));
      }, 7000);
      dispatch({
        type: VALIDATE_UNVALIDATE_OBSERVATIONS_FAILD,
        payload: error.message,
      });
    }
  };
// delete list of obserations
export const deleteListOfObservations =
  (observationsList) => async (dispatch, getState) => {
    try {
      dispatch({
        type: DELETE_LIST_OF_OBSERVATION_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.data.token}`,
        },
      };

      const { data } = await axios.post(
        API + `api/ArrayObservation/delete`,
        {
          observations: observationsList,
        },
        config
      );

      dispatch(successNofit({ deleteObsSuccessNotif: true }));
      setTimeout(() => {
        dispatch(successNofit({ deleteObsSuccessNotif: false }));
      }, 7000);

      dispatch({
        type: DELETE_LIST_OF_OBSERVATION_SUCCESS,
        payload: data.message,
      });
    } catch (error) {
      console.log(error);
      dispatch(ErrorNofit({ deleteObsErrorNotif: true }));
      setTimeout(() => {
        dispatch(ErrorNofit({ deleteObsErrorNotif: false }));
      }, 7000);
      dispatch({
        type: DELETE_LIST_OF_OBSERVATION_FAILD,
        payload: error.message,
      });
    }
  };

// Upload list of obserations to iNaturalist

export const uploadListOfObservationsToInaturalist =
  (observationsList, rowData) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.data.token}`,
        },
      };

      dispatch({ type: UPLOAD_OBSERVATION_TO_INATURALIST_REQUEST });

      for (const [index, observation] of observationsList.entries()) {
        try {
          const { data } = await axios.post(
            API + `api/observation/iNaturalist`,
            { observations: observation },
            config
          );

          dispatch({
            type: UPLOAD_OBS_PROGRESS,
            payload: { progressArray: [index + 1, observationsList.length] },
          });

          toast.success(
            `Observation with ID ${observation} uploaded successfully`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          const updatedObservation = rowData?.find(
            (obs) => obs.id === observation
          );
          if (updatedObservation) {
            updatedObservation.idInaturalist = data;
            dispatch(updateObservationLocally(updatedObservation));
          }
          console.log(rowData, "rowData");
          console.log(data, "Inat upload success");
        } catch (error) {
          dispatch({
            type: UPLOAD_OBS_PROGRESS,
            payload: { progressArray: [index + 1, observationsList.length] },
          });

          toast.error(`Observation with ID ${observation} failed`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          console.log(error, "iNat upload error");
          dispatch({
            type: UPLOAD_OBSERVATION_TO_INATURALIST_FAILD,
            payload: error.message,
          });
        }
      }

      dispatch({
        type: UPLOAD_OBSERVATION_TO_INATURALIST_SUCCESS,
        payload: "All observations uploaded successfully",
      });
      dispatch(successNofit({ uploadSuccessNotif: true }));
      setTimeout(() => {
        dispatch(successNofit({ uploadSuccessNotif: false }));
      }, 7000);
    } catch (error) {
      console.log(error);
      dispatch(ErrorNofit({ uploadObsEror: true }));
      setTimeout(() => {
        dispatch(ErrorNofit({ uploadObsEror: false }));
      }, 7000);
      dispatch({
        type: UPLOAD_OBSERVATION_TO_INATURALIST_FAILD,
        payload: "Failed to upload observations",
      });
    }
  };

// delete single image
export const deleteSingleImage = (deleteImg) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_OBSERVATION_IMAGE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };

    const { data } = await axios.post(
      API + `api/observationImage/delete`,
      {
        id: deleteImg.id,
        link: deleteImg.link,
      },
      config
    );

    dispatch(successNofit({ deleteObsImageNotif: true }));
    setTimeout(() => {
      dispatch(successNofit({ deleteObsImageNotif: false }));
    }, 7000);
    dispatch({
      type: DELETE_OBSERVATION_IMAGE_SUCCESS,
      payload: data.message,
    });
  } catch (error) {
    dispatch(ErrorNofit({ errorNotif: true }));
    setTimeout(() => {
      dispatch(ErrorNofit({ errorNotif: false }));
    }, 7000);
    dispatch({
      type: DELETE_OBSERVATION_IMAGE_FAILD,
      payload: error.message,
    });
  }
};

// export const setData = (data) => ({ type: SET_DATA, payload: data });
export const setData = (data) => (dispatch) => {
  dispatch({
    type: SET_DATA,
    payload: data,
  });
};
// change map marker
export const changeMarker = (obsId) => (dispatch) => {
  dispatch({
    type: CHANGE_MARKER,
    payload: obsId,
  });
};

// open map marker
export const openPopup = (obsId) => (dispatch) => {
  dispatch({
    type: OPEN_POPUP,
    payload: obsId,
  });
};

// success notif
export const successNofit = (message) => (dispatch) => {
  dispatch({
    type: SUCCESS_NOTIFICATION,
    payload: message,
  });
};
// error notif
export const ErrorNofit = (message) => (dispatch) => {
  dispatch({
    type: ERROR_NOTIFICATION,
    payload: message,
  });
};

// set Project role
export const userProjectRoleAction = (role) => (dispatch) => {
  dispatch({
    type: SET_USER_ROLE_ON_PROJECT,
    payload: role,
  });
};

// refetchObs
export const refetchObs = (refetch) => (dispatch) => {
  dispatch({
    type: REFETCH_OBS_LIST,
    payload: refetch,
  });
};

// update project Locally
export const updateObservationLocally = (obs) => (dispatch) => {
  dispatch({
    type: UPDATE_OBSERVATION_LOCALY,
    payload: obs,
  });
};

// create project Locally
export const createObservationLocally = (obs) => (dispatch) => {
  dispatch({
    type: CREATE_OBS_AND_STORE_LOCALLY,
    payload: obs,
  });
};

// new obs id
export const newObservationId = (obsId) => (dispatch) => {
  dispatch({
    type: NEW_OBS_ID,
    payload: obsId,
  });
};

// delete project Locally
export const deleteObservationLocally = (obsId) => (dispatch) => {
  dispatch({
    type: DELETE_OBS_LOCALLY,
    payload: obsId,
  });
};

// Validate project Locally
export const validatObservationLocally = (obsId) => (dispatch) => {
  dispatch({
    type: ValIDATE_OBS_LOCALLY,
    payload: obsId,
  });
};
// get map zoom
export const mapZoomLevelAction = (zoom) => (dispatch) => {
  dispatch({
    type: MAP_ZOOM_LEVEL,
    payload: zoom,
  });
};

// get obs tpes liste
export const observationTypesAction = (obs) => (dispatch) => {
  dispatch({
    type: OBSERVATIONS_TYPES,
    payload: obs,
  });
};
// get dates rages
export const datesRangesActions = (dates) => (dispatch) => {
  dispatch({
    type: DATE_RANGE,
    payload: dates,
  });
};
// get single observation
export const singleObservation = (obs) => (dispatch) => {
  dispatch({
    type: SINGLE_OBSERVATION,
    payload: obs,
  });
};

// get single observation
export const panTracker = (value) => (dispatch) => {
  dispatch({
    type: PAN_TRAKER,
    payload: value,
  });
};

// get single observation
export const createSiteSegment = (value) => (dispatch) => {
  dispatch({
    type: SITE_SEGMENT,
    payload: value,
  });
};
export const editSiteSegment = (value) => (dispatch) => {
  dispatch({
    type: EDIT_SITE_SEGMENT,
    payload: value,
  });
};
export const uploadObsProgress = (value) => (dispatch) => {
  dispatch({
    type: UPLOAD_OBS_PROGRESS,

    payload: value,
  });
};

export const addQuestionData = (value) => (dispatch) => {
  dispatch({
    type: ADD_QUESTION_DATA,
    payload: value,
  });
};
export const deleteQuestion = (value) => (dispatch) => {
  dispatch({
    type: DELETE_QUESTION,
    payload: value,
  });
};
