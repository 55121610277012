import { useState } from "react";
import DataExportModal from "./dataexportmodal";
import "../projectcontent.css";
import "./dataexport.css";
import { useTranslation } from "react-i18next";

const DataExport = ({ user, project }) => {
  const { t } = useTranslation();
  const [showDataExportModal, setShowDataExportModal] = useState(false);

  const handleHideModal = () => {
    setShowDataExportModal(false);
  };

  return (
    <>
      <button
        type="button"
        className="export-btn admin-btn-dark btn-logout btn mt-4"
        onClick={() => setShowDataExportModal(true)}
      >
        {t("admin.exportData.exportDataText")}
      </button>
      {showDataExportModal && (
        <DataExportModal
          user={user}
          project={project}
          closeDataExportModal={handleHideModal}
        />
      )}
    </>
  );
};

export default DataExport;
