import React from "react";
import { motion } from "framer-motion";
import animal from "../../assets/mapMarkrers/animal.svg";
import crawl from "../../assets/mapMarkrers/crawl.svg";
import fEncounter from "../../assets/mapMarkrers/fencounter.svg";
import flash from "../../assets/mapMarkrers/flash.svg";
import Nest from "../../assets/mapMarkrers/nest.svg";
import Other from "../../assets/mapMarkrers/others.svg";
import sofPresence from "../../assets/mapMarkrers/presence.svg";
import threat from "../../assets/mapMarkrers/treath.svg";
function Legend({ hideLegend, legendPostion }) {
  return (
    <>
      <div
        className="position-absolute legend "
        style={{ zIndex: "9999", left: `${legendPostion}px` }}
      >
        <div className="legend-close text-end">
          <span
            className="pe-sm-2 pointer"
            onClick={() => {
              hideLegend(false);
            }}
            style={{}}
          >
            <img
              src="assets/elements/images/cross.png"
              alt=""
              style={{ height: "13px" }}
            />
          </span>
        </div>
        <div
          className="px-4"
          style={{
            marginTop: "30px",
          }}
        >
          <div className="title-men p-2">Legend</div>
          <div className="pl-4 mt-2">
            <span className="d-block pt-2 pb-2">
              <span className="ms-4 me-sm-1">
                <img src={animal} alt="" className="filtre" />
              </span>{" "}
              Animal
            </span>
            <span className="d-block pt-2 pb-2">
              <span className="ms-4 me-sm-1">
                <img src={crawl} alt="" className="filtre" />
              </span>{" "}
              Crawl
            </span>
            <span className="d-block pt-2 pb-2">
              <span className="ms-4 me-sm-1">
                <img src={fEncounter} alt="" className="filtre" />
              </span>{" "}
              Female Encounter
            </span>
            <span className="d-block pt-2 pb-2">
              <span className="ms-4 me-sm-1">
                <img src={flash} alt="" className="filtre" />
              </span>{" "}
              Flash
            </span>
            <span className="d-block pt-2 pb-2">
              <span className="ms-4 me-sm-1">
                <img src={Nest} alt="" className="filtre" />
              </span>{" "}
              Nest: New
            </span>
            <span className="d-block pt-2 pb-2">
              <span className="ms-4 me-sm-1">
                <img src={Other} alt="" className="filtre" />
              </span>{" "}
              Other
            </span>
            <span className="d-block pt-2 pb-2">
              <span className="ms-4 me-sm-1">
                <img src={sofPresence} alt="" className="filtre" />
              </span>{" "}
              Sign of Presence
            </span>
            <span className="d-block pt-2 pb-2">
              <span className="ms-4 me-sm-1">
                <img src={threat} alt="" className="filtre" />
              </span>{" "}
              Threat
            </span>
            <div
              style={{
                height: "20px",
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Legend;
