import React, { useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import { useSelector } from "react-redux";

const ProgressBarComponent = () => {
  const [progressBarPercent, setProgressBarPercent] = useState(0);
  const uploadProgress = useSelector((state) => state.uploadProgressUpdateFunc);
  const { progressArray } = uploadProgress;

  useEffect(() => {
    if (progressArray) {
      const percentage = (progressArray[0] / progressArray[1]) * 100;
      setProgressBarPercent(percentage);
    }
  }, [progressArray]);

  return (
    <div
      className="progressBar_container "
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 9999,
        width: "100%",
        height: "30px",
      }}
    >
      {progressArray && (
        <ProgressBar
          now={progressBarPercent}
          label={`${[progressArray[0]]} / ${[
            progressArray[1],
          ]}  Uploading observations to iNaturalist `}
          // animated
          variant="custom"
          style={{
            backgroundColor: "#042b4a",
            color: "black", // Set text color to black for visibility
          }}
        />
      )}
    </div>
  );
};

export default ProgressBarComponent;
