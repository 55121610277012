import React, { useState } from "react";
import ViewPatrolModal from "../../modals/patrols/ViewPatrols";

const ID = ({ data }) => {
  const [showPatrolDetails, setShowPatrolDetails] = useState(false);
  const closeDetailModal = () => {
    setShowPatrolDetails(false);
  };

  return (
    <>
      <div
        className=""
        style={{
          marginLeft: "20px",
          fontWeight: "bold",
          color: "#075796",
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={() => {
          setShowPatrolDetails(true);
        }}
      >
        {data.id}
      </div>

      {showPatrolDetails && (
        <ViewPatrolModal
          patrolData={data}
          closeDetailModal={closeDetailModal}
          // openIsEditModal={openIsEditModal}
          // handleShowImageCarosel={handleShowImageCarosel}
        />
      )}
    </>
  );
};

export default ID;
