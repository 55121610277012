import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import carret from "../../assets/images/pointbas.png";
import { useState } from "react";
import GridTable from "@nadavshaar/react-grid-table";
import { useTranslation } from "react-i18next";
import GetQuestionsColumn from "./getQuestionsColumn";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const TableQuestions = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const questionsData = location.state?.questionsData;
  const [rowData, setRowsData] = useState([]);
  useEffect(() => {
    setRowsData(questionsData);
  }, [questionsData]);

  return (
    <>
      <div className="patrol__homePage">
        <Header />
        <div className="patrole__subHeader"></div>

        <GridTable
          enableColumnsReorder={false}
          columns={GetQuestionsColumn({ questionsData })}
          selectAllMode={"all"}
          icons={{
            sortAscending: (
              <img className="sortB rBottom" src={carret} alt="sortB" />
            ),
            sortDescending: <img className="sortB" src={carret} alt="sortB" />,
          }}
          texts={{
            search: `${t("gridTable.search")}`,
            totalRows: `${t("gridTable.totalRows")}`,
            rows: `${t("gridTable.rows")}`,
            selected: `${t("gridTable.selected")}`,
            rowsPerPage: `${t("gridTable.rowsPerPage")}`,
            page: "Page:",
            of: `${t("gridTable.of")}`,
            prev: `${t("gridTable.prev")}`,
            next: `${t("gridTable.next")}`,
          }}
          //   components={{
          //     Header: CustomHeader,
          //     Loader: TableLoader,
          //     NoResults: NoPatrolFound,
          //   }}
          showSearch={false}
          rows={questionsData}
        />
      </div>
    </>
  );
};

export default TableQuestions;
