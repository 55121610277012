import React, { useEffect, useState } from "react";
import position from "../../../assets/images/position.svg";
import { useRef } from "react";
import { Modal } from "react-bootstrap";
import { RxCross1, RxCross2 } from "react-icons/rx";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import { Switch, TimePicker } from "antd";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import plus from "../../../assets/images/plus.png";
import backArrow from "../../../assets/images/backArrow.png";

// Upload images import
import ImageUploading from "react-images-uploading";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  useFetchFamilyList,
  useFetchGroupList,
  useFetchObservaionTypeList,
  useFetchSeSpeciesList,
  useFetchSiteSgmntListByProject,
} from "../../../queries/useFetchOptions";
import {
  createObservaion,
  createObservationLocally,
} from "../../../actions/projectActions";
import {
  AiFillDelete,
  AiOutlineCalendar,
  AiOutlineQuestionCircle,
} from "react-icons/ai";

// form Validation imports
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { observationStyle } from "../../../utils/TypeObsStyle";
import { formatDate5 } from "../../../utils/DateFormater";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import unavailableMapPlaceHolder from "../../../assets/images/unavailableMapPlaceholder.svg";
import uploadImagePlaceHolder from "../../../assets/images/uploadImagePlaceholder.svg";
import ModalMarker from "../../map/leafletMap/ModalMarker";

import { useFetchUnvalidatedObservaionList } from "../../../queries/useMapQueriesHook";
import { useTranslation } from "react-i18next";

const format = "HH:mm";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

dayjs.extend(customParseFormat);

function SetMap({ location, lat, long, typeObservation }) {
  const map = useMap();
  if (true) {
    map.flyTo(location, 8);
  }

  return lat && long ? (
    <ModalMarker
      observation={null}
      lat={lat}
      long={long}
      typeObservation={typeObservation}
    />
  ) : null;
}

const CreateObservation = ({ handleHideModalCreateModal }) => {
  // Translation
  // Translate switcher
  const { t } = useTranslation();

  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;

  let today1 = new Date();
  var presentTime = today1.getHours() + ":" + today1.getMinutes();

  useEffect(() => {
    var x = document.getElementById("demo");
    function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        x.innerHTML = "Geolocation is not supported by this browser.";
      }
    }
    getLocation();
  }, []);

  // validation

  const validationSchema = yup.object().shape({
    // date: yup.string().required("required Field"),
    // time: yup.string().required("required Field"),
    coords: yup.string().required(`${t("required")}`),
    site: yup.string(),
    segment: yup.string(),
    observationType: yup.string().required(`${t("required")}`),
    groupe: yup.string().required(`${t("required")}`),
    family: yup.string().required(`${t("required")}`),
    specie: yup.string().required(`${t("required")}`),
  });

  const {
    register,

    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  // Get date and time

  const [currentTime, setCurrentTime] = useState(presentTime);
  const [seconds, setSeconds] = useState("");
  const onChangeTime = (time, timeString) => {
    setCurrentTime(timeString);
    setChangeTracker(true);
  };
  function getRandomNumber() {
    let seconds = Math.floor(Math.random() * 60)
      .toString()
      .padStart(2, "0");
    setSeconds(seconds);
  }
  useEffect(() => {
    getRandomNumber();
  }, []);

  const [segmentState, setSegmentState] = useState();
  const [siteState, setSiteState] = useState(null);
  const [obsTypeState, setObsTypeState] = useState();
  const [groupeState, setGroupState] = useState();
  const [familyState, setFamilyState] = useState();
  const [speciesListState, setSpeciesListState] = useState();

  // fetch options
  const { groupeList } = useFetchGroupList();
  const {
    speciesList,
    speciesLoading,
    speciesIsRefetching,
    refetchSpeciesList,
  } = useFetchSeSpeciesList(familyState);
  const {
    siteSegmentList,

    siteSegmentLoading,
    refetchSiteSegment,
    siteSegmentIsRefetching,
  } = useFetchSiteSgmntListByProject(localProjectId);
  const [customSegmentList, setCustomSegmentList] = useState(null);

  const handleSetSegmentList = (seletedSiteId) => {
    if (siteSegmentList) {
      siteSegmentList.data.map((site) => {
        if (site.siteId === seletedSiteId) {
          setCustomSegmentList(site.segment);
        }
        return site.segment;
      });
    }
  };

  useEffect(() => {
    if (localProjectId) {
      refetchSiteSegment();
    }
  }, [localProjectId, refetchSiteSegment]);

  const { observationList } = useFetchObservaionTypeList();

  const { familyList, familyListRefetch, familyLoading, familyIsRefetching } =
    useFetchFamilyList(groupeState);

  const [upDatedGroupeList, setUpDatedGroupeList] = useState([]);
  const [upDatedFamilyList, setUpDatedFamilyList] = useState([]);
  const [user, setuser] = useState();
  useEffect(() => {
    if (userInfo) {
      setuser(userInfo.data.email);
    }
  }, []);

  useEffect(() => {
    if (obsTypeState === "1" || obsTypeState === "2" || obsTypeState === "3") {
      if (groupeList) {
        // const newArray = groupeList.data.filter((item) =>
        //   ["1", "2", "3"].includes(item.id.toString())
        // );
        groupeList.data.map((item) => {
          if (item.id === 2) {
            setUpDatedGroupeList([item]);
          }
        });
      }
    } else {
      if (groupeList) {
        setUpDatedGroupeList(groupeList.data);
      }
    }
    if (
      groupeState === "2" &&
      (obsTypeState === "1" || obsTypeState === "2" || obsTypeState === "3")
    ) {
      if (familyList) {
        const newArray = familyList.data.filter((item) =>
          ["4"].includes(item.id.toString())
        );
        setUpDatedFamilyList(newArray);
      }
    } else if (familyList) {
      setUpDatedFamilyList(familyList.data);
    }
    // if (groupeState !== "2") {
    //   if (familyList) {
    //     setUpDatedFamilyList(familyList.data);
    //   }
    // }
  }, [groupeList, obsTypeState, groupeState, familyList]);

  const dispatch = useDispatch();

  // Upload images function

  const [showUploadUI, setShowUploadUI] = useState(false);
  const [images, setImages] = useState([]);
  const maxNumber = 4;
  const [imageData, setImageData] = useState({
    img1: "",
    img2: "",
    img3: "",
    img4: "",
  });

  const uploadImages = (imageList, addUpdateIndex) => {
    setChangeTracker(true);
    if (imageList.length === 1) {
      setImageData((prevImageData) => ({
        ...prevImageData,
        img1: imageList[0].data_url,
      }));
    } else if (imageList.length === 2) {
      setImageData((prevImageData) => ({
        ...prevImageData,
        img1: imageList[0].data_url,
        img2: imageList[1].data_url,
      }));
    } else if (imageList.length === 3) {
      setImageData((prevImageData) => ({
        ...prevImageData,
        img1: imageList[0].data_url,
        img2: imageList[1].data_url,
        img3: imageList[2].data_url,
      }));
    } else if (imageList.length === 4) {
      setImageData((prevImageData) => ({
        ...prevImageData,
        img1: imageList[0].data_url,
        img2: imageList[1].data_url,
        img3: imageList[2].data_url,
        img4: imageList[3].data_url,
      }));
    }

    setImages(imageList);
  };

  let currentProject = JSON.parse(localStorage.getItem("selectedProject"));
  // setSelectedProject(currentProject);

  useEffect(() => {
    if (groupeState) {
      familyListRefetch();
    }
    if (familyList && familyList.data.length !== 0) {
      setFamilyState(familyList.data[0].id);
    }
  }, [groupeState, familyList, familyListRefetch]);
  useEffect(() => {
    if (familyState) {
      refetchSpeciesList();
    }
  }, [familyState, groupeState, refetchSpeciesList]);
  const [displaySpecies, setDisplaySpecies] = useState();
  const [displayGroupe, setDisplayGroupe] = useState();

  const [displayFamily, setDisplayFamily] = useState();
  const [displaySegment, setDisplaySegment] = useState();
  const [displaySite, setDisplaySite] = useState();

  const handleSetGroupeName = (target) => {
    if (groupeList) {
      groupeList.data.map((s) => {
        if (Number(target) === s.id) {
          setDisplayGroupe(s.name);
        }
        return s.name;
      });
    }
  };

  const handleSetFamilyName = (target) => {
    if (familyList) {
      familyList.data.map((s) => {
        if (Number(target) === s.id) {
          setDisplayFamily(s.name);
        }
        return s.name;
      });
    }
  };

  const handleSetSpeciesName = (target) => {
    if (speciesList) {
      speciesList.data.map((s) => {
        if (Number(target) === s.id) {
          setDisplaySpecies(s.name);
        }
        return s.name;
      });
    }
  };

  const handleSetSiteName = (target) => {
    if (siteSegmentList) {
      siteSegmentList.data.map((s) => {
        if (Number(target) === s.siteId) {
          setDisplaySite(s.site);
        }
        return s.site;
      });
    }
  };

  const handleSetSegmentName = (target) => {
    if (customSegmentList) {
      customSegmentList.map((s) => {
        if (Number(target) === s.id) {
          setDisplaySegment(s.name);
        }
        return s.name;
      });
    }
  };

  //dead or alive  status change
  const [deadOrAlive, setDeadOrAlive] = useState(1);
  const [status, setStatus] = useState("Dead");
  const statusChange = (checked) => {
    setChangeTracker(true);
    if (checked) {
      setDeadOrAlive(0);
      setStatus("Alive");
    } else {
      setDeadOrAlive(1);

      setStatus("Dead");
    }
  };

  const onSubmit = (data) => {
    // setConfirmExit(false);
    const createObject = {
      projectId: currentProject && currentProject.id,
      segment: segmentState,
      site: siteState,
      obsType: obsTypeState,
      groupe: groupeState,
      family: familyState,
      species: speciesListState,
      time: currentTime + `:${seconds}`,
      coordX: newLocalLat,
      coordY: newLocalLong,
      date: convertedDate,
      imgs: imageData,
      dead: deadOrAlive,
    };

    // Local project update
    const timeStr = currentTime + `:${seconds}`;
    const dateStr = convertedDate;

    // Splitting time into hours, minutes, and seconds
    const [hours, minutes, secondss] = timeStr.split(":");

    // Splitting date into year, month, and day
    const [year, month, day] = dateStr.split("-");

    // Setting the desired timezone
    // Adjusting the UTC offset
    const dt = new Date(
      Date.UTC(year, month - 1, day, hours, minutes, secondss)
    );

    // Creating the final object
    const localdateObject = {
      date: dt.toISOString().slice(0, 23),
      timezone_type: 3,
      timezone: "UTC",
    };

    const updtedObservationData = {
      id: "",
      projectId: currentProject && currentProject.id,
      projectName: currentProject && currentProject.name,
      idInaturalist: null,
      coordX: newLocalLat,
      coordY: newLocalLong,
      note: "",
      alpha: "",
      collector: "",
      images: {
        img1: imageData.img1,
        img2: imageData.img2,
        img3: imageData.img3,
        img4: imageData.img4,
      },
      dead: deadOrAlive,
      status: 0,
      TypeObservationId: obsTypeState,
      TypeObservation: displayObserVationType,
      specieId: speciesListState,
      specie: displaySpecies,
      familyId: familyState,
      family: displayFamily,
      groupId: groupeState,
      group: displayGroupe,
      user: user,
      date: localdateObject,
      updatedate: null,
      segment: displaySegment,
      site: displaySite,
    };

    dispatch(createObservaion(createObject));
    dispatch(createObservationLocally(updtedObservationData));
    handleHideModalCreateModal();
  };

  const [localLatValidation, setLocalLatValidation] = useState(false);
  const [localObsTypeValidation, setLocalObsTypeValidation] = useState(false);
  const [localSpeciesState, setLocalSpeciesState] = useState(false);
  const [localGroupValidation, setLocalGroupValidation] = useState(false);
  const [localSiteValidation, setLocalSiteValidation] = useState(false);
  const [localFamilyValidation, setLocalFamilyValidation] = useState(false);
  const handleCreateObs = () => {
    if (
      newLocalLat &&
      newLocalLong &&
      obsTypeState &&
      speciesListState &&
      siteState
    ) {
      onSubmit();
    }
    if (!newLocalLat) {
      setLocalLatValidation(true);
    }
    if (!obsTypeState) {
      setLocalObsTypeValidation(true);
    }
    if (!speciesListState) {
      setLocalSpeciesState(true);
    }
    if (!groupeState) {
      setLocalGroupValidation(true);
    }
    if (!familyState) {
      setLocalFamilyValidation(true);
    }
    if (!siteState) {
      setLocalSiteValidation(true);
    }
    setConfirmExit(false);
    // setTimeout(() => {
    //   setSaveError(false);
    // }, 2000);
  };

  // exit box
  const [confirmExit, setConfirmExit] = useState(false);
  const handleExitEdit = () => {
    setConfirmExit(false);
    handleHideModalCreateModal();
  };

  // input tracker
  const [changeTracker, setChangeTracker] = useState(false);
  const handleShowConfirmModal = () => {
    if (changeTracker) {
      setConfirmExit(true);
    } else {
      handleExitEdit();
    }
  };

  // display observation type
  const [displayObserVationType, setDisplayObserVationType] = useState(null);
  const handleSetObservationType = (e) => {
    if (observationList) {
      for (let x of observationList) {
        if (x.id === e) {
          setDisplayObserVationType(x.name);
        }
      }
    }
  };

  // set coordinates

  const [newLocalLong, setNewLocalLong] = useState();
  const [newLocalLat, setNewLocalLat] = useState();
  function showPosition(position) {
    setNewLocalLat(position.coords.latitude);
    setNewLocalLong(position.coords.longitude);
  }
  useEffect(() => {
    function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        return "Geolocation is not supported by this browser.";
      }
    }
    getLocation();
  }, []);

  const cordRef = useRef();
  const [coordsPosition, setCoordsPosition] = useState();
  // const [coordsWidth, setCoordsWidth] = useState();
  useEffect(() => {
    if (cordRef) {
      setCoordsPosition(cordRef.current.offsetHeight);
      // setCoordsWidth(cordRef.current.offsetWidth);
    }
  }, []);
  const [showCoordsBox, setShowCoordsBox] = useState(false);
  const [newLat, setNewLat] = useState();
  const [newLong, setNewLong] = useState();

  const [isCoordsSet, setIsCoordsSet] = useState(true);
  useEffect(() => {
    if (newLat && newLong) {
      setIsCoordsSet(false);
    }
  }, [newLong, newLat]);

  const [isInvalidCoords, setIsInvalidCoords] = useState(false);
  const [coordsErrorMessage, setCoordsErrorMessage] = useState("");

  function LongRangeCheck(number) {
    if (Number(number) >= -180 && Number(number) <= 180) {
      // Number is within the range

      return true;
    } else {
      // Number is outside the range

      return false;
    }
  }
  function LatRangeCheck(number) {
    if (Number(number) >= -90 && Number(number) <= 90) {
      // Number is within the range
      // , 29.211659283924526

      return true;
    } else {
      // Number is outside the range

      return false;
    }
  }

  const handleSubmitCoords = () => {
    if (LongRangeCheck(newLong) && LatRangeCheck(newLat)) {
      setNewLocalLat(newLat);
      setNewLocalLong(newLong);
      // LatRangeCheck(newLat);

      setShowCoordsBox(!showCoordsBox);
      setIsCoordsSet(true);

      // hide error message if one

      // // setChangeTracker
      setChangeTracker(true);
      setIsInvalidCoords(false);
    }

    if (!LatRangeCheck(newLat) && !LongRangeCheck(newLong)) {
      setCoordsErrorMessage(" Long and Lat Values are invalid");

      setIsInvalidCoords(true);
    } else if (!LatRangeCheck(newLat)) {
      setCoordsErrorMessage("Latitude value is invalid");
      setIsInvalidCoords(true);
    } else if (!LongRangeCheck(newLong)) {
      setCoordsErrorMessage("Longitude value is invalid");
      setIsInvalidCoords(true);
    }
  };

  const [newDate, setNewDate] = useState(new Date());
  let convertedDate = formatDate5(newDate);

  return (
    <div className="mvp__modals">
      {/* {user && } */}

      <Modal
        show={true}
        onHide={handleHideModalCreateModal}
        backdrop="static"
        centered
        className="mx-auto mvp__modals create__modal"
        keyboard={false}
        size="lg"
        dialogClassName="modal-size"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header
          className="bgPrimary modal__header text-left  "
          style={{
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Modal.Title className="modal__header__title">
            {t("createObs")}
          </Modal.Title>
          <div className="">
            <span
              className="pointer"
              onClick={() => {
                // setSaveConfirmation(true);
                // setConfirmExit(true);
                handleShowConfirmModal();
              }}
            >
              <RxCross1 className="close-modal-icon" />
            </span>
          </div>
        </Modal.Header>

        {!showUploadUI ? (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className="modal-top"
                style={{ borderBottom: "0.3px solid #ebf0ec" }}
              >
                <div
                  className="modal-map"
                  style={{ width: "50%", height: "100%" }}
                >
                  {!newLocalLat && !newLocalLong ? (
                    <img
                      src={unavailableMapPlaceHolder}
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                      alt=""
                    />
                  ) : (
                    newLocalLat &&
                    newLocalLong && (
                      <MapContainer
                        center={[newLocalLat, newLocalLong]}
                        zoom={12}
                        scrollWheelZoom={true}
                        zoomControl={false}
                        layer-type="base"
                        style={{ width: "100%", height: "100%", zIndex: "1" }}
                      >
                        <TileLayer
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        />
                        {newLat && newLong && (
                          <SetMap
                            location={[newLat, newLong]}
                            lat={newLat}
                            long={newLong}
                            typeObservation={displayObserVationType}
                          />
                        )}

                        <ModalMarker
                          observation={null}
                          lat={newLocalLat}
                          long={newLocalLong}
                        />
                      </MapContainer>
                    )
                  )}
                </div>
                <div
                  className="modal-imgs "
                  style={{ width: "50%", height: "100%", position: "relative" }}
                >
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    {images.length === 0 ? (
                      <img
                        onClick={() => {
                          setShowUploadUI(!showUploadUI);
                        }}
                        src={uploadImagePlaceHolder}
                        style={{
                          height: "100%",
                          width: "100%",
                          cursor: "pointer",
                        }}
                        alt=""
                      />
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "relative",
                          backgroundImage: `url(${images[0].data_url})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: " center center",
                          backgroundSize: "cover",
                        }}
                      />
                    )}
                  </div>
                  {images && images.length !== 0 && (
                    <div
                      className="modal-photo-btn create"
                      onClick={() => {
                        setShowUploadUI(!showUploadUI);
                      }}
                    >
                      {images && images.length} + photos {">"}
                    </div>
                  )}
                </div>
              </div>

              <div className=" container-fluid modal__content">
                <div className="row ">
                  <div className="col-12 px-4 pt-1 ">
                    <span
                      className="f-bold me-2 single__obs__id"
                      // style={{ fontSize: "1.1rem" }}
                    >
                      Obs {currentProject && currentProject.id}
                    </span>
                    {displayObserVationType && (
                      <span
                        className=" p-1   d-inline obs__type "
                        // style={{ fontSize: ".6rem" }}
                        style={
                          displayObserVationType === "Threat" ||
                          displayObserVationType === "Menace"
                            ? observationStyle.Threat
                            : displayObserVationType === "Crawl" ||
                              displayObserVationType === "Trace"
                            ? observationStyle.Crawls
                            : displayObserVationType === "Animal"
                            ? observationStyle.Animal
                            : displayObserVationType === "Flash Observation" ||
                              displayObserVationType === "Observation flash"
                            ? observationStyle.FlashObs
                            : displayObserVationType === "Signs of presence" ||
                              displayObserVationType === "Signe de présence"
                            ? observationStyle.SignsPresence
                            : displayObserVationType === "Female Encounter" ||
                              displayObserVationType ===
                                "Rencontre tortue femelle"
                            ? observationStyle.FemaleEn
                            : displayObserVationType === "Nest" ||
                              displayObserVationType === "Nid"
                            ? observationStyle.Nest
                            : observationStyle.noStyle
                        }
                      >
                        {displayObserVationType}
                      </span>
                    )}
                  </div>
                  <div className="col-5 text-end"></div>
                </div>
                <div className="row px-3 modal__obs__details__row projectName">
                  <div className="col-5 modal__detail__title">
                    {t("projectName")}
                  </div>
                  <div className="col-7 modal__title__value author">
                    {currentProject && currentProject.name}
                  </div>
                </div>
                <div className="row px-3 modal__obs__details__row projectName">
                  <div className="col-5 modal__detail__title">
                    {t("author")}
                  </div>
                  <div className="col-7 modal__title__value author">
                    {currentProject && currentProject.user}
                  </div>
                </div>
                <div className="row px-3 modal__obs__details__row mt-1">
                  <div className="col-5 modal__detail__title">
                    {t("dateTime")}
                    <sup>*</sup>
                  </div>

                  <div className="col-7 modal__title__value d-flex">
                    <div className="d-flex  col-12">
                      <div
                        className="col-6 new__date_container"
                        style={{
                          width: "50%",
                        }}
                      >
                        <DatePicker
                          calendarIcon={<AiOutlineCalendar />}
                          clearIcon={null}
                          onChange={setNewDate}
                          calendarClassName="calender__picker"
                          className={`calender__picker`}
                          value={newDate}
                          required={true}
                          format={"y-MM-dd"}
                        />

                        <p className="error">{errors.date?.message}</p>
                      </div>

                      <div
                        className=" col-6 new__date_container"
                        style={{
                          width: "50%",
                        }}
                      >
                        <TimePicker
                          className="time-picker-input"
                          onChange={onChangeTime}
                          defaultValue={dayjs(`${currentTime}`, format)}
                          getPopupContainer={(triggerNode) => {
                            return triggerNode.parentNode;
                          }}
                          format={format}
                        />

                        <p className="error">{errors.time?.message}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row px-3 modal__obs__details__row mt-1">
                  <div className="col-5 modal__detail__title coords_mobile">
                    {t("coords")} <sup>*</sup>
                  </div>
                  <div
                    className="col-7 modal__title__value "
                    style={{
                      position: "relative",
                    }}
                  >
                    {/* new coords system */}
                    <div className="coords__wrapper__content">
                      <div
                        className="coords__wrapper "
                        ref={cordRef}
                        onClick={() => {
                          setShowCoordsBox(true);
                        }}
                      >
                        <div className="coords__container">
                          <div className="lat__container">
                            {newLocalLat && newLocalLat}
                          </div>
                          {newLocalLat ? <span>,</span> : ""}
                          <div className="log__container">
                            {newLocalLong && newLocalLong}
                          </div>
                        </div>

                        <div className="coords__marker">
                          <img
                            src={position}
                            alt=""
                            // width={11}
                            className="mt-1 me-1 "
                          />
                        </div>
                      </div>
                      {showCoordsBox && (
                        <div
                          className="coords__popup__wrapper"
                          style={{
                            // width: `${coordsWidth - 50}px`,
                            top: `${coordsPosition + 5}px`,
                          }}
                        >
                          {isInvalidCoords && (
                            <span
                              className="error"
                              style={{
                                position: "absolute",
                                left: "10px",
                              }}
                            >
                              {coordsErrorMessage}
                            </span>
                          )}
                          <div
                            className="coords__inputs"
                            style={
                              {
                                // width: `${coordsWidth - 50}px`,
                              }
                            }
                          >
                            <div className="coords__inputs__container">
                              <div>
                                <label>Latitude</label>
                                <input
                                  defaultValue={newLocalLat && newLocalLat}
                                  placeholder="XX.XXXXXX"
                                  type="number"
                                  style={{ fontSize: ".6rem" }}
                                  onChange={(e) => {
                                    setNewLat(e.target.value);
                                  }}
                                  step="any"
                                />
                              </div>
                              <div className="ms-2">
                                <label>Longitude</label>
                                <input
                                  style={{ fontSize: ".6rem" }}
                                  placeholder="XX.XXXXXX"
                                  defaultValue={newLocalLong && newLocalLong}
                                  type="number"
                                  step="any"
                                  onChange={(e) => {
                                    setNewLong(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="coords__btn__coords">
                              <div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    setShowCoordsBox(false);
                                    setIsInvalidCoords(false);
                                    setIsCoordsSet(true);
                                  }}
                                >
                                  {t("cancelBtn")}
                                </button>
                              </div>
                              <div>
                                <button
                                  className="save"
                                  type="button"
                                  onClick={() => {
                                    handleSubmitCoords();
                                  }}
                                  disabled={isCoordsSet}
                                >
                                  {t("saveBtn")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {localLatValidation && (
                      <p className="error">{t("required")}</p>
                    )}
                  </div>
                </div>
                <div className="row px-3 modal__obs__details__row mt-1">
                  <div className="col-5 modal__detail__title">
                    {t("site")}, {t("segment")}
                  </div>
                  <div className="col-7 modal__title__value d-flex">
                    <div className="col-6  me-1" style={{ width: "50%" }}>
                      <select
                        className={`crud_select crud-input ${
                          errors.site ? "is-invalid" : ""
                        }`}
                        name="site"
                        id="site"
                        {...register("site")}
                        onChange={(e) => {
                          setSiteState(e.target.value);
                          setLocalSiteValidation(false);
                          handleSetSiteName(e.target.value);
                          setChangeTracker(true);
                          handleSetSegmentList(e.target.value);
                          // setcustomSegmentList(s.segment);
                        }}
                        style={{
                          cursor: `${
                            siteSegmentLoading || siteSegmentIsRefetching
                              ? "not-allowed"
                              : "pointer"
                          }`,
                          width: "100%",
                        }}
                        disabled={
                          siteSegmentLoading || siteSegmentIsRefetching
                            ? true
                            : false
                        }
                      >
                        {siteSegmentLoading || siteSegmentLoading ? (
                          <option disabled selected value="">
                            Loading...
                          </option>
                        ) : (
                          <option disabled selected value="">
                            Site
                          </option>
                        )}

                        {siteSegmentList &&
                          siteSegmentList !== "undefined" &&
                          siteSegmentList.data.map((s, i) => (
                            <option value={s.siteId} key={i}>
                              {s.site}
                            </option>
                          ))}
                      </select>
                      <p className="error">
                        {errors.site?.message
                          ? errors.site?.message
                          : localSiteValidation
                          ? `${t("required")}`
                          : ""}
                      </p>
                    </div>
                    <div className="col-6 w-100" style={{ width: "52%" }}>
                      <select
                        // {...register("segment")}
                        className={`crud_select crud-input
                           
                         ${errors.site ? "is-invalid" : ""}
                          
                          `}
                        {...register("segment")}
                        id="segment"
                        name="segment"
                        style={{
                          cursor: `${
                            siteSegmentLoading || siteSegmentIsRefetching
                              ? "not-allowed"
                              : "pointer"
                          }`,
                          width: "50%",
                        }}
                        disabled={
                          siteSegmentLoading || siteSegmentIsRefetching
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setSegmentState(e.target.value);
                          setDisplaySegment(e.target.innerText);
                          handleSetSegmentName(e.target.value);
                          setChangeTracker(true);
                        }}
                      >
                        <option disabled selected value="">
                          Segment
                        </option>

                        {customSegmentList &&
                          customSegmentList.map((s, i) => (
                            <option value={s.id} key={i}>
                              {s.name}
                            </option>
                          ))}
                      </select>
                      <p className="error">{errors.segment?.message}</p>
                    </div>
                  </div>
                </div>
                <div className="row px-3 modal__obs__details__row mt-1">
                  <div className="col-5 modal__detail__title">
                    {t("obsType")} <sup>*</sup>
                  </div>
                  <div className="col-7 modal__title__value">
                    <select
                      {...register("observationType")}
                      placeholder="observation type"
                      id="observationType"
                      name="observationType"
                      className={`crud_select crud-input ${
                        errors.observationType ? "is-invalid" : ""
                      }`}
                      style={{ width: "101%" }}
                      onChange={(e) => {
                        setObsTypeState(e.target.value);
                        handleSetObservationType(e.target.value);
                        setLocalObsTypeValidation(false);
                        setChangeTracker(true);
                      }}
                    >
                      <option></option>
                      {observationList &&
                        observationList.map((s, i) => (
                          <option value={s.id} key={i}>
                            {s.name}
                          </option>
                        ))}
                    </select>

                    <p className="error">
                      {errors.observationType?.message
                        ? errors.observationType?.message
                        : localObsTypeValidation
                        ? `${t("required")}`
                        : ""}
                    </p>
                    {/* {localObsTypeValidation && (
                        <p className="error">This Field  is required</p>
                      )} */}
                  </div>
                </div>
                <div
                  style={{
                    borderBottom: ".5px solid #CCCCCC",
                  }}
                  className="row px-3   "
                >
                  <div
                    className="col-12 "
                    style={{
                      padding: "0px",
                      paddingLeft: "10px",
                      margin: "0px",
                    }}
                  >
                    <label className="modal__detail__title mb-1">
                      {t("group")} / {t("family")} / {t("species")} <sup>*</sup>
                    </label>
                    <div className=" species-select-container d-flex ">
                      <div className="me-2 species-select-item">
                        <select
                          {...register("groupe")}
                          placeholder="groupe"
                          name="groupe"
                          id="groupe"
                          onChange={(e) => {
                            setGroupState(e.target.value);
                            setLocalGroupValidation(false);
                            handleSetGroupeName(e.target.value);
                            // setDisplayGroupe(e.target.innerText);
                            setChangeTracker(true);
                          }}
                          className={`crud_select crud-input ${
                            errors.groupe ? "is-invalid" : ""
                          }`}
                        >
                          <option disabled selected value="">
                            {t("group")}
                          </option>
                          {/* groupeList */}

                          {upDatedGroupeList &&
                            upDatedGroupeList.map((s, i) => (
                              <option value={s.id} key={i}>
                                {s.name}
                              </option>
                            ))}
                        </select>

                        <p className="error">
                          {errors.groupe?.message
                            ? errors.groupe?.message
                            : localGroupValidation
                            ? `${t("required")}`
                            : ""}
                        </p>
                      </div>
                      <div className="me-2 species-select-item">
                        <select
                          {...register("family")}
                          onChange={(e) => {
                            setFamilyState(e.target.value);
                            setLocalFamilyValidation(false);
                            handleSetFamilyName(e.target.value);

                            // setDisplayFamily(e.target.innerText);
                            setChangeTracker(true);
                          }}
                          id="family"
                          name="family"
                          placeholder="family"
                          className={`crud_select crud-input ${
                            errors.family ? "is-invalid" : ""
                          }`}
                          style={{
                            cursor: `${
                              familyLoading || familyIsRefetching
                                ? "not-allowed"
                                : "pointer"
                            }`,
                          }}
                          disabled={
                            familyLoading || familyIsRefetching ? true : false
                          }
                        >
                          {familyLoading || familyIsRefetching ? (
                            <option disabled selected value="">
                              Loading...
                            </option>
                          ) : (
                            <option disabled selected value="">
                              {t("family")}
                            </option>
                          )}

                          {upDatedFamilyList &&
                            upDatedFamilyList.map((s, i) => (
                              <option value={s.id} key={i}>
                                {s.name}
                              </option>
                            ))}
                        </select>

                        <p className="error">
                          {errors.family?.message
                            ? errors.family?.message
                            : localFamilyValidation
                            ? `${t("required")}`
                            : ""}
                        </p>
                      </div>
                      <div className="me-2 species-select-item">
                        <select
                          {...register("specie")}
                          placeholder="specie"
                          onChange={(e) => {
                            setSpeciesListState(e.target.value);
                            setLocalSpeciesState(false);
                            handleSetSpeciesName(e.target.value);
                            // setDisplaySpecies(e.target.innerText);
                            setChangeTracker(true);
                          }}
                          id="specie"
                          name="specie"
                          className={`crud_select crud-input ${
                            errors.specie ? "is-invalid" : ""
                          }`}
                          style={{
                            cursor: `${
                              speciesLoading || speciesIsRefetching
                                ? "not-allowed"
                                : "pointer"
                            }`,
                          }}
                          disabled={
                            speciesLoading || speciesIsRefetching ? true : false
                          }
                        >
                          {speciesLoading || speciesIsRefetching ? (
                            <option disabled selected value="">
                              Loading...
                            </option>
                          ) : (
                            <option disabled selected value="">
                              {t("species")}
                            </option>
                          )}

                          {speciesList &&
                            speciesList.data.map((s, i) => (
                              <option value={s.id} key={i}>
                                {s.name}
                              </option>
                            ))}
                        </select>

                        <p className="error">
                          {errors.specie?.message
                            ? errors.specie?.message
                            : localSpeciesState
                            ? `${t("required")}`
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="dead-alive  d-flex  px-3 py-2"
                  style={{
                    justifyContent: "space-between",
                    fontSize: "14px",
                    color: "#595959",
                    marginBottom: "5px",
                    background: "#fff",
                  }}
                >
                  <div>{t("status")}</div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        marginRight: "15px",
                      }}
                    >
                      {status}
                    </div>
                    <Switch onChange={statusChange} />
                  </div>
                </div>
              </div>

              <div
                className="modal-footers"
                style={{
                  borderRadius: "8px",
                }}
              >
                <div
                  className="d-flex justify-content-end"
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    className="modal_obs__btn"
                    onClick={() => {
                      handleShowConfirmModal();
                    }}
                  >
                    {t("cancelBtn")}
                  </div>
                  <div
                    // type="submit"
                    className="modal_obs__btn validate"
                    onClick={() => handleCreateObs()}
                  >
                    {t("createBtn")}
                  </div>
                </div>
              </div>
            </form>
          </>
        ) : (
          <Modal.Body>
            <div className="p-2">
              <div className="create_image_modal">
                <div>
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={uploadImages}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["jpg", "jpeg", "png", "hevc", "heic", "gif"]}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <>
                        {/* Conditionally showing upload button */}
                        {images.length === 4 ? (
                          <div className="d-flex justify-content-between align-items-center mx-1">
                            <div
                              onClick={() => {
                                setShowUploadUI(!showUploadUI);
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <img src={backArrow} alt="back" />
                            </div>

                            <button
                              style={{
                                background: "#073B60",
                                opacity: "0.3",
                                padding: "10px 15px",
                                color: "white",
                                fontSize: ".8rem",
                                borderRadius: "10px",
                              }}
                            >
                              <img src={plus} alt="plus" className="me-2" />
                              {t("uploadNewPhoto")}
                            </button>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-between align-items-center mx-2">
                            <div
                              onClick={() => {
                                setShowUploadUI(!showUploadUI);
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <img src={backArrow} alt="back" />
                            </div>

                            <button
                              style={{
                                background: "#073b60",
                                fontSize: ".8rem",
                                padding: "10px 15px",
                                color: "white",
                                borderRadius: "10px",
                              }}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              <img src={plus} alt="plus" className="me-2" />
                              {t("uploadNewPhoto")}
                            </button>
                          </div>
                        )}

                        <div className="row d-flex gap-1 image__upload__container  ">
                          {imageList.map((image, index) => (
                            <div
                              key={index}
                              style={{
                                position: "relative",
                              }}
                              className="imageWrapper ms-2"
                            >
                              <div className="create__delete__img__icon__container ">
                                <AiFillDelete
                                  style={{
                                    position: "absolute",
                                    right: "5px",
                                    display: "block",
                                    top: "5px",
                                    zIndex: "9999",
                                    cursor: "pointer",

                                    opacity: "1",
                                  }}
                                  className="deleteIcon"
                                  onClick={() => onImageRemove(index)}
                                />
                              </div>
                              <div
                                className=" tableImage  details"
                                style={{
                                  backgroundImage: `url(${image.data_url})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                  cursor: "pointer",
                                  position: "relative",
                                }}
                              />
                            </div>
                            // </div>
                          ))}
                        </div>
                      </>
                    )}
                  </ImageUploading>
                </div>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>

      {/* {confirmExit && ( */}
      <div className="confrim__modal">
        <Modal
          show={confirmExit}
          //  onHide={handleHideConfirmationModal}
          backdrop="static"
          centered
          className="mx-auto confirmModal"
          keyboard={false}
          size="sm"
          dialogClassName="tailledeletemodal"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <div className=" text-end p-2 ">
            <span
              className="pointer "
              onClick={() => {
                setConfirmExit(false);
              }}
            >
              <RxCross2 className="close__icon" />
            </span>
          </div>

          <Modal.Body>
            <div className=" d-flex justify-content-center">
              <AiOutlineQuestionCircle
                style={{
                  fontSize: "3rem",
                  fontWeight: "bold",
                }}
                color="red"
              />
            </div>
            <div className="text-center h5 mt-3" style={{ color: " #404040" }}>
              {t("saveChanges")} ?
            </div>
            <div className="text-center mb-3" style={{ color: " #08253B" }}>
              {t("lostUnsaved")}
            </div>
          </Modal.Body>

          <div
            className="d-flex mt-3"
            style={{
              borderTop: ".5px solid #CCCCCC",
            }}
          >
            <div
              className="text-center p-2 fs-7 validbtn"
              style={{
                borderRight: ".5px solid #CCCCCC",
                width: "50%",
                cursor: "pointer",
                color: "#404040",
              }}
              onClick={() => {
                handleExitEdit();
              }}
            >
              {t("dontSave")}
            </div>
            <div
              className="p-2 text-center fs-7 validbtn"
              // type="submit"
              style={{
                width: "50%",
                color: `red`,
                cursor: "pointer",
              }}
              onClick={() => {
                handleCreateObs();
              }}
            >
              {t("saveChanges")}
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default CreateObservation;
