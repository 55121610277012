import React, { useEffect, useState } from "react";
import GridTable from "@nadavshaar/react-grid-table";
import "../style.css";
import carret from "../../../assets/images/pointbas.png";
import CustomHeader from "../../ListViewTable/custom/TableHeader";
import TableLoader from "../../ListViewTable/custom/TableLoader";

import { useTranslation } from "react-i18next";
import GetPatrolColumns from "./getPatrolsColumns";
import NoPatrolFound from "../../ListViewTable/custom/NoPatrolFound";

const PatrolTable = ({ handleCallback, patrolData, patrolListLoading }) => {
  const [selectedRows, setSelectedRows] = useState();
  const [rowData, setRowsData] = useState([]);
  useEffect(() => {
    setRowsData(patrolData);
  }, [patrolData]);

  useEffect(() => {
    handleCallback(selectedRows);
  }, [selectedRows, handleCallback]);

  // Translation

  const lang = localStorage.getItem("localLangVal");

  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  useEffect(() => {
    if (!lang || lang === "English") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("fr");
    }
  }, [lang, i18n]);
  // Listen for language change and update the state
  useEffect(() => {
    const handleLanguageChange = () => {
      setLanguage(i18n.language);
    };
    i18n.on("languageChanged", handleLanguageChange);
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);
  return (
    <div className="patrol_mainTable">
      <GridTable
        enableColumnsReorder={false}
        columns={GetPatrolColumns()}
        key={language}
        isLoading={patrolListLoading}
        selectAllMode={"all"}
        selectedRowsIds={selectedRows}
        onSelectedRowsChange={setSelectedRows}
        icons={{
          sortAscending: (
            <img className="sortB rBottom" src={carret} alt="sortB" />
          ),
          sortDescending: <img className="sortB" src={carret} alt="sortB" />,
        }}
        texts={{
          search: `${t("gridTable.search")}`,
          totalRows: `${t("gridTable.totalRows")}`,
          rows: `${t("gridTable.rows")}`,
          selected: `${t("gridTable.selected")}`,
          rowsPerPage: `${t("gridTable.rowsPerPage")}`,
          page: "Page:",
          of: `${t("gridTable.of")}`,
          prev: `${t("gridTable.prev")}`,
          next: `${t("gridTable.next")}`,
        }}
        components={{
          Header: CustomHeader,
          Loader: TableLoader,
          NoResults: NoPatrolFound,
        }}
        showSearch={false}
        rows={rowData}
      />
    </div>
  );
};

export default PatrolTable;
