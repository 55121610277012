import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { login } from "../../actions/userActions";
import Message from "../../components/LoadingError/Error";
import Loading from "../../components/LoadingError/Loading";
// Css import
import "./login.css";

// form Validation imports
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";

// Randomly renderin login images
const loginImagesPath = [
  `url(assets/elements/images/turtleCompressed.png)`,
  `url(assets/elements/images/SharkimageCompressed.png)`,
  `url(assets/elements/images/DolphinCompressed.png)`,
];
// mobile images imports

const loginImagesPathMoble = [
  `url(assets/elements/images/Shark-image-small-2.png)`,
  `url(assets/elements/images/Turtle-image-small.png)`,
  `url(assets/elements/images/Dolphin-image-small-3.png)`,
];

let imagePathId = Math.floor(Math.random() * 3);
const mystyle = {
  background: loginImagesPath[imagePathId],
  // backgroundSize: "cover",
  backgroundSize: " 100% 100%",
  backgroundRepeat: "no-repeat",
};
const mystyleMobile = {
  background: loginImagesPathMoble[imagePathId],
  // backgroundSize: "cover",
  backgroundSize: " 100% 100%",
  backgroundRepeat: "no-repeat",
};

const Login = ({ history }) => {
  // Translation
  // Translate switcher
  const { t, i18n } = useTranslation();

  const lang = localStorage.getItem("localLangVal");
  useEffect(() => {
    if (!lang || lang === "English") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("fr");
    }
  }, [lang, i18n]);

  const [show, setShow] = useState("false");

  const handleShow = () => {
    setShow(!show);
  };

  const dispatch = useDispatch();

  //get user from state
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, error, loading } = userLogin;
  // const navigate = useNavigate();
  useEffect(() => {
    //if user is logged
    if (userInfo) {
      history.push("/map");
      // navigate("/map");
    }
  }, [history, userInfo]);

  // Form inputs validation using react hook forms to handle the form and yup for the validation
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email()
      .required(`${t("required")}`),
    password: yup.string().required(`${t("required")}`),
  });

  const {
    register,

    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    dispatch(login(data.email, data.password));
  };

  return (
    <div className="container-fluid p-0 login-page">
      <div className="row header bg-cl-bl d-md-none d-lg-none d-sm-none ">
        <div className="col-xs-12 text-center">
          <Link to={"/"}>
            <img
              src="assets/elements/images/logo/logo.png"
              alt=""
              className="logo"
            />
          </Link>
        </div>
      </div>
      <div
        className="row header-img bg-cl-bl d-md-none d-lg-none d-sm-none "
        style={mystyleMobile}
      ></div>

      <div className="row  row-cols-2,row-cols-3, auto,justify-content-md-center vh-100">
        <div className="col-md-6 col-sm-9 ">
          <div className="row d-none d-sm-block p-3">
            <Link to={"/"}>
              <img
                src="assets/elements/images/logo/logos.png"
                alt=""
                className="logos"
              />
            </Link>
          </div>
          <div className="row mt-1 " style={{ height: "80%" }}>
            {/* translate switcher */}

            <div className="col-md-8 offset-md-2 align-self-center">
              <div className="mb-1">
                <div className="mb-0 h3 f-bold mx-2">{t("welcome")}</div>
              </div>
              <div>
                {error && (
                  <Message variant="alert-danger" dynamicClass="login__error">
                    {error}
                  </Message>
                )}
                <div className="loadingContainer">{loading && <Loading />}</div>

                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="mx-2 auth_forms"
                >
                  <div
                    className="mt-1 mb-1  position-relative "
                    style={{ height: "80px" }}
                  >
                    <label>Email</label>
                    <br />
                    <input
                      type="text"
                      name="email"
                      placeholder={`${t("mailPlaceholder")}`}
                      // className="w-100 emailtxt"
                      className={`w-100  form-control  ${
                        errors.email ? "is-invalid" : ""
                      }`}
                      {...register("email")}
                    />

                    {/* <div className="valid-feedback">Looks good!</div> */}
                    <p className="error">{errors.email?.message}</p>
                    {/* <div className="fs-11 error mailerror d-none">
                      
                    </div> */}
                  </div>
                  <div
                    className=" position-relative"
                    style={{ height: "80px" }}
                  >
                    <label>{t("password")}</label>
                    <br />
                    <input
                      type={show ? "password" : "text"}
                      name="password"
                      placeholder={`${t("passPlaceholder")}`}
                      // className="w-100 rounded password"
                      className={`w-100 rounded password form-control ${
                        errors.email ? "is-invalid" : ""
                      }`}
                      {...register("password")}
                    />
                    <p className="error">{errors.password?.message}</p>
                    <label onClick={handleShow}>
                      {show ? (
                        <img
                          src="assets/elements/images/icon/eyesc.svg"
                          alt=""
                          className="d-inline-block eye-close pointer"
                        />
                      ) : (
                        <img
                          src="assets/elements/images/icon/eyes.svg"
                          alt=""
                          className="d-inline-block eye-close pointer"
                        />
                      )}
                    </label>
                    {/* <div className="fs-11 error passworderror d-none">
                      Password entered is incorrect
                    </div> */}
                  </div>
                  <div className="position-relative fs-16 rememberMe mt-2">
                    <div className="mt-2">
                      <input
                        type="checkbox"
                        id="Remember"
                        name="subscribe"
                        value="Remember"
                        className="pointer me-2 "
                      />
                      <label
                        htmlFor="Remember"
                        style={{ color: "#595959" }}
                        className="pointer"
                      >
                        {t("rememberMe")}
                      </label>
                    </div>
                    <span className="forgotpass ">
                      <Link className="lien noDeco" to={"/forgottenPassword"}>
                        {t("forgotPassword")}
                      </Link>
                    </span>
                  </div>
                  <button
                    disabled={!isDirty || !isValid}
                    type="submit"
                    className=" btn-block w-100 mt-4 login-btn"
                  >
                    {t("login")}
                  </button>
                  <div className="text-center mt-3">
                    {t("noAccount")}? <br />{" "}
                    <Link className="lien" to={`/register`}>
                      {t("getAccess")}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* <div className="h-70"></div> */}
        </div>
        <div
          className="col-md-6 col-sm-3 d-none d-sm-block login-image"
          style={mystyle}
        ></div>
      </div>
    </div>
  );
};

export default Login;
