import React from "react";
import success from "../../assets/images/success.svg";
import "./style.css";
const CustomSuccess = ({ message }) => {
  return (
    <div>
      <div className="custom-success-alert">
        <div className="ms-5">
          <span>
            <img
              src={success}
              alt="success"
              width={"30px"}
              height={"30px"}
              style={{ marginRight: "10px" }}
            />
          </span>
          <span>{message}</span>
        </div>
      </div>
    </div>
  );
};

export default CustomSuccess;
