import React from "react";
import "./projectcontent.css";
const ReadSiteSegments = ({ siteSegments }) => {
  return (
    <div className="row pb-3  mt-4">
      <div className="col-md-4 offset-md-1 newpromt mt-3 pt-1">
        <div className="mt-1 mb-2  position-relative">
          <label className="site_lable pb-1">Site</label>
          <br />
          <div className="site__field_wrapper">
            <div className="site__input">
              <div>{siteSegments && siteSegments?.site}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-5  newpromt mt-0 ">
        <div className="mt-2 mb-2  position-relative">
          <label className="site_lable">Segments</label>
          <br />

          <div
            className={`w-100 segment_feild read`}
            style={{
              borderBottom: "0.5px solid #cccccc",
              justifyContent: "start",
              flexWrap: "wrap",
            }}
          >
            {siteSegments &&
              siteSegments?.segment?.map((seg) => (
                <div
                  className={`added__segment 
         `}
                >
                  <div>{seg.name}</div>{" "}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadSiteSegments;
