import React from "react";
import { observationStyle } from "../../../utils/TypeObsStyle";
import { t } from "i18next";
import { useTranslateObsType } from "../../../utils/useTranslateObservationType";
const styles = {
  img: { width: 50, height: 50 },
  input: {
    position: "absolute",
    height: 28,
    width: "calc(100% - 82px)",
    top: 10,
    right: 20,
    bottom: 0,
    border: "none",
    borderBottom: "1px solid #eee",
    outline: "none",
    fontSize: 16,
    padding: 0,
    fontFamily: "inherit",
  },
  Crawls: {
    marginLeft: 5,
    background: "#C8F0FF",
    borderRadius: "20px",
    fontSize: "12px",
    color: "black",
    fontWeight: "bold",
    padding: "3px 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  noStyle: {
    marginLeft: 5,

    borderRadius: "20px",
    fontSize: "12px",
    fontWeight: "bold",
    padding: "3px 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  Threat: {
    marginLeft: 5,
    background: "red",
    borderRadius: "20px",
    fontSize: "12px",
    fontWeight: "bold",
    color: "white",
    padding: "3px 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  Animal: {
    marginLeft: 5,
    background: "black",
    borderRadius: "20px",
    fontSize: "12px",
    fontWeight: "bold",
    color: "white",
    padding: "3px 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  FlashObs: {
    marginLeft: 5,
    background: "#00D4E2",
    borderRadius: "20px",
    fontSize: "12px",
    fontWeight: "bold",
    color: "black",
    padding: "3px 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  SignsPresence: {
    marginLeft: 5,
    background: "#3A8AC9",
    borderRadius: "20px",
    fontSize: "12px",
    fontWeight: "bold",
    color: "white",
    padding: "3px 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  Nest: {
    marginLeft: 5,
    background: "#22A6C1",
    borderRadius: "20px",
    fontSize: "12px",
    fontWeight: "bold",
    color: "white",
    padding: "3px 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  FemaleEn: {
    marginLeft: 5,
    background: "#20638D",
    borderRadius: "20px",
    fontSize: "12px",
    fontWeight: "bold",
    color: "white",
    padding: "3px 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};
const ObserVationType = ({
  tableManager,
  value,
  onChange,
  isEdit,
  data,
  column,
  rowIndex,
  searchText,
  isFirstEditableCell,
}) => {
  const translatedObsType = useTranslateObsType(value);

  return (
    <div>
      {isEdit ? (
        <React.Fragment>
          <img style={styles.img} src={data.avatar} alt="avatar" />
          <input
            autoFocus={isFirstEditableCell}
            style={styles.input}
            type="text"
            value={value}
            className=""
            onChange={(e) =>
              onChange({ ...data, [column.field]: e.target.value })
            }
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <img style={styles.img} src={data.img1} alt="avatar" /> */}
          <span
            style={
              value === "Threat"
                ? observationStyle.Threat
                : value === "Crawl"
                ? observationStyle.Crawls
                : value === "Animal"
                ? observationStyle.Animal
                : value === "Flash Observation"
                ? observationStyle.FlashObs
                : value === "Signs of presence"
                ? observationStyle.SignsPresence
                : value === "Female Encounter"
                ? observationStyle.FemaleEn
                : value === "Nest"
                ? observationStyle.Nest
                : observationStyle.noStyle
              //  styles.noStyle
            }
          >
            {translatedObsType}
          </span>
        </React.Fragment>
      )}
    </div>
  );
};

export default ObserVationType;
