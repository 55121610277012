import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  userLoginReducer,
  userListReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateReducer,
  userResetPasswordReducer,
  userForgotPasswordReducer,
  userConfirmRegistrationReducer,
  removeUserReducer,
} from "./reducers/userReducers";

import {
  ProjectIdreducer,
  addQuestionDataReducer,
  changeMarkerReducer,
  createObservationLocallyReducer,
  createObservationReducer,
  datesRangesReducer,
  deleteListOfObservationsRducer,
  deleteObservationLocallyReducer,
  deleteObservationReducer,
  deleteQuestionReducer,
  deleteSigleImageRducer,
  editObservationReducer,
  editSiteSegmentReducer,
  errorNotifReducer,
  mapZoomReducer,
  newObservationIdReducer,
  observationTypesReducer,
  openPopupReducer,
  panTrackerReducer,
  projectListReducer,
  projectObservationMapReducer,
  projectObservationReducer,
  refetchObsReducer,
  setUserReducer,
  singleObservationReducer,
  // siteSegmentComponentListReducer,
  siteSegmentReducer,
  successNotifReducer,
  unvalidatedProjectObservationReducer,
  updateObservationLocallyReducer,
  uploadObsToInaturalistRducer,
  uploadProgressReducer,
  userProjectRoleReducer,
  validateObservationLocallyReducer,
  validateUnValidatedObservationsReducer,
} from "./reducers/projectReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userList: userListReducer,
  userRegister: userRegisterReducer,
  userConfrimAccont: userConfirmRegistrationReducer,
  userDetails: userDetailsReducer,
  userUpdate: userUpdateReducer,
  userResetPassword: userResetPasswordReducer,
  userForgotPassword: userForgotPasswordReducer,
  projectList: projectListReducer,
  projectObservation: projectObservationReducer,
  mapValideObservation: projectObservationMapReducer,
  unvalidateProjectObservation: unvalidatedProjectObservationReducer,
  validateUnvalidatedObservations: validateUnValidatedObservationsReducer,
  deleteNumerousObservation: deleteListOfObservationsRducer,
  uploadObsToInaturalist: uploadObsToInaturalistRducer,
  createObservation: createObservationReducer,
  editeObservation: editObservationReducer,
  deleteObservation: deleteObservationReducer,
  deleteImage: deleteSigleImageRducer,
  projectId: ProjectIdreducer,
  insertUserUpdate: setUserReducer,
  changeSelectedMarker: changeMarkerReducer,
  openPopup: openPopupReducer,
  setSuccessMessage: successNotifReducer,
  setErrorMessage: errorNotifReducer,
  setRefetchObsList: refetchObsReducer,
  userRoleOnParticularProject: userProjectRoleReducer,
  // Locally update an observation
  updateProjectLocally: updateObservationLocallyReducer,
  deleteProjectLocally: deleteObservationLocallyReducer,
  valideProjectLocally: validateObservationLocallyReducer,
  createObsStoreLocally: createObservationLocallyReducer,
  newCreatedObservationId: newObservationIdReducer,
  observationTypesList: observationTypesReducer,
  dateRangeStoreFunction: datesRangesReducer,
  singleObsData: singleObservationReducer,
  panTrackerFunction: panTrackerReducer,
  removeUserTracker: removeUserReducer,
  siteSegentFunction: siteSegmentReducer,
  editSiteSegentFunction: editSiteSegmentReducer,
  uploadProgressUpdateFunc: uploadProgressReducer,
  addQuestionData: addQuestionDataReducer,
  deleteQuestion: deleteQuestionReducer,
  // siteSegmentList: siteSegmentComponentListReducer,

  // Map zoom
  getMapzoomState: mapZoomReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
