import React from "react";
import LatestNewsCard from "../LatestNewsCard";
import SignUpBtn from "../SignUpBtn";
// import latestNewsCards from "../Data/latestNewsCards";
import "./styles.css";
import { useTranslation } from "react-i18next";

export default function LatestNews() {
  const { t } = useTranslation();

  const latestNewsCards = [
    {
      img: "assets/caseStudyImages/IMG-20240318-WA0003.jpg",
      title: `${t("landingPage.content.latestNews.cards.card1.title")}`,
      text: `${t("landingPage.content.latestNews.cards.card1.details")} `,
      caseStudyLink: `case-studies/protecting-critically-endangered-hawksbill-turtles-in-the-middle-east-gulf`,
      externalLink: false,
    },
    {
      img: "assets/ghofrane.jpg",
      title: `${t("landingPage.content.latestNews.cards.card2.title")}`,
      text: `${t("landingPage.content.latestNews.cards.card2.details")}`,
      caseStudyLink: `/case-studies/conserving-critically-endangered-sharks-and-rays-in-west-africa`,
      externalLink: false,
    },
    {
      img: "assets/grandDauphin.jpg",
      title: `${t("landingPage.content.latestNews.cards.card3.title")}`,
      text: `${t("landingPage.content.latestNews.cards.card3.details")}`,
      caseStudyLink: `https://www.ammco.org/index.php?rub=16&id=13`,
      externalLink: true,
    },
  ];
  return (
    <>
      <section id="latestNews" className="latestNews">
        <div className="latestNews-title">
          {t("landingPage.content.latestNews.title")}
        </div>

        <div className="latestNewsCardRow">
          <div className="latestNewsSlider">
            {latestNewsCards.map((card, index) => (
              <LatestNewsCard
                key={index}
                title={card.title}
                text={card.text}
                img={card.img}
                caseStudyLink={card.caseStudyLink}
                externalLink={card.externalLink}
              />
            ))}
          </div>
        </div>
      </section>
      <div className="latestNewsBtnWrapper">
        <SignUpBtn />
      </div>
    </>
  );
}
