import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAdmin, isAuthenticated } from "./index";

//private route, only show for authenticated users
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default PrivateRoute;

//private route, only show for authenticated users
// export const AdminPrivateRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) =>
//       isAuthenticated() && isAdmin() ? (
//         <Component {...props} />
//       ) : (
//         <Redirect
//           to={{
//             pathname: "/listview-validate",
//             state: { from: props.location },
//           }}
//         />
//       )
//     }
//   />
// );

// export default AdminPrivateRoute;
